/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = {
  isFinishBase: false, // 室内基础信息是否加载完
  selectIndoorLanguage: {}, // 当前选择的语音类型
  indoorCurrentPlayInfo: {
    title: '---',
    currentTime: 0,
    playStatus: 0, // 0为未播放，1为播放，2为暂停
    voices: null, // 播放的url，时长等信息
    currentVoice: null, // 当前url
    typed: 1, // 当前播放的类型（景区，景点...）
    playId: 0, // 当前播放类型的id
  }, // 当前播放器信息
  indoorCurrentGroupItem: null, // 当前景点组或者子景点组的id
};
const actions = {
  updateFinish({ commit }, flag) {
    commit('updateFinish', flag);
  },
  updateIndoorLanguage({ commit }, obj) {
    commit('updateIndoorLanguage', obj);
  },
  updateIndoorPlayInfo({ commit }, info) {
    commit('updateIndoorPlayInfo', info);
  },
  updateIndoorCurrentGroupItem({ commit }, item) {
    commit('updateIndoorCurrentGroupItem', item);
  },
};

const mutations = {
  updateFinish(state, flag) {
    state.isFinishBase = flag;
  },
  updateIndoorLanguage(state, obj) {
    state.selectIndoorLanguage = obj;
  },
  updateIndoorPlayInfo(state, obj) {
    Object.assign(state.indoorCurrentPlayInfo, obj);
  },
  updateIndoorCurrentGroupItem(state, item) {
    state.indoorCurrentGroupItem = item;
  },
};

export default {
  state,
  actions,
  mutations,
};
