import Vue from 'vue';
import Router from 'vue-router';
import home from '@/view/index/home';

const indoorHome = () => import('@/view/index/indoorHome');
const textNavigation = () => import('@/view/textNavigation');

Vue.use(Router);

export default new Router({
  base: '/guide/',
  mode: 'history',
  routes: [
    {
      path: '/textNavigation',
      name: 'textNavigation',
      component: textNavigation,
      meta: {
        title: '手绘图导航',
      },
    },
    {
      path: '/IndoorHome/:query?',
      name: 'IndoorHome',
      component: indoorHome,
      meta: {
        title: '室内',
      },
    },
    {
      path: '/detail/:query?',
      name: 'Home',
      component: home,
      meta: {
        title: '首页',
      },
    },
    {
      path: '/:query?',
      name: 'Home',
      component: home,
      meta: {
        title: '首页',
      },
    },
  ],
});
