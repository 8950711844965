import Vue from 'vue';
import L from 'leaflet';
import { sync } from 'vuex-router-sync';
import VueCookies from 'vue-cookies';
// import Ve from 've';
// import 've/ve-css/index.css';
import '@/style/reset.css';
import '@/style/common.scss';
import Mint, { Toast, Loadmore } from 'mint-ui';
import 'mint-ui/lib/style.css';
import Utils from '@/utils/index';
import App from './App';
import router from './router';
import store from './store';
import 'amfe-flexible'
import '@/icons'

// import VConsole from 'vconsole';

// const vConsole = new VConsole();
// or init with options
// const vConsole = new VConsole({ theme: 'dark' });

// Vue.use(Ve);
Vue.use(Mint);
Vue.use(VueCookies);
sync(store, router);
Vue.config.productionTip = false;

// 加载转场效果
// CutTo();

// 通过href获取url参数
const URLOBJECT = Utils.parseUrl(window.location.href);
console.log(URLOBJECT);

Vue.prototype.$toast = (config) => {
  if (typeof config === 'string') {
    Toast({
      message: config,
      position: 'bottom',
    });
  } else {
    Toast(config);
  }
};

router.beforeEach((to, from, next) => {
  if (to.name) {
    // console.log(`我想给body加个${to.name}`);
    document.body.className = to.name;
  }
  if (to.meta && to.meta.title) {
    // 修改标题
    document.title = typeof to.meta.title === 'function' ? to.meta.title() : to.meta.title;
  }
  if (!from.name) {
    // 第一次进来获取数据
    console.log('first coming');
    next();
  } else {
    next();
  }
});
Vue.component(Loadmore.name, Loadmore);
// 添加过滤器
Vue.filter('timeFormat', (value) => {
  // console.log(value);
  const tempMin = parseInt(value / 60, 0);
  const tempSec = parseInt(value % 60, 0);
  const curMin = tempMin < 10 ? (`0${tempMin}`) : tempMin;
  const curSec = tempSec < 10 ? (`0${tempSec}`) : tempSec;
  return `${curMin}:${curSec}`;
});
// 添加过滤器
Vue.filter('wordFlow', (value, shu) => {
  const values = value.toString().length > shu ? `${value.slice(0, shu)}...` : value;
  return values;
});
// 添加时间过滤器
Vue.filter('secondMinute', (s) => {
  let h;
  h = Math.floor(s / 60);
  // 计算秒
  // 算法：取得秒%60的余数，既得到秒数
  // eslint-disable-next-line
  s %= 60;
  // 将变量转换为字符串
  h += '';
  // eslint-disable-next-line
  s += '';
  // 如果只有一位数，前面增加一个0
  // eslint-disable-next-line
  h = (h.length == 1) ? `0${h}` : h;
  // eslint-disable-next-line
  s = (s.length == 1) ? `0${s}` : s;
  return `${h}分${s}秒`;
});
// 两坐标之间的距离
Vue.filter('latlngDistance', (value, dist) => {
  const distance = dist.distanceTo(L.latLng(value));
  return `${distance.toFixed(2)}米`;
});
// document.body.addEventListener('touchmove', (e) => {
//   e.preventDefault(); // 阻止默认的处理方式(阻止下拉滑动的效果)
// }, { passive: false }); // passive 参数不能省略，用来兼容ios和android
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  mounted() {
    window.addEventListener('orientationchange', () => {
      if (window.orientation == 90 || window.orientation == -90) {
        Toast('建议竖屏显示，禁止横屏');
      }
    });
  },
  methods: {
  },
  render: h => h(App),
});
