import L from 'leaflet';

export default {
  data() {
    return {
      fontsizePer: 1,
      popupOptions: {
        scenicPopup: {
          className: 'scenic-popup-container',
          closeButton: true,
        },
        servicePopup: {
          className: 'service-popup-container',
          closeButton: false,
        },
      },
    };
  },
  methods: {
    // 服务点marker
    serviceIcon(item) {
      let html;
      if (this.isIndoor) {
        html = `
        <div class="scenics_top service_top"><span>${item.name && item.name.length > 10 ? `${item.name.slice(0, 10)}...` : item.name}</span></div>
        <div class="scenics_img" style="background-image: url(${item.picPath2}) !important;"></div>
      `;
      } else {
        html = `
        <div class="scenics_top service_top"><span>${item.name && item.name.length > 10 ? `${item.name.slice(0, 10)}...` : item.name}</span></div>
        <div class="scenics_img" style="background-image: url(${this.currentNearService.picPath}) !important;"></div>
      `;
      }

      return L.divIcon({
        html,
        className: 'scenics_icon_wrap',
        iconSize: [62 * 0.5 * this.fontsizePer, 62 * 0.5 * this.fontsizePer],
        // eslint-disable-next-line
        iconAnchor: [62 * 0.5 * this.fontsizePer / 2, 62 * 0.5 * this.fontsizePer / 2],
        // eslint-disable-next-line
        popupAnchor: [-62 * 0.3 * this.fontsizePer / 2, -62 * 0.5 * this.fontsizePer / 2],
      });
    },
    // 景点marker图标定义
    newIcon(item) {
      // console.log('item', item);
      let html;
      const isAudition = !this.$store.state.user.isVip && this.$store.state.user.elecUser.isFree == '0' && item.isAudition;
      const name = isAudition ? '免费试听' : (item.name && item.name.length > 10 ? `${item.name.slice(0, 10)}...` : item.name);
      if (!this.isIndoor) {
        html = `
        <div class="scenics_top ${isAudition ? 'audition' : ''}"><span>${name}</span></div>
        <div class="scenics_img ${item.isIndoor === 1 ? 'indoor' : ''} ${this.currentPlayInfo.typed === 2 && this.currentPlayInfo.playId === item.id && this.currentPlayInfo.playStatus === 1 && item.isIndoor === 2 ? 'playingIn' : ''}"></div>
      `;
      } else {
        html = `
        <div class="scenics_top ${isAudition ? 'audition' : ''}"><span>${name}</span></div>
        <div class="scenics_img ${this.indoorCurrentPlayInfo.typed === 2 && this.indoorCurrentPlayInfo.playId === item.id && this.indoorCurrentPlayInfo.playStatus === 1 ? 'playingIn' : ''}"></div>
      `;
      }

      return L.divIcon({
        html,
        className: 'scenics_icon_wrap',
        iconSize: [62 * 0.5 * this.fontsizePer, 62 * 0.5 * this.fontsizePer],
        // eslint-disable-next-line
        iconAnchor: [62 * 0.5 * this.fontsizePer / 2, 62 * 0.5 * this.fontsizePer / 2],
        // eslint-disable-next-line
        popupAnchor: [-90 * 0.3 * this.fontsizePer / 2, -62 * 0.5 * this.fontsizePer / 2],
      });
    },
    // 景点 popup 图标定义
    scenicPopupContent(marker) {
      let html;
      // eslint-disable-next-line
      let endClass;
      if (!this.isIndoor) {
        // eslint-disable-next-line
      if (this.currentPlayInfo.typed === 2 && this.currentPlayInfo.playId === marker.id && this.currentPlayInfo.playStatus === 1) {
          endClass = 'playing';
          // eslint-disable-next-line
      } else if (this.currentPlayInfo.typed === 2 && this.currentPlayInfo.playId === marker.id && this.currentPlayInfo.playStatus === 2) {
          endClass = 'paused';
        } else {
          endClass = '';
        }
      } else {
        // eslint-disable-next-line
      if (this.indoorCurrentPlayInfo.typed === 2 && this.indoorCurrentPlayInfo.playId === marker.id && this.indoorCurrentPlayInfo.playStatus === 1) {
          endClass = 'playing';
          // eslint-disable-next-line
      } else if (this.indoorCurrentPlayInfo.typed === 2 && this.indoorCurrentPlayInfo.playId === marker.id && this.indoorCurrentPlayInfo.playStatus === 2) {
          endClass = 'paused';
        } else {
          endClass = '';
        }
      }

      // eslint-disable-next-line prefer-const
      html = `<div class="popup_container">
        <div class="title ${marker.isIndoor === 1 ? 'indoorTitle' : ''}">
            <div>${marker.name && marker.name.length > 15 ? `${marker.name.slice(0, 15)}...` : marker.name}</div>
            <div class="goto-icon" id="goto-icon"></div>
        </div>
        <div class="desc">
          <div class="desc_left">
            <img src="${marker.introPic}"/>
          </div>
          <div class="desc_right">
            <p>${marker.introVoiceCut || marker.introCut}</p>
            <div class="desc_btn">
              <div class="speaker ${marker.isIndoor === 1 ? 'speakerIndoor' : endClass}" id="${marker.isIndoor === 1 ? 'speakerIndoor' : 'speaker'}">
                <span></span>
              </div>
              <div class="detail" id="detail" data-id="${marker.id}">${this.isAssociationAiBot ? '问AI' : '详情'}</div>
            </div>
          </div>
        </div>
      </div>`;
      return html;
    },
    // 检测景点组内部是否有播放的语音,1为有，0为没有
    isScenicGroupIn(item) {
      let endFlag = 0;
      let info;
      if (!this.isIndoor) {
        info = this.currentPlayInfo;
      } else {
        info = this.indoorCurrentPlayInfo;
      }
      if (info.playId === item.id && info.typed === item.typed && info.playStatus === 1) {
        endFlag = 1;
      } else {
        // eslint-disable-next-line
        item.subScenics.map((child) => {
          if (info.playId === child.id && info.typed === child.typed && info.playStatus === 1) {
            endFlag = 1;
          }
        });
      }
      return endFlag;
    },
    // 检测子景点组内部是否有播放的语音,1为有，0为没有
    isScenicChildGroupIn(item) {
      let endFlag = 0;
      let info;
      if (!this.isIndoor) {
        info = this.currentPlayInfo;
      } else {
        info = this.indoorCurrentPlayInfo;
      }
      if (info.playId === item.id && info.typed === item.typed && info.playStatus === 1) {
        endFlag = 1;
      } else {
        // eslint-disable-next-line
        item.subScenics&&item.subScenics.map((child) => {
          // eslint-disable-next-line
          child.childScenics.map((child2) => {
            // eslint-disable-next-line
            if (info.playId === child2.id && info.typed === child2.typed && info.playStatus === 1) {
              endFlag = 1;
            }
          });
        });
      }
      return endFlag;
    },
    // 景点组Icon
    scenicGroupIcon(item) {
      const endFlag = this.isScenicGroupIn(item);
      const html = `
        <div class="scenics_top"><span>${item.name}</span></div>
        <div class="scenics_img ${endFlag ? 'playingIn' : ''} scenicGroup"><span>${!endFlag ? item.subScenics.length : ''}</span></div>
      `;
      return L.divIcon({
        className: 'scenicGroup_wrap',
        iconSize: [62 * 0.5 * this.fontsizePer, 62 * 0.5 * this.fontsizePer],
        // eslint-disable-next-line
        iconAnchor: [62 * 0.5 * this.fontsizePer / 2, 62 * 0.5 * this.fontsizePer / 2],
        html,
      });
    },
    // 子景点组Icon
    childrenScenicGroupIcon(item) {
      const endFlag = this.isScenicChildGroupIn(item);
      const html = `
        <div class="scenics_top"><span>${item.name}</span></div>
        <div class="scenics_img ${endFlag ? 'playingIn' : ''} childrenScenicGroup"></div>
      `;
      return L.divIcon({
        className: 'childrenScenicGroup_wrap',
        iconSize: [62 * 0.5 * this.fontsizePer, 62 * 0.5 * this.fontsizePer],
        // eslint-disable-next-line
        iconAnchor: [62 * 0.5 * this.fontsizePer / 2, 62 * 0.5 * this.fontsizePer / 2],
        html,
      });
    },
    // 路线上的marker
    markIcon(index) {
      return L.divIcon({
        iconSize: [20 * this.fontsizePer, 20 * this.fontsizePer],
        iconAnchor: [10 * this.fontsizePer, 10 * this.fontsizePer],
        html: `<span class="icon-track">${index}</span>`,
      });
    },
  },
};
