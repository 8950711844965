<template>
  <transition name="fade" appear>
    <div class="feedBack tan_wrap">
      <div class="tan_header">
        <p class="th_p">建议与反馈</p>
        <div class="th_wrap" @click="hideModal('feedBackModal')">
          <img class="th_close" src="../../assets/f7/close.png">
        </div>
      </div>
      <div class="tan_main">
        <p class="tm_p">使用中遇到问题？可尝试联系客服哟！</p>
        <div class="tm_body">
          <div class="tm_wrap">
            <div class="tm_pic" @click="showModal('feedBackDetailModal',1)">
              <img src="../../assets/f7/map_error.png" class="tm_icon" alt="">
            </div>
            <div class="tm_pic" @click="showModal('feedBackDetailModal',2)">
              <img src="../../assets/f7/audio_error.png" class="tm_icon" alt="">
            </div>
          </div>
          <div class="tm_wrap">
            <div class="tm_pic" @click="showModal('feedBackDetailModal',3)">
              <img src="../../assets/f7/function_error.png" class="tm_icon" alt="">
            </div>
            <div class="tm_pic" @click="showModal('feedBackDetailModal',4)">
              <img src="../../assets/f7/others_error.png" class="tm_icon" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="tan_footer">
        <div class="tf_btn">
          <img src="../../assets/logo.png" class="tf_logo" alt="">
          <p class="tf_p">联系客服</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import eventBus from '@/eventBus';

export default {
  components: {},
  props: {},
  data() {
    return {
    };
  },
  watch: {},
  computed: {},
  methods: {
    hideModal(modal) {
      eventBus.$emit('hideModal', modal);
    },
    showModal(modal, arg) {
      // arg为点击的哪种问题
      eventBus.$emit('showModal', { whichModal: modal, arg });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.feedBack{
  width: 299px;
  height: 395px;
  border-radius:8px;
  background:rgba(255,255,255,0.95);
  box-shadow:0px 10px 20px 0px rgba(0,0,0,0.2),0px 5px 10px 0px rgba(0,0,0,0.2);
}
</style>
