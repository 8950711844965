<template>
  <div id="app">
    <div class="main" v-if="isSuccess">
      <transition :name="'vux-pop-in'" appear mode="out-in">
        <!-- <keep-alive :exclude="'IndoorHome'"> -->
          <router-view/>
        <!-- </keep-alive> -->
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'App',
  computed: {
    ...mapState({
      isSuccess: state => state.app.isSuccess,
    }),
  },
  data() {
    return {

    };
  },
  components: {
  },
  beforeDestroy() {
    sessionStorage.removeItem('mapBaseData');
    sessionStorage.removeItem('coords');
    sessionStorage.removeItem('routeObject');
  },
};
</script>

<style>
  @import "./style/leaflet.css";  /*leaflet地图css*/
/* --清除谷歌浏览器下的 search 叉号 */
input::-webkit-search-cancel-button{
  display: none;
}
/* --清除IE下的 search 叉号 */
input[type=search]::-ms-clear{
  display: none;
}
body{
  background: #fff;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
  /* min-height: 100%;
  height: auto; */
}
#app {
  position: relative;
  width: 100%;
  height: 100%;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
a:hover{
  text-decoration: none;
}
#app .main {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 0;
  z-index: 10;
  overflow: hidden;
  transition: margin-top 0.5s;
  -moz-transition: margin-top 0.5s; /* Firefox 4 */
  -webkit-transition: margin-top 0.5s; /* Safari 和 Chrome */
  -o-transition: margin-top 0.5s; /* Opera */
}
.vux-pop-out-enter-active,
.vux-pop-out-leave-active,
.vux-pop-in-enter-active,
.vux-pop-in-leave-active {
  will-change: transform;
  transition: all 500ms;
  height: 100%;
  top: 0;
  position: absolute !important;
  backface-visibility: hidden;
  perspective: 1000;
}
.vux-pop-out-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.vux-pop-out-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.vux-pop-in-enter {
  opacity: 0;
  /* transform: translate3d(100%, 0, 0); */
  transform: scale(3);
}
.vux-pop-in-leave-active {
  opacity: 0;
  transform: scale(0.3);
  /* transform: translate3d(-100%, 0, 0); */
}
.mint-indicator {
  position: relative;
  z-index: 10001;
}

.ve-tabs {
  position: relative;
}
.ve-tabs__wrap {
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  overflow: hidden;
  position: absolute;
  display: flex;
}
.ve-tabs__wrap--page-top {
  position: fixed;
}
.ve-tabs__wrap--content-bottom {
  top: auto;
  bottom: 0;
}
.ve-tabs__wrap--scrollable .ve-tab {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 22%;
  flex: 0 0 22%;
}
.ve-tabs__wrap--scrollable .ve-tabs__nav {
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.ve-tabs__wrap--scrollable .ve-tabs__nav::-webkit-scrollbar {
  display: none;
}
.ve-tabs__nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  flex: 1;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  background-color: #fff;
}
.ve-tabs__nav--line {
  height: 100%;
  padding-bottom: 15px;
  box-sizing: content-box;
}
.ve-tabs__nav--card {
  margin: 0 15px;
  border-radius: 2px;
  box-sizing: border-box;
  border: 1px solid #f44;
  height: 30px;
}
.ve-tabs__nav--card .ve-tab {
  color: #f44;
  border-right: 1px solid #f44;
  line-height: 28px;
}
.ve-tabs__nav--card .ve-tab:last-child {
  border-right: none;
}
.ve-tabs__nav--card .ve-tab.ve-tab--active {
  color: #fff;
  background-color: #f44;
}
.ve-tabs__line {
  z-index: 1;
  left: 0;
  bottom: 15px;
  height: 2px;
  position: absolute;
  background-color: #f44;
}
.ve-tabs--line {
  padding-top: 44px;
}
.ve-tabs--line .ve-tabs__wrap {
  height: 44px;
}
.ve-tabs--card {
  padding-top: 30px;
}
.ve-tabs--card .ve-tabs__wrap {
  height: 30px;
}
.ve-tab {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  cursor: pointer;
  padding: 0 5px;
  font-size: 14px;
  position: relative;
  color: #333;
  line-height: 44px;
  text-align: center;
  box-sizing: border-box;
  background-color: #fff;
  min-width: 0;
}
.ve-tab span {
  display: block;
}
.ve-tab:active {
  background-color: #e8e8e8;
}
.ve-tab--active {
  color: #f44;
}
.ve-tab--disabled {
  color: #c9c9c9;
}
.ve-tab--disabled:active {
  background-color: #fff;
}
</style>
