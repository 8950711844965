import http from '@/utils/http';
// import store from '@/store';
// import { keysRejecter } from '@/utils';


// 获取景区语音
export function getVoice(scenicId) {
  return http({
    url: `/h5/pb/scenic/${scenicId}/voice`,
  });
}
// 获取景区基本信息
export function getScenceData(scenicId) {
  return http({
    url: `/h5/pb/scenic/${scenicId}/baseInfo`,
  });
}
// 获取景点列表
export function getScenicList(scenicId) {
  return http({
    url: `/h5/pb/scenic/${scenicId}/subScenics`,
  });
}
// 获取景点详情
export function getScenicDetail(subScenicId) {
  return http({
    url: `/h5/pb/subScenic/${subScenicId}/detail`,
  });
}
// 获取景区推荐路线
export function getRoutes(scenicId) {
  return http({
    url: `/h5/pb/scenic/${scenicId}/routes`,
  });
}
// 获取景点的子景点组
export function getChildScenicGroups(subScenicId) {
  return http({
    url: `/h5/pb/subScenic/${subScenicId}/childScenicGroups`,
  });
}
// 获取景区的景点组
export function getSubScenicGroups(scenicId) {
  return http({
    url: `/h5/pb/scenic/${scenicId}/subScenicGroups`,
  });
}
// 获取景区周边服务（含高德服务）
export function getNearServices(scenicId) {
  return http({
    url: `/h5/pb/scenic/${scenicId}/nearServices`,
  });
}

// 提交用户景区收听记录
export function uploadListen(data) {
  return http({
    url: `${window.location.origin}/guide-distribution/api/pt/user/scenic/listening/submit`,
    method: 'post',
    data
  });
}
