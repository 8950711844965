import axios from 'axios';
import wrapper from 'axios-cache-plugin';
import store from '@/store';
import CommonUtils from './utils';

const { VUE_APP_BASE_API } = process.env;
// 创建axios实例
const service = axios.create({
  baseURL: VUE_APP_BASE_API,
  timeout: 15000, // 请求超时时间
});
// request拦截器
service.interceptors.request.use((config) => {
  if (/^\/h5\//.test(config.url)) {
    // eslint-disable-next-line no-param-reassign
    config.url = '/api' + config.url;
  }

  const configCopy = config;
  const userToken = store.state.user.userToken;
  const userId = store.state.user.userId;
  if (userToken) {
    configCopy.headers.userToken = userToken;
    configCopy.headers.userId = userId;
  }

  const elecUser = store.state.user.elecUser;
  if (elecUser && elecUser.token) {
    configCopy.headers['h5-token'] = elecUser.token;
  }

  // eslint-disable-next-line
  store.dispatch('updateLoadingStatus', true);
  return configCopy;
}, (error) => {
  // Do something with request error
  Promise.reject(error);
});
// respone拦截器
service.interceptors.response.use(
  (response) => {
    store.dispatch('updateLoadingStatus', false);
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    const res = response.data;
    // eslint-disable-next-line
    console.log('请求结果', res)
    // eslint-disable-next-line
    if (res.status != 0) {
      if (res.code == 200) {
        return ({
          code: res.code,
          data: res.data,
          msg: res.msg,
        });
      }
      // 没有权限
      if (res.code === 401) {
        console.log('================401================');
        const url = window.location.href;
        const obj = CommonUtils.getUrlParamToObj(url);
        const salesmanId = obj.salesmanId || localStorage.getItem('salesmanId');
        window.location.href = `${window.location.origin}/guide-distribution/api/pb/wechat/silent/authorize?salesmanId=${salesmanId}&returnUrl=${encodeURIComponent(url)}`;
        return;
      }
      return Promise.reject({
        message: response.message || '服务器错误',
        err: 'error',
      });
    } 
    
    return ({
      code: response.data.status,
      data: res.content,
      msg: response.data.msg,
    });
  }
  ,
  (error) => {
    store.dispatch('updateLoadingStatus', false);
    return Promise.reject(error);
  },
);
const http = wrapper(service, {
  maxCacheSize: 15,
});
// eslint-disable-next-line
http.__addFilter(/\/api\/pt\/scenic\/servicePoint/);
export default http;
