<template>
  <transition name="fade" appear>
    <div class="success-modal">
      <div class="share-txt">
        <img src="../../assets/f7/share-ying.png">
        <p>为避免权益丢失，<br/>请在微信中收藏链接</p>
      </div>
      <div class="img-box">
        <img src="../../assets/f7/icon_suc@2x.png" />
        <p>已解锁AI对话功能</p>
        <div class="know-btn" @click="hideModal('payAiSuccessModal')">我知道了</div>
      </div>
    </div>
  </transition>
</template>

<script>
import eventBus from '@/eventBus';
import utils from "@/utils/utils";

export default {
  components: {},
  props: {
  },
  data() {
    return {
    };
  },
  methods: {
    hideModal(modal) {
      const obj = utils.getUrlParamToObj(window.location.href);
      localStorage.setItem('aiToastTime', obj.toast);
      eventBus.$emit('hideModal', modal);
    }
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
  .success-modal {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .share-txt {
      text-align: right;
      position: absolute;
      right: 16px;
      top: 8px;
      img {
        width: 43px;
        height: 52px;
      }
      p {
        width: 126px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        position: absolute;
        right: 0;
        text-align: left;
        margin: 8px 0 0 0;
      }
    }
    .img-box {
      text-align: center;
      img {
        width: 64px;
        height: 64px;
      }
      p {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 22px;
        margin: 16px 0 72px 0;
      }
      .know-btn {
        width: 231px;
        height: 46px;
        border-radius: 24px;
        border: 1px solid #FFC726;
        margin: 0 auto;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFC726;
        line-height: 44px;
        text-align: center;
      }
    }
  }
</style>
