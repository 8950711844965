import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import indoor from './modules/indoor';
import user from './modules/user';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    app,
    indoor,
    user,
  },
  getters: {
    cannotPlay: state => !state.user.isVip && state.user.elecUser.isFree == '0',
    isAssociationAiBot : state => state.user.aiBotInfo && state.user.aiBotInfo.isAssociationAiBot === 1
  },
  strict: debug,
});
