<template>
  <div class="wrapper">
    <div class="wrap-ul1" ref="scroll">
        <div class="hold" ref="ul">
            <div class="near "
            ref="li"
            v-for="(service,index) in nearServiceData"
            :key="index"
            @click="selectService(service,index)">
                <div class="icon-span">
                  <div class="img" v-if="!service.select"
                  :style="{backgroundImage:'url('+service.defaultPicPath+')'}"></div>
                  <div class="img" v-else
                  :style="{backgroundImage:'url('+service.showPicPath+')'}"></div>
                </div>
                <span :class="service.select?'selected':''">{{service.name}}</span>
            </div>
        </div>
    </div>
    <div class="bottom-arrow" @click.stop="openSlideDown">
      <img src="../assets/f7/down_line.png" alt="" :style="slideFlag?options:''">
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus';

const $ = window.$;
export default {
  components: {},
  props: ['nearServiceData', 'modalAll'],
  data() {
    return {
      ulWidth: 0,
      liOffset: 0,
      ulThis: null,
      options: {
        transform: 'rotate(180deg)',
      },
    };
  },
  watch: {},
  computed: {
    slideFlag() {
      return this.modalAll.slideDownModal.isShow;
    },
  },
  methods: {
    openSlideDown() {
      // 切换下拉框
      eventBus.$emit('openSlideDown');
    },
    selectService(service, my) {
      this.animateTo(my);
      eventBus.$emit('selectService', service);
    },
    animateTo(my) {
      this.$nextTick(() => {
        const ulthis = $('.wrap-ul1 .near');
        this.ulWidth = 0; // 获取总长度
        this.liOffset = 0; // 获取当前点击的li的长度
        // eslint-disable-next-line
        for (let i = 0; i < ulthis.length; i++) { // 计算总长度的计算过程
          this.ulWidth = this.ulWidth + ulthis[i].clientWidth;
          this.liOffset = ulthis[my].offsetLeft;
        }
        const wrapWidth = $('.wrap-ul1').get(0).clientWidth * 0.5 * 0.88; // 获取最外层包裹容器的宽度并计算适合的位置
        $('.hold').animate({
          scrollLeft: this.liOffset - wrapWidth, // 将当前点击的li滚动到合适的位置
        });
      });
    },
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background:rgba(255,255,255,0.95);
    z-index: 2;
    font-size: 14px;
    box-sizing: border-box;
    .bottom-arrow{
        position: absolute;
        right: 0;
        top: 0rem;
        width: 49px;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 30px;
        background: rgba(255,255,255,0.95);
        border-left: 1px solid rgba(223,227,228,1);;
        margin: 10px 0;
        box-sizing: border-box;
        img{
          transition: transform .2s;
          width: 15px;
          height: 15px;
        }
    }
    .wrap-ul1{
        display: flex;
        width: 326px;
        display: -webkit-flex;
        height: 100%;
        flex-wrap: nowrap;
        align-items: center;
        .near{
            padding: 0 15px;
            white-space: nowrap;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .icon-span{
              width: 16px;
              margin-bottom: 3px;
              height: 17px;
              .img{
                height: 100%;
                width: 100%;
                background-size: contain;
                background-repeat: no-repeat;
              }
            }
            span{
                color: #999DA3;
                font-size: 10px;
                &.selected{
                    color: #333333;
                }
            }
        }
    }
    .hold::-webkit-scrollbar{
        display: none;
    }
    .hold{
        display:flex;
        width: 100%;
        display:-webkit-flex;
        flex-wrap: nowrap;
        height: 100%;
        align-items: center;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        overflow: -moz-scrollbars-none;
    }
}
</style>
