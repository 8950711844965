<template>
    <ul v-if="isShow&&filterEnd.length>0">
      <li
      class="wrapli"
      v-for="(item,index) in filterEnd"
      :key="index"
      @click="changeScenic(item)"
      v-if="item.typed===2">
        <div class="serve-li">
          <div class="serve-left">
            <div class="servescenic-icon"
            :class="item.subScenics.length>0?'servescenic-icon-child':''">
              <img
              class="" src="../assets/f7/subChildGroup.png" alt="">
             </div>
            <span>{{item.name|wordFlow(15)}}</span>
            <template v-if="item.selected">
             <img
              src="../assets/f7/childPlaying.gif"
              v-if="playInfo.playStatus==1"
              class="childPlay">
              <img
              v-else
              src="../assets/f7/currentSelect.png"
              class="childPlay2">
              </template>
          </div>
          <div class="serve-right">
            {{item.distance}}
          </div>
        </div>
      </li>
      <li
      class="wrapli"
      v-for="(item,index) in filterEnd"
      :key="index"
      @click="changeScenic(item)"
      v-if="item.type===3">
        <div class="serve-li">
          <div class="serve-left">
            <img
             class="serve-icon" :src="item.listPicPath" alt="">
            <span>{{item.name|wordFlow(15)}}</span>
            <template v-if="item.selected">
             <img
              src="../assets/f7/childPlaying.gif"
              v-if="playInfo.playStatus==1"
              class="childPlay">
              <img
              v-else
              src="../assets/f7/currentSelect.png"
              class="childPlay2">
              </template>
          </div>
          <div class="serve-right">
            {{item.distance}}
          </div>
        </div>
      </li>
      <li
      class="wrapli"
      v-for="(item,index) in filterEnd"
      :key="index"
      @click="changeScenic(item)"
      v-if="item.typed===4">
        <div class="serve-li serve-li4">
          <div class="serve-left">
            <img
             v-if="!item.isparentGroup"
             class="serve-icon" src="../assets/f7/scenic_nei.png" alt="">
            <span :class="item.isparentGroup?'downlog':''">{{item.name|wordFlow(15)}}</span>
            <template
             v-if="item.id==playInfo.playId&&playInfo.playId&&!item.isparentGroup">
             <img
              src="../assets/f7/childPlaying.gif"
              v-if="playInfo.playStatus==1"
              class="childPlay">
              <img
              v-else
              src="../assets/f7/currentSelect.png"
              class="childPlay2">
              </template>
          </div>
          <div class="serve-right">
            {{item.distance}}
          </div>
        </div>
        <nearTree v-if="item.subScenics&&item.subScenics.length>0" :childGroup="item.subScenics"/>
      </li>
    </ul>
    <ul v-else>
      <p class="nonhav">未找到符合的景点或目的地</p>
    </ul>
</template>

<script>
import eventBus from '@/eventBus';
import { mapState } from 'vuex';

const $ = window.$;

export default {
  name: 'nearTree',
  props: {
    childGroup: {
      type: Array,
      default() {
        return [];
      },
    },
    currentServiceList: {
      type: Array,
      default() {
        return [];
      },
    },
    searchKey: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      endFilter: [],
      isShow: false,
      playInfo: {},
    };
  },
  computed: {
    ...mapState({
      currentPlayInfo: state => state.app.currentPlayInfo,
      indoorCurrentPlayInfo: state => state.indoor.indoorCurrentPlayInfo,
      isIndoor: state => state.app.isIndoor,
    }),
    // eslint-disable-next-line
    filterEnd() {
      // eslint-disable-next-line
      return this.endFilter.map((item) => {
        const matchedChildren =
        (item.subScenics || []).filter(subItem => RegExp(this.searchKey).test(subItem.name));
        const matched = matchedChildren.length > 0 || RegExp(this.searchKey).test(item.name);
        if (matched) {
          return { ...item, subScenics: matchedChildren };
        }
      }).filter(item => item);
    },
  },
  methods: {
    changeScenic(item) {
      // if (this.isIndoor) {
      //   eventBus.$emit('changeIndoorSelected', item);
      // } else {
      //   eventBus.$emit('changeSelected', item);
      // }
      eventBus.$emit('changeScenic', item);
    },
  },
  created() {
    if (!this.isIndoor) {
      this.playInfo = this.currentPlayInfo;
    } else {
      this.playInfo = this.indoorCurrentPlayInfo;
    }
    this.endFilter = this.currentServiceList.concat(this.childGroup);
    this.isShow = true;
  },
  mounted() {
    this.$nextTick(() => {
      $('.serve-li4').off('click');
      // eslint-disable-next-line
      $('.serve-li4').on('click', function () {
        if ($(this).siblings('ul').is(':hidden')) {
          $(this).find('.downlog').removeClass('down');
          $(this).siblings('ul').slideDown();
        } else {
          $(this).find('.downlog').addClass('down');
          $(this).siblings('ul').slideUp();
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.serve-li{
  height: 40px;
  line-height: 40px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  .childPlay{
    width:18px;
    height:18px;
    margin-left: 10px;
    margin-bottom: 8px;
  }
  .childPlay2{
    width:15px;
    margin-left: 10px;
    height:15px;
  }
  .serve-left{
    display: flex;
    display: -webkit-flex;
    align-items: center;
    font-size: 13px;
    color: #202020;
    .servescenic-icon{
      width:18px;
      height:18px;
      background: #ff9802;
      border-radius: 50%;
      margin-right: 15px;
      position: relative;
      img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 60%;
      }
    }
    .servescenic-icon-child{
      background: #52be80
    }
    .serve-icon{
      width:18px;
      height:18px;
      margin-right: 15px;
    }
  }
  .serve-right{
    font-size: 12px;
    color: #A5B0B5;
    .serve-icon{
      width:18px;
      height:18px;
      margin-right: 15px;
    }
  }
}
.wrapli{
  ul{
    display: block;
    margin-left: 15px;
    .serve-li{

    }
  }
}
.downlog{
  position: relative;
  &::after{
    content: ">";
    transform: rotate(90deg);
    position:absolute;
    right: -20px;
    top: 0;
  }
  &.down{
    &::after{
      transform: rotate(0) !important;
      content: ">";
      position:absolute;
      right: -20px;
      top: 0;
    }
  }
}
.nonhav{
    text-align: center;
    font-size: 12px;
    color: #999
  }
</style>
