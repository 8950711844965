export const gaode = data => data.steps.map(step => ({
  instruction: step.instruction,
  distance: Number.parseInt(step.distance, 10),
  duration: Number.parseInt(step.duration, 10),
  action: Array.isArray(step.action) ? '到达目的地' : step.action,
  points: step.polyline.split(';')
    .map((item) => {
      const pt = JSON.parse(`[${item}]`);
      return [pt[1], pt[0]];
    }),
}));

export const minDistance = 20;
export const Errors = {
  alreadyThere: () => new Error('already there'),
  isAleadyThere: err => err.message === 'already there',
};
