<template>
  <transition name="fade" appear>
    <div class="scenicInfo">
      <div class="top">
        <img src="../../assets/f7/adv1.png" alt="">
      </div>
      <div class="mains">
        <div class="main_img">
          <img src="../../assets/f7/adv2.jpg" alt="">
        </div>
        <div class="main_content">
          <p>开放时间：{{scenic.openTimeDesc}}</p>
          <p>景区地址：{{scenic.address}}</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  components: {},
  props: {
    scenic: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.scenicInfo{
  // background: #fff;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding-top: 120px;
  height: 400px;
  .top{
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    height:120px;
    img{
      width: 100%;
    }
  }
  .mains{
    position: absolute;
    top: 120px;
    width: 85%;
    left: 50%;
    transform: translateX(-50%);
    height:280px;
    background: #fff;
    padding: 8px;
    box-sizing: border-box;
    .main_img{
      width: 100%;
      height: 176px;
      overflow: hidden;
      img{
        width: 100%;
      }
    }
    .main_content{
      margin-top: 10px;
      text-align: center;
      font-size: 12px;
      color: #888;
    }
  }
}

</style>
