<template>
  <div class="homeWrap">
    <div class="home" v-if="JSON.stringify(mapScenic) != '{}'">
      <transition name="fade" appear>
        <div v-if="!isGoto && !isDigitalHumanShow && !isChatBoxShow">
          <!-- 顶部服务栏 S -->
          <nearServiceNew :nearServiceData="nearServiceData" :modalAll="modalAll"/>
          <!-- 顶部服务栏 E -->
          <!-- 顶部服务栏下拉 S -->
          <nearServiceDown
            v-if="modalAll.slideDownModal.isShow&&openDown"
            :currentNearService="currentNearService"
            :currentServiceList="currentServiceList"
            :mapScenicGroup="mapScenicGroup"
          />
          <!-- 顶部服务栏下拉 E -->
        </div>
      </transition>
      <!-- 地图组件 -->
      <div style="position: relative;height:50px;line-height:50px;margin-left:15px;" v-if="showBackBtn" @click="showNavModal">返回导航</div>
      <map-container
        ref="mapcontainer"
        v-if="mapScenic.scenic"
        :scenic="mapScenic.scenic"
        :currentRoutes="currentRoutes"
        :map="mapScenic.map"
        :handScan="autoPlay"
        :mapScenicList="mapScenicList"
        :mapScenicGroup="mapScenicGroup"
        :currentNearService="currentNearService"
        :currentServiceList="currentServiceList"
      />
      <!-- 右上角菜单 S-->
      <transition name="fadeRight" appear>
        <div v-if="!isGoto && !isDigitalHumanShow && !isChatBoxShow" class="right_top_menu">
          <div class="menu_wrap">
            <div class="menu_btn" @click="showModal({whichModal:'scenicInfoModal'})">
              <img src="../../assets/f7/recommend.png" alt="" class="svg-icon">
              <div class="pp">简介</div>
            </div>
            <div class="menu_btn" @click="showModal({whichModal:'languageDetailModal'})">
              <img src="../../assets/f7/language.png" alt="" class="svg-icon">
              <div class="pp">语言</div>
            </div>
          </div>
          <div class="menu_btn">
            <div class="toggleWrapper">
              <input
                type="checkbox"
                name="autoPlay"
                class="mobileToggle"
                id="autoPlay"
                v-model="autoPlay"
              >
              <label for="autoPlay"></label>
            </div>
            <div class="pp">手绘</div>
          </div>
          <div class="menu_btn" @click="showModal({whichModal:'shareModal'})">
            <img src="../../assets/f7/icon_share.png" alt="" class="svg-icon">
            <div class="pp">分享</div>
          </div>

          <div class="menu_btn" @click="fakeLoc" v-if="false">
            <img src="../../assets/f7/icon_share.png" alt="" class="svg-icon">
            <div class="pp">模拟</div>
          </div>
        </div>
      </transition>
      <!-- 右上角菜单 E-->
      <div class="robot-wrap" v-show="isAssociationAiBot && !isDigitalHumanShow && !isChatBoxShow">
        <!-- <div class="robot-wrap" v-show="!isDigitalHumanShow && !isChatBoxShow"> -->
              <!-- <svg-icon icon-class="robot" /> -->
            <img src="../../assets/robot.gif" alt="" class="robot-icon" @click="showAiModal">

            <div class="robot-tips" v-if="showRobotTips">
              <div>你好，我是你的金牌导游</div>

            <div class="robot-close-btn" @click.stop="showRobotTips = false" >
              <svg-icon icon-class="closeS"/>
            </div>

            </div>
      </div>
      <!-- 左下角定位按钮 S -->
      <transition name="fadeLeft" appear>
        <div v-if="!isGoto" class="left_bottom">
          <!-- <div class="lbt_btn feedback" @click="showModal({whichModal:'feedBackModal'})">
            <img src="../../assets/f7/errorCorrection.png" class="locationpng" alt="">
          </div>-->
          <div class="lbt_btn" @click="locationHander(true)" v-if="hasLocationLoading">
            <img src="../../assets/f7/location.png" class="locationpng">
          </div>
          <div class="lbt_btn" v-else>
            <img src="../../assets/f7/location-loading.gif" class="locationpng" alt="">
          </div>
        </div>
      </transition>
      <!-- 左下角定位按钮 E -->
      <div class="position_wrap transform_bottom" :class="controlExpanded ? 'transform_enter' :'transform_leave'" :style="'bottom: '+ offsetBottomY+'px'">
        <!-- 底部购买栏 S -->
        <div class="bottom_shop" v-if="!isVip">
          <div class="bs_left">
            <!-- <img src="../../assets/icon_tip.png" alt="">
            <span>VIP会员解锁全球1W+景点解说</span> -->
          </div>
          <!-- 0-收费；1-免费 -->
          <template v-if="isFree == '0'" >
            <div class="bs_right" v-if="canBuy" @click="buyVip">立即使用</div>
            <div class="bs_right dis-buy-btn" v-else >立即使用</div>
          </template>
        </div>
        <!-- 底部购买栏 E -->
        <transition name="audioslide" mode="out-in" appear>
          <div v-if="!isGoto" key="slide1">
            <!-- 底部播放框 S -->
            <audioContainer></audioContainer>
            <!-- 底部播放框 E -->
            <!-- 底部播放列表 S -->
            <audioList
              ref="audioListRef"
              v-if="scenicAndGroup"
              :scenicAndGroup="scenicAndGroup"
            ></audioList>
            <!-- 底部播放列表 E -->
          </div>
          <!-- 去这里导航 S -->
          <div class="goto" v-else-if="isGoto" key="slide2">
            <gotoContainer :mapScenic="mapScenic"></gotoContainer>
          </div>
          <!-- 去这里导航 E -->
        </transition>
      </div>
      <!-- 景区简介弹窗 S -->
      <transMaskModal :currentModal="'scenicInfoModal'" v-if="modalAll.scenicInfoModal.showMask">
        <template slot="modal">
          <scenicInfoModal v-if="modalAll.scenicInfoModal.isShow" :scenic="mapScenic.scenic"/>
        </template>
      </transMaskModal>
      <!-- 景区简介弹窗 E -->
      <!-- 反馈弹窗  S -->
      <transMaskModal :currentModal="'feedBackModal'" v-if="modalAll.feedBackModal.showMask">
        <template slot="modal">
          <feedBackModal v-if="modalAll.feedBackModal.isShow"/>
        </template>
      </transMaskModal>
      <!-- 反馈弹窗  E -->
      <!-- 反馈详情弹窗  S -->
      <feedBackDetailModal :arg="feedBackArg" v-if="modalAll.feedBackDetailModal.isShow"/>
      <!-- 反馈详情弹窗  E -->
      <!-- 语言详情弹窗  S -->
      <transMaskModal
        :currentModal="'languageDetailModal'"
        v-if="modalAll.languageDetailModal.showMask"
      >
        <template slot="modal">
          <languageDetailModal
            :mapLanguage="mapLanguage"
            v-if="modalAll.languageDetailModal.isShow"
          />
        </template>
      </transMaskModal>
      <!-- 语言详情弹窗  E -->
      <!-- 分享 S -->
      <transMaskModal
        :currentModal="'shareModal'"
        v-if="modalAll.shareModal.showMask"
      >
        <template slot="modal">
          <shareModal
            :mapLanguage="mapLanguage"
            v-if="modalAll.shareModal.isShow"
            :sharePosterUrl="sharePosterUrl"
          />
        </template>
      </transMaskModal>
      <!-- 分享 E -->

       <!-- 支付成功 S -->
      <transMaskModal
        :currentModal="'paySuccessModal'"
        v-if="modalAll.paySuccessModal.showMask"
      >
        <template slot="modal">
          <paySuccessModal
            v-if="modalAll.paySuccessModal.isShow"
          />
        </template>
      </transMaskModal>
      <!-- 分享 E -->

      <!-- AI支付成功 S -->
      <transMaskModal
        :currentModal="'payAiSuccessModal'"
        v-if="modalAll.payAiSuccessModal.showMask"
      >
        <template slot="modal">
          <payAiSuccessModal
            v-if="modalAll.payAiSuccessModal.isShow"
          />
        </template>
      </transMaskModal>
      <!-- 分享 E -->

      <!-- 购买弹窗  S -->
      <transMaskModal :currentModal="'shopDetailModal'" v-if="modalAll.shopDetailModal.showMask">
        <template slot="modal">
          <shopDetailModal
            v-if="modalAll.shopDetailModal.isShow"
            :scenic="mapScenic.scenic"
            :scenicCount="mapScenicList.length"
          />
        </template>
      </transMaskModal>
      <!-- 购买弹窗  E -->
      
      <!-- AI购买弹窗  S -->
      <transMaskModal :currentModal="'aiShopDetailModal'" v-if="modalAll.aiShopDetailModal.showMask">
        <template slot="modal">
          <aiShopDetailModal
            v-if="modalAll.aiShopDetailModal.isShow"
            :scenic="mapScenic.scenic"
            :scenicCount="mapScenicList.length"
          />
        </template>
      </transMaskModal>
      <!-- AI购买弹窗  E -->

      <!-- 景点详情  S -->
      <transMaskModal
        :currentModal="'scenicDetailModal'"
        v-if="modalAll.scenicDetailModal.showMask"
      >
        <template slot="modal">
          <scenicDetailModal
            v-if="modalAll.scenicDetailModal.isShow"
            :scenicDetailOn="scenicDetailOn"
          />
        </template>
      </transMaskModal>
      <!-- 景点详情  E -->
      <!-- 景点组弹框  S -->
      <transMaskModal
        :currentModal="'scenicGroupModal'"
        :iszIndex="true"
        v-if="modalAll.scenicGroupModal.showMask"
      >
        <template slot="modal">
          <scenicGroupModal
            :isChildrenGroup="isChildrenGroup"
            :scenicList="mapScenicList"
            :currentGroupItem="currentGroupItem"
            v-if="modalAll.scenicGroupModal.isShow"
          />
        </template>
      </transMaskModal>
      <!-- 景点组弹框  E -->
    </div>
    <!-- 播放 -->
    <audio
      v-show="false"
      :src="currentPlayInfo.currentVoice?currentPlayInfo.currentVoice.voiceFile:''"
      ref="audios"
      preload=""
      id="audios"
      controls
      style="display: block;position: absolute;z-index:99;"
    ></audio>

    <transition name="aifade" appear>
      <div class="digital-container" v-if="isDigitalHumanShow">
        <img src="../../assets/f7/ai_close_black.png" alt="" @click="closeAiModal" class="digital-close">

        <digital-human-comp
          :media-info="mediaInfo"
          :user-info="userInfo"
          :style-config="styleConfig"
          :payment-info="paymentInfo"
          class="digital-box"
          @btntap="handleAiTap"
        />
      </div>
  </transition>

  <transition name="aifade" appear>
    <div class="lv-chat-box-container" v-if="isChatBoxShow">
      <img src="../../assets/robot.gif" alt="" class="lv-chat-box-icon">
      <img src="../../assets/f7/ai_close_grey.png" alt="" @click="closeAiModal" class="lv-digital-close">

      <chat-box
        :disabled-more-panel="false"
        :media-info="mediaInfo"
        :user-info="userInfo"
        :style-config="styleConfig"
        :payment-info="paymentInfo"
        @onaudioplay="aiPauseFmPlayer($event, {tagId: 'chatBox'})"
        @scrollup="aiScrollup"
        @scrolldown="aiScrolldown"
        class="lv-chat-box"
        ref="chatBox"
      />
    </div>
  </transition>

    <!-- <div class="lv-chat-box-container" v-if="isChatBoxShow">
      <img src="../../assets/f7/indoorClose.png" alt="" @click="closeAiModal" class="lv-digital-close">

      <tour-guide-comp
    :media-info="mediaInfo"
    :user-info="userInfo"
    :style-config="styleConfig"
    :payment-info="paymentInfo"
    :disabled-introduce-panel="disabledIntroducePanel"
    />
    </div> -->
  
  </div>
</template>

<script>
// api
import L from "leaflet";
import basePromise from "@/mixin/basePromise";
import { Indicator } from "mint-ui";
import axios from 'axios';
import audio from "@/mixin/audio";
// 播放组件
import audioContainer from "@/components/audioContainer";
// 去这里组件
import gotoContainer from "@/components/gotoContainer";
// 播放列表组件
import audioList from "@/components/audioList";

import transMaskModal from "@/components/mask/transMaskModal";
import nearServiceNew from "@/components/nearServiceNew";
import nearServiceDown from "@/components/nearServiceDown";
// 弹窗详情
import scenicInfoModal from "@/components/Detail/scenicInfo";
import feedBackModal from "@/components/Detail/feedBack";
import feedBackDetailModal from "@/components/Detail/feedBackDetail";
import languageDetailModal from "@/components/Detail/languageDetail";
import shareModal from "@/components/Detail/shareModal";
import shopDetailModal from "@/components/Detail/shopDetail";
import aiShopDetailModal from "@/components/Detail/aiShopDetail";
import scenicDetailModal from "@/components/Detail/scenicDetail";
import scenicGroupModal from "@/components/Detail/scenicGroup";
import paySuccessModal from "@/components/Detail/paySuccessModal";
import payAiSuccessModal from "@/components/Detail/payAiSuccessModal";
import { mapState } from "vuex";

import eventBus from "@/eventBus";
// 地图组件
import mapContainer from "./map";

import utils from "@/utils/utils";
import { DigitalHumanComp,ChatBox,TourGuideComp, getPaymentInfo,payment } from 'aibqt-bot-h5';

export default {
  name: "Home",
  mixins: [basePromise, audio],
  components: {
    mapContainer,
    transMaskModal,
    nearServiceNew,
    nearServiceDown,
    scenicInfoModal,
    feedBackModal,
    feedBackDetailModal,
    languageDetailModal,
    shareModal,
    shopDetailModal,
    aiShopDetailModal,
    audioContainer,
    gotoContainer,
    audioList,
    scenicDetailModal,
    scenicGroupModal,
    paySuccessModal,
    payAiSuccessModal,
    DigitalHumanComp,
    ChatBox,
    TourGuideComp
  },
  data() {
    return {
      modalAll: {
        slideDownModal: {
          isShow: false,
          showMask: false
        },
        scenicInfoModal: {
          isShow: false,
          showMask: false
        },
        feedBackModal: {
          isShow: false,
          showMask: false
        },
        feedBackDetailModal: {
          isShow: false
        },
        languageDetailModal: {
          isShow: false,
          showMask: false
        },
        shareModal: {
          isShow: false,
          showMask: false
        },
        shopDetailModal: {
          isShow: false,
          showMask: false
        },
        aiShopDetailModal: {
          isShow: false,
          showMask: false
        },
        audioListModal: {
          isShow: true
        },
        scenicDetailModal: {
          isShow: false,
          showMask: false
        },
        scenicGroupModal: {
          isShow: false,
          showMask: false
        },
        paySuccessModal: {
          isShow: false,
          showMask: false
        },
        payAiSuccessModal: {
          isShow: false,
          showMask: false
        },
      },
      timer: null, // 计时器
      autoPlay: true, // 手绘图按钮切换
      mapScenic: {}, // 地图基础信息
      mapScenicList: [], // 地图景点列表
      mapLanguage: {}, // 语音数据
      mapScenicGroup: [], // 景点组列表
      mapNearServiceList: [], // 景区服务列表
      mapRoutes: [], // 景区路线
      currentRoutes: {}, // 当前景区路线
      feedBackArg: 0, // 反馈详情arg
      openDown: true, //
      isChildrenGroup: false, // 景点组详情arg，true为子景点组，false为景点组
      scenicDetailOn: null,
      currentNearService: {
        // 当前选择的顶部栏的类型,-1为景点，-2为路线，3为服务点
        type: -1,
        id: -1,
        name: "景点",
        listPicPath: "",
        infoPicPath: "",
        picPath: ""
      },
      currentServiceList: [], // 当前服务列表
      nearServiceData: [],
      controlExpanded: true,
      movePositionValue: 0,
      endPositionValue: -200 * (window.innerWidth / 375),
      refreshCount: 0,
      canBuy: false,
      isFree: '',
      showBackBtn: false,
      sharePosterUrl: '',
      fakeIndex: -1,
      firstTimeGetLoc: true,

      showRobotTips: true,

      mediaInfo: null,
      userInfo: null,
      paymentInfo: null,
      styleConfig: {
        themeColor: ["#60AFFF", "#6599FF"],
        pageBackgroundColor: ["#F2F7FF", "#F2F5F7", "#E6F2FF", "#D7EBFF"],
        digitalEntityNum: "1",
      },
  
      isDigitalHumanShow: false,
      isChatBoxShow: false,
      // aibot剩余次数
      aiBotResidueNum: 0
    };
  },
  watch: {
    mapScenic(nv) {
      if (nv) {
        Indicator.close();
      }
    },
    autoPlay(val) {
      if (val) {
        this.$toast({
          message: "开启手绘图",
          position: "bottom"
        });
      } else {
        this.$toast({
          message: "关闭手绘图",
          position: "bottom"
        });
      }
    },
    // 监听列表循环开关
    listLoop(nv) {
      if (nv === 2) {
        this.$store.dispatch("updateLoopIndex", -1);
      } else {
        let currentIndex = -1;
        const currentPlay = this.currentPlayInfo;
        
        this.scenicAndGroup.map((item, index) => {
          if (
            item.id == currentPlay.playId &&
            item.typed == currentPlay.typed
          ) {
            currentIndex = index;
          }
        });
        
        if (currentIndex != -1) {
          this.$store.dispatch("updateLoopIndex", currentIndex);
          // 开启列表播放后不会立马播放，因为这里恒相等，要调下"updateLoopIndex"顺序
          if (this.loopIndex != currentIndex) {
            eventBus.$emit("changeSelected", this.scenicAndGroup[currentIndex]);
            eventBus.$emit("changeAudioInfo", {
              item: this.scenicAndGroup[currentIndex],
              status: 1
            });
          }
        }
      }
    },
    // 监听播放完播放下一首
    playInfoStatus(nv) {
      if (this.listLoop === 2) return false;
      const that = this;
      
      function allChange(index) {
        that.$store.dispatch("updateLoopIndex", index);
        eventBus.$emit("changeSelected", that.scenicAndGroup[index]);
        eventBus.$emit("changeAudioInfo", {
          item: that.scenicAndGroup[index],
          status: 1
        });
      }
      let endIndex = -1;
      
      this.scenicAndGroup.map((item, index) => {
        if (
          item.id == this.currentPlayInfo.playId &&
          item.typed == this.currentPlayInfo.typed
        ) {
          endIndex = index;
          this.$store.dispatch("updateLoopIndex", endIndex);
        }
      });
      // 检测到播放的是最后一个的时候
      if (endIndex === this.scenicAndGroup.length - 1) {
        this.$store.dispatch("updateLoopIndex", -1);
      }
      // 检测到播放的文件不是在列表中
      // 还有这种情况????????
      if (endIndex == -1) {
        allChange(0);
      }
      if (nv === 0 && this.loopIndex !== -1) {
        // 播放完毕并且loopIndex不为-1则可以继续播放否侧停止列表循环
        const currentPlay = this.currentPlayInfo;
        let currentIndex = -1;
        
        this.scenicAndGroup.map((item, index) => {
          if (
            item.id == currentPlay.playId &&
            item.typed == currentPlay.typed
          ) {
            currentIndex = index + 1;
          }
        });
        if (currentIndex != -1) {
          allChange(currentIndex);
        }
      }
    },
    // 实时更新游客位置，计算离景点的距离
    coords(val) {
      eventBus.$emit("userMarker", val);
      
      this.mapScenic.distance = this.getDistance(
        [this.mapScenic.scenic.latitude, this.mapScenic.scenic.longitude],
        val
      ); // 景区

      if(this.mapScenicList && this.mapScenicList.length > 0) {
        this.mapScenicList.map(item => {
          // 景点列表
          item.distance = this.getDistance([item.lat, item.lon], val);
          item.distanceNum = val.distanceTo(L.latLng([item.lat, item.lon]));
          if (item.isContainChildScenic) {
            // 子景点
            item.subScenics &&
              item.subScenics.map(item3 => {
                item3.childScenics.map(item4 => {
                  item4.distance = item.distance;
                });
              });
          }
        });

        // 首次定位成功设置
        if (this.firstTimeGetLoc) {
          this.firstTimeGetLoc = false;

          this.mapScenicList.sort((x, y) => x.distanceNum - y.distanceNum);

          const obj = utils.getUrlParamToObj(window.location.href);
          const auditionArr = localStorage.getItem(`audition_${obj.scenicId}`);
          // 如果没有试听点就添加
          if (!auditionArr) {
            const arr = [];
            this.mapScenicList.forEach((item, index) => {
              if (index < 3) {
                item.isAudition = true;
                arr.push(item.id);
              }
            });
            localStorage.setItem(`audition_${obj.scenicId}`, arr.join(','));
          }

          this.$refs.audioListRef.refreshList();
        }
      }

      this.mapScenicGroup &&
        this.mapScenicGroup.length > 0 &&
        this.mapScenicGroup &&
        this.mapScenicGroup.map(item => {
          // 景点组列表
          item.distance = this.getDistance([item.lat, item.lon], val);
          item.subScenics.map(item2 => {
            item2.distance = this.getDistance([item2.lat, item2.lon], val);
          });
      });
         
      this.mapNearServiceList &&
        this.mapNearServiceList.length > 0 &&
        this.mapNearServiceList.map(item => {
          // 服务点列表
          item.nearServices.map(item2 => {
            item2.distance = this.getDistance([item2.lat, item2.lon], val);
          });
        });
      // 播放器信息初始
      if (!this.playInit) {
        this.$store.dispatch("updatePlayInfo", {
          distance: this.mapScenic.distance,
          latLng: {
            lat: this.mapScenic.scenic.latitude,
            lng: this.mapScenic.scenic.longitude
          }
        });
        this.$store.dispatch("updatePlayInit", true);
      }

      // 自动讲解初始触发，只限景点
      if (
        this.autoExplain == 1 &&
        this.mapScenicList.length > 0
         && this.isInScenic
         && this.currentPlayInfo.playStatus === 0
      ) {
        this.mapScenicList.map(item => {
          if (
            // 播放完不会清掉playId，此处去掉id判断
            // item.id != this.currentPlayInfo.playId &&
            item.isVoice &&
            L.latLngBounds(this.scenicBounds(item)).contains(
              L.latLng([val.lat, val.lng])
            )
          ) {
            // 判断游客附近的景点，触发自动讲解
            eventBus.$emit("changeAudioInfo", { item, status: 1 });
          }
        });
      }
    },

    vipItemList(val) {
      if (val) {
        const obj = utils.getUrlParamToObj(window.location.href);
        if (obj.isBuy == '1' && obj.isAiBot !== '1') {
          this.buyVip();
        }
        if (val.productSpecVOS.length > 0) {
          this.canBuy = true;
        } else {
          this.canBuy = false;
        }
      }
    },

    elecUser(val) {
      this.isFree = val.isFree;
    },
    aiBotVipItemList(val) {
      if(val && val.productSpecVOS && val.productSpecVOS.length > 0) {
        this.paymentInfo = {
          paymentUrl: "pay",
          account: val.productSpecVOS[0].salePrice, // 10元
        }
      }
    }
  },
  computed: {
    ...mapState({
      hasLocationLoading: state => state.app.hasLocationLoading,
      autoExplain: state => state.app.autoExplain,
      selectLanguage: state => state.app.selectLanguage,
      coords: state => state.app.coords,
      currentPlayInfo: state => state.app.currentPlayInfo,
      havedUseAutoExplain: state => state.app.havedUseAutoExplain,
      isInScenic: state => state.app.isInScenic,
      currentGroupItem: state => state.app.currentGroupItem,
      listLoop: state => state.app.listLoop,
      loopIndex: state => state.app.loopIndex,
      playInit: state => state.app.playInit,
      isGoto: state => state.app.isGoto,
      isVip: state => state.user.isVip,
      vipItemList: state => state.user.vipItemList,
      elecUser: state => state.user.elecUser,
      aiBotInfo: state => state.user.aiBotInfo,
      aiBotVipInfo: state => state.user.aiBotVipInfo,
      aiBotVipItemList: state => state.user.aiBotVipItemList,
    }),
    
    playInfoStatus() {
      return this.currentPlayInfo.playStatus;
    },
    // 景点平铺数据（景区 + 普通景点 + 子景点组 + 景点组）
    // 普通景点没有subScenics
    // 景点组subScenics下面直接是景点
    // 子景点组subScenics下面的childScenics才是景点
    scenicAndGroup() {
      let endAry = [
        {
          // 景区
          ...this.mapScenic.scenic,
          voices: this.mapLanguage.voices,
          typed: 1,
          distance: this.mapScenic.distance
        }
      ];
      if (this.mapScenicList.length > 0) {
        endAry = endAry.concat(this.mapScenicList);
      }
      
      if (this.mapScenicGroup.length > 0) {
        endAry = endAry.concat(this.mapScenicGroup);
      }
      
      endAry.map((item, index) => {
        item.subScenics &&
          item.subScenics.map(item2 => {
            if (item2.childScenics && item2.childScenics.length > 0) {
              item2.childScenics.map(item3 => {
                // 子景点组
                endAry.push(item3);
              });
            } else {
              // 景点组
              endAry.push(item2);
            }
          });
      });
      endAry = endAry.filter(item => item.voices);
      return endAry;
    },
    offsetBottomY() {
      return this.movePositionValue + this.endPositionValue;
    },
    platformIos() {
      return utils.os.isIOS;
    },
    cannotPlay() {
      return this.$store.getters.cannotPlay;
    },
    isAssociationAiBot() {
      return this.$store.getters.isAssociationAiBot;
    },
  },
  methods: {
    fakeLoc() {
      this.fakeIndex += 1;
      const obj = this.mapScenicList[this.fakeIndex];
      this.$store.dispatch('updateCoords', L.latLng(obj.lat, obj.lon));
    },
    // 埋点：产品详情页访问记录
    accessRecord() {
      const url = window.location.href;
      const obj = utils.getUrlParamToObj(url);
      const salesmanId = obj.salesmanId || localStorage.getItem('salesmanId');
      const params = {
        salesmanId,
        type: 2
      };
      axios({
        method: 'post',
        url: `${window.location.origin}/guide-distribution/api/pt/index/access/record/${params.salesmanId}/${params.type}`,
        headers: {
          'h5-token': this.$store.state.user.elecUser.token || '',
          "Content-Type": "application/json"
        }
      }).then(res => {
        console.log('111', res);
      });
    },
    // 计算景点边界
    scenicBounds(item) {
      const onebounds = [];
      // const twobounds = [];
      let bounds;
      onebounds.push([item.leftBottomLat, item.leftBottomLon]);
      onebounds.push([item.rightTopLat, item.rightTopLon]);
      onebounds.push([item.rightBottomLat, item.rightBottomLon]);
      onebounds.push([item.leftTopLat, item.leftTopLon]);
      
      bounds = L.latLngBounds(onebounds);
      return bounds;
    },
    getDistance(value, dist) {
      if (!value || !dist) {
        // 如果传过来的有一个无效，则提示
        return "--";
      }
      const distance = dist.distanceTo(L.latLng(value));
      let endDistance = 0;
      if (distance < 1000) {
        endDistance = `${distance.toFixed(1)}米`;
      } else if (distance > 1000) {
        endDistance = `${(Math.round(distance / 100) / 10).toFixed(1)}公里`;
      }
      return endDistance;
    },
    showModal(modal) {
      // 此modal是对象
      eventBus.$emit("showModal", modal);
    },
    showNavModal() {
      window.location.reload();
    },
    // 用户点击定位按钮
    locationHander(isUser) {
      if (isUser) {
        this.$store.dispatch("CHANGE_LC_LOADING", false);
        eventBus.$emit("location", { userTrigger: true });
      }
    },
    // 控制台上下显示
    // expandControlPlatform() {
    //   this.controlExpanded = !this.controlExpanded;
    // },
    // 打开或者关闭小景点的列表
    openAudioList() {
      const number = -200 * (window.innerWidth / 375);
      if (this.endPositionValue === number) this.endPositionValue = 0;
      else this.endPositionValue = number;
      // if (!this.modalAll.audioListModal.isShow) {
      //   eventBus.$emit("hideAllModal");
      // }
      // this.modalAll.audioListModal.isShow = !this.modalAll.audioListModal.isShow;
    },
    buyVip() {
      if (wx) {
        // console.log("buyVip---", this.$store.state.user, this.$store.state.user.userId)
        // if (!this.$store.state.user.userId) {
        //   wx.miniProgram.switchTab({
        //     url: "/pages/user/user",
        //   });
        //   return;
        // }
        this.showModal({ whichModal: 'shopDetailModal' });
      }
    },
    showAiModal() {
      // 暂停音频播放
      if(this.currentPlayInfo.currentVoice && this.currentPlayInfo.playStatus === 1) {
        eventBus.$emit('changePlayInfo', { playStatus: 2 });
      }
      this.isDigitalHumanShow = true
      
      this.getAiPaymentInfo()
    },
    showChatBox() {
    // 暂停音频播放
      if(this.currentPlayInfo.currentVoice && this.currentPlayInfo.playStatus === 1) {
          eventBus.$emit('changePlayInfo', { playStatus: 2 });
        }
        this.isChatBoxShow = true
        
        this.getAiPaymentInfo()
    },
    closeAiModal() {
      console.log("=====",this.$refs.chatBox)
      this.$refs.chatBox && this.$refs.chatBox.pauseAudio();
      this.isDigitalHumanShow = false
      this.isChatBoxShow = false

      this.getAiPaymentInfo()
    },
    handleAiTap(e) {
      console.log("======AI isPay======",e)
      if(e && e.isPay) {
        this.isDigitalHumanShow = false
        this.isChatBoxShow = true
      } else {
        this.closeAiModal()
        this.showModal({ whichModal: 'aiShopDetailModal' });
      }
    },

    aiPauseFmPlayer(e,obj) {
      console.log('音频播放暂停：',e,obj)
    },
    aiScrollup() {

    },
    aiScrolldown() {

    },
    getAiPaymentInfo() {
          // 	0无权益(支付购买),1拥有有效权益，2权益已退款
        if(this.aiBotVipInfo && this.aiBotVipInfo.interestStatus === 1 && this.aiBotVipInfo.orderNoSys) {
          getPaymentInfo(
            {
              mediaUserInfo: this.mediaInfo,
              orderid: this.aiBotVipInfo.orderNoSys,
            },
            (res) => {
              console.log("=======Baidu Ai getAiPaymentInfo success=======",res)
              // 1. 查询不存在的订单也回调success但res为undefined
              // 2. 查询已充值订单：{orderid: "xxx", status: "PAID", consumeTimes: 0, maxConsumeTimes: 300}
              // 3. 查询已退款订单，还是能查询到次数但status为"REFUND":{orderid: "xxx", status: "REFUND", consumeTimes: 0, maxConsumeTimes: 300}
              if(res && res.orderid && res.status === 'PAID') {
                this.aiBotResidueNum = Number(res.maxConsumeTimes) - Number(res.consumeTimes)
                this.uploadAiConsumeCount(this.aiBotResidueNum,res.maxConsumeTimes)
              }
            },
            (err) => {
              console.log("=======Baidu Ai getAiPaymentInfo fail=======",err)
            },
          )
        }
    },
    // 上传
    uploadAiConsumeCount(residueNum,totalNum) {
        axios({
          method: 'put',
          url: `${window.location.origin}/guide-distribution/api/pt/interest/aiBot/interestNum/${this.aiBotVipInfo.userInterestId}/${residueNum}/${totalNum}`,
          headers: {
            'h5-token': this.$store.state.user.elecUser.token || '',
            "Content-Type": "application/json"
          }
        }).then(res => {
          console.log('=======Baidu Ai upload consumeTimes=======', res);
        });
    },

    // 风险
    // 1. 后台充值成功，ai充值失败无法使用
    // 2. 后台退款成功，ai退款失败继续使用
    // 3. 如果跳转DigitalHumanComp则只由ai判断权益，不清楚他们的判断规则（有效期 + 次数，假如用户多笔订单怎么判断）
    // 4. 如果需要直接跳转chatBox，则需要自己判断权益
    handleAiPay() {
      payment(
        {
          mediaUserInfo: this.mediaInfo,
          'orderid': this.aiBotVipInfo.orderNoSys,
          'payTime': this.aiBotVipInfo.payTime,
          'status': 'PAID',
          // account需要大于0，充值后次数固定为300不可定制
          'account': this.paymentInfo && this.paymentInfo.account || '100',
          'effectiveStartTime': this.aiBotVipInfo.effectiveStartTime,
          'effectiveEndTime': this.aiBotVipInfo.effectiveEndTime,
        },
        (res) => {
          // 1. 充值成功res为undefined
          // 2. 订单号重复充值时回调success，且res为{msg: "订单号重复"}
          // 3. 退款后再通过这个订单号充值，res为{msg: "订单号重复"}
          console.log("=======Baidu Ai Pay success=======",res)
          this.getAiPaymentInfo()
        },
        (err) => {
          console.log("=======Baidu Ai Pay fail=======",err)
        },
      );
    },

    handleAiRefund() {
      payment(
        {
          mediaUserInfo: this.mediaInfo,
          'orderid': this.aiBotVipInfo.orderNoSys,
          'payTime': this.aiBotVipInfo.payTime,
          'status': 'REFUND'
        },
        (res) => {
          // 1. 退款成功res为undefined
          // 2. 订单号重复退款时回调success，且res为{msg: "该订单无法退款"}
          console.log("=======Baidu Ai Refund success=======",res)
          // AiBot权益标记退款状态
          axios({
            method: 'put',
            url: `${window.location.origin}/guide-distribution/api/pt/interest/aiBot/markRefunded/${this.aiBotVipInfo.orderId}/${this.aiBotVipInfo.userInterestId}`,
            headers: {
              'h5-token': this.$store.state.user.elecUser.token || '',
              "Content-Type": "application/json"
            }
          }).then(res => {
            console.log('=======Baidu Ai mark refunded success=======', res);
          });
        },
        (err) => {
          console.log("=======Baidu Ai Refund fail=======",err)
        },
      );
    },

    updateAiInfo(aiInfo,aiVipInfo) {
      this.mediaInfo = {
        appid: aiInfo.appId,
        token: aiInfo.token,
        taid: aiInfo.taid,
        taname: aiInfo.taname,
        tsid: aiInfo.tsid,
        tsname: aiInfo.tsname,
        openid: aiVipInfo.userAccount
      }
      this.userInfo = {
        openid: aiVipInfo.userAccount
      }
    }
  },
  created() {
  },
  mounted() {
    if (this.platformIos) {
      setTimeout(() => {
        this.endPositionValue = -200 * (window.innerWidth / 375);
        console.log('ios', window.innerWidth, this.endPositionValue);
      }, 500);
    }

    const url = window.location.href;
    const obj = utils.getUrlParamToObj(url);
    const salesmanId = obj.salesmanId || localStorage.getItem('salesmanId');
    // ???
    this.isFree = obj.isFree;
    if (!this.$store.state.user.elecUser.token && !obj.token) {
      window.location.href = `${window.location.origin}/guide-distribution/api/pb/wechat/silent/authorize?salesmanId=${salesmanId}&returnUrl=${encodeURIComponent(url)}`;
    }
    this.accessRecord();
    console.log(localStorage.getItem('salesmanId'), 'elecsalesmanId');
    console.log(this.$store.state.user.elecUser.token, 'elecUserToken');
    console.log(this.$store.state.user, 'productSpecVOS');
    Indicator.open({
      spinnerType: "triple-bounce"
    });
    // 播放器点击去那里
    eventBus.$on("gotoHere", item => {
      this.$store.dispatch("updateRouteObject", null);
      sessionStorage.removeItem("routeObject");
      eventBus.$emit("hideAllModal");
      utils
        .gaodeRoutePlanning(
          this.coords,
          [
            item.lat || item.latLng.lat,
            item.lng || item.lon || item.latLng.lng
          ],
          item
        )
        .then(res => {
          console.log(res);
          this.$store.dispatch("updateIsGoto", true);
          this.$store.dispatch("updateRouteObject", res);
        })
        .catch(() => {
          this.$store.dispatch("updateIsGoto", false);
          this.$store.dispatch("updateRouteObject", null);
        });
    });
    // service的goto
    eventBus.$on("serviceGoto", item => {
      if (!item.distance) {
        this.$toast({
          message: "位置服务获取失败，无法导航",
          position: "bottom"
        });
      } else if (!this.isInScenic) {
        this.$toast({
          message: "您不在景区范围内，无法导航",
          position: "bottom"
        });
      } else {
        eventBus.$emit("gotoHere", item);
      }
    });
    // 更改播放器信息
    eventBus.$on("changeAudioInfo", obj => {
      console.log("obj", obj);
      if (this.cannotPlay && !obj.item.isAudition) {
        eventBus.$emit('showVipPackageMask', {});
        return;
      }
      const language = this.selectLanguage;
      let endVoice;
      
      obj.item.voices.map(item2 => {
        if (this.isThisVoice(language, item2) !== undefined) {
          endVoice = this.isThisVoice(language, item2);
        }
      });
      this.$store.dispatch("updatePlayInfo", {
        title: obj.item.name,
        currentTime: 0,
        distance: obj.item.distance,
        latLng: {
          lat: obj.item.lat || obj.item.latitude,
          lng: obj.item.lon || obj.item.longitude
        },
        // playStatus: 0, // 0为未播放，1为播放，2为暂停
        voices: obj.item.voices, // 播放的url，时长等信息
        currentVoice: endVoice, // 当前url
        typed: obj.item.typed,
        playId: obj.item.id,
        currentItem: obj.item
      });
      
      audios.load(); // iosBUS所需
      setTimeout(() => {
        eventBus.$emit("changePlayInfo", { playStatus: obj.status });
      });
    });
    // 更改路线
    eventBus.$on("changeRoute", item => {
      
      this.mapRoutes.map((mk, index) => {
        if (mk.id === item.id) {
          this.currentRoutes = this.mapRoutes[index];
          this.$nextTick(() => {
            eventBus.$emit("routeBounds");
          });
        }
      });
    });
    // 播放列表显示隐藏
    eventBus.$on("audioListOpen", () => {
      this.openAudioList();
    });
    // 顶部栏tab切换服务
    
    eventBus.$on("selectService", service => {
      if (this.currentNearService.id === service.id) {
        // 如果当前再次点击则不会触发下面
        return false;
      }
      this.openDown = false;
      this.$store.dispatch("updateServiceShow", false); // 防止弹窗继续存在
      
      this.nearServiceData.map(item => {
        item.select = false;
      });
      
      service.select = true;
      this.currentNearService = service;
      if (this.currentNearService.type === -1) {
        // 切换为景点
        this.currentServiceList = this.mapScenicList;
        eventBus.$emit("boundsInit");
      } else if (this.currentNearService.type === -2) {
        // 切换为路线
        this.currentServiceList = this.mapRoutes;
        eventBus.$emit("routeBounds");
      } else if (this.currentNearService.type === 3) {
        // 切换为不同服务点
        setTimeout(() => {
          this.$store.dispatch("updateServiceShow", true);
        }, 10);
        
        this.mapNearServiceList.map(item => {
          if (
            item.nearServiceType.type === service.type &&
            item.nearServiceType.id === service.id
          ) {
            this.currentServiceList = item.nearServices;
          }
        });
      }
      setTimeout(() => {
        this.openDown = true;
      }, 5);
    });
    // 修改景点selected
    eventBus.$on("changeSelected", item => {
      // console.log(item);
      this.currentServiceList.map(items => {
        items.selected = false;
      });
      if (item.typed && item.typed === 3) {
        // 子景点
        this.mapScenicList
          .filter(item3 => item3.isContainChildScenic === 1)
          .map((item2, index) => {
            if (item2.id === item.parentId) {
              item2.selected = true;
            }
          });
      } else {
        this.currentServiceList.map(items => {
          if (items.id === item.id) {
            items.selected = true;
          }
        });
      }
    });
    // 顶部下拉框
    eventBus.$on("openSlideDown", () => {
      if (!this.modalAll.slideDownModal.isShow) {
        eventBus.$emit("hideAllModal");
      }
      this.modalAll.slideDownModal.isShow = !this.modalAll.slideDownModal
        .isShow;
    });
    // 隐藏所有弹窗等
    eventBus.$on("hideAllModal", () => {
      // this.showBackBtn = true;
      const all = this.modalAll;
      
      for (let item in all) {
        // if (!all[item].isShow) {
        //   all[item].isShow = false;
        // }
        // if (!all[item].showMask) {
        //   all[item].showMask = false;
        // }
        all[item].isShow = false;
        all[item].showMask = false;
      }
    });
    // 隐藏个别弹窗
    eventBus.$on("hideModal", modal => {
      const all = this.modalAll;
      
      all[modal].isShow = false;
      setTimeout(() => {
        all[modal].showMask = false;
      }, 200);
    });
    // 显示个别弹窗
    eventBus.$on("showModal", modal => {
      // 先关闭所有弹窗
      // eventBus.$emit('hideAllModal');
      // 反馈弹窗需要传参
      if (modal.whichModal === "feedBackDetailModal") {
        this.feedBackArg = modal.arg;
      }
      // 景点详情弹框传参
      if (modal.whichModal === "scenicDetailModal") {
        this.scenicDetailOn = modal.arg;
      }
      // 子景点组和景点组
      if (modal.whichModal === "scenicGroupModal") {
        this.isChildrenGroup = modal.arg;
      }
      const all = this.modalAll;
      
      all[modal.whichModal].isShow = true;
      all[modal.whichModal].showMask = true;
    });
    // 显示开通的vip列表弹窗
    eventBus.$on("showVipPackageMask", () => {
      this.buyVip();
    });

    eventBus.$on("posterUrlChange", value => {
      if (value) {
        this.sharePosterUrl = value;
      }
    });
    eventBus.$on('showAi', (item) => {
      if (item) {
        if(this.aiBotVipInfo && this.aiBotVipInfo.interestStatus === 1 && this.aiBotResidueNum > 0) {
          this.showChatBox()
        } else {
          this.showAiModal()
        }
      } else {
        this.closeAiModal()
      }
    })

    // // 支付成功后，刷新vip状态
    // eventBus.$on("refreshVipStatus", () => {
    //   this.vipTimer = setInterval(() => {
    //     console.log("支付后每隔2.5秒执行一次VIP状态");
    //     // if (utils.os.isIOS) {
    //     //   eventBus.$emit('cancelRefreshVipStatus');
    //     //   return;
    //     // }
    //     if (this.refreshCount > 5) {
    //       eventBus.$emit('cancelRefreshVipStatus');
    //       return;
    //     }
    //      no-plusplus
    //     this.refreshCount++;
    //     this.$store.dispatch("refreshVipStatus");
    //   }, 2500);
    // });
    // // 取消定时器
    // eventBus.$on("cancelRefreshVipStatus", () => {
    //   eventBus.$emit("hideModal", "shopDetailModal");
    //   clearInterval(this.vipTimer);
    //   console.log("支付后---取消定时器");
    // });
    setTimeout(() => {
      eventBus.$emit("location", { userTrigger: false });
    }, 2000);
    this.$store.dispatch("updateIsIndoor", false);
    this.timer = setInterval(() => {
      eventBus.$emit("location", { userTrigger: false });
    }, 10000);

    // control滑动
    eventBus.$on("postTouchMove", (value) => {
      this.movePositionValue = value;

      console.log('postTouchMove', this.movePositionValue);
    });
    eventBus.$on("postTouchEnd", () => {
      if (Math.abs(this.movePositionValue) < 10) {
        this.openAudioList();
        return;
      }
      this.endPositionValue = this.movePositionValue + this.endPositionValue;
      this.movePositionValue = 0;

      const tempOffset1 = -200 * (window.innerWidth / 375);
      const tempOffset2 = -138 * (window.innerWidth / 375);
      if (this.offsetBottomY >= 0) {
        this.endPositionValue = 0;
        eventBus.$emit('audioShowStatus', 3);
      } else if (this.offsetBottomY > tempOffset1 / 2) {
        this.endPositionValue = 0;
        eventBus.$emit('audioShowStatus', 3);
      } else if (this.offsetBottomY > tempOffset1) {
        this.endPositionValue = tempOffset1;
        eventBus.$emit('audioShowStatus', 2);
      } else if (this.offsetBottomY > tempOffset1 + (tempOffset2 * (2 / 3))) {
        this.endPositionValue = tempOffset1;
        eventBus.$emit('audioShowStatus', 2);
      } else {
        this.endPositionValue = tempOffset1 + tempOffset2 + 12;
        eventBus.$emit('audioShowStatus', 1);
      }
    });
    eventBus.$on("controlResume", () => {
      this.endPositionValue = -200 * (window.innerWidth / 375);
    });
  },
  beforeDestroy() {
    eventBus.$off();
    this.$store.dispatch("updateLoopIndex", -1);
    this.$store.dispatch("updateListLoop", 2);
    this.$store.dispatch("updateIsGoto", false);
    // this.$store.dispatch('updateRouteObject', null);

    clearInterval(this.timer);
  }
};
</script>
<style lang="scss" scoped>
.homeWrap {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 111;
}
.home {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 111;
}

.right_top_menu {
  z-index: 2;
  position: absolute;
  top: 66px;
  right: 10px;
  .menu_wrap {
    margin-bottom: 11px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 34px;
    .menu_btn {
      background: none;
      box-shadow: none;
      margin-bottom: 0;
    }
  }
  .menu_btn {
    width: 34px;
    padding: 5px 0;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 11px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .pp {
      font-size: 10px;
      // white-space: nowrap;
    }
    .svg-icon {
      margin-bottom: 2px;
      width: 18px;
      height: 16px;
    }
  }
}
.left_bottom {
  position: fixed;
  left: 8px;
  z-index: 2;
  bottom: 192px;
  .lbt_btn {
    width: 36px;
    height: 36px;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 4px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    .locationpng {
      width: 18px;
      height: 18px;
    }
  }
  .feedback {
    flex-direction: column;
    margin-bottom: 10px;
    width: 36px;
    height: 36px;
    .locationpng {
      width: 100%;
      height: 100%;
    }
  }
}
.bottom_shop {
  padding: 0 8px;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  z-index: 2;
  border-radius: 5px;
  position: absolute;
  right: 0;
  top: -40px;
  .bs_left {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    img {
      width: 24px;
      margin-right: 8px;
      height: 24px;
    }
    span {
      font-size: 14px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: rgba(22, 24, 22, 1);
    }
  }
  .bs_right {
    width: 76px;
    height: 30px;
    background: rgba(255, 210, 61, 1);
    text-align: center;
    line-height: 30px;
    font-size: 14px;
    font-family: PingFangSC-Regular;
    font-weight: 600;
    color: #333;
    border-radius: 6px;
  }
  .dis-buy-btn {
      opacity: 0.6;
  }
}
.position_wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}
.transform_bottom {
  transition:bottom .3s;
  -moz-transition:bottom .3s; /* Firefox 4 */
  -webkit-transition:bottom .3s; /* Safari and Chrome */
  -o-transition:bottom .3s; /* Opera */
}
.transform_enter {
  bottom: 0;
}
.transform_leave {
  bottom: -138px;
}

.robot-wrap {
  // box-shadow: 0 2px 4px 0 rgba(13, 35, 82, 0.28);

  top: 66px;
  left: 16px;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  .svg-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    // animation-name: rotateAnimation; /* 指定动画名称为rotateAnimation */
    // animation-duration: 8s; /* 设置动画持续时间为5秒 */
    // animation-iteration-count: 3;
    // animation-timing-function: linear
  }

  .robot-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 4px 6px 0 rgba(13, 35, 82, 0.5);
    pointer-events: all
  }

  .robot-tips {
    background: rgba(255,255,255,0.94);
    box-shadow: 0 2px 4px 0 rgba(13,35,82,0.28);
    height: 24px;
    line-height: 24px;
    border-radius: 8px;
    padding: 0px 3px 0px 10px;
    margin-left: 10px;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 12px;

    .robot-close-btn {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      border-radius: 50%;
      justify-content: center;

      .svg-icon{
          width: 18px;
          height: 18px;
        }
    }
  }

  .robot-tips::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-right: 8px solid rgba(255,255,255,0.94);
    border-left: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    left: -12px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.digital-container {
    position: absolute;
    top: 80px;
    height: calc(100% - 80px) !important;
    width: 100%;
    z-index: 999;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;
  .digital-box {
    height: calc(100%) !important;
  }

  .digital-close {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1000;
    width: 30px;
    height: 30px;
    display: block;
    pointer-events: all
  }

}

.lv-chat-box-container {
    position: absolute;
    top: 80px;
    height: calc(100% - 80px) !important;
    width: 100%;
    z-index: 999;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    // overflow: hidden;
    background-image: linear-gradient(1deg, rgb(242, 247, 255) 0%, rgb(242, 245, 247) 14%, rgb(230, 242, 255) 80%, rgb(215, 235, 255) 94%, rgb(215, 235, 255) 100%);
    .lv-chat-box {
      height: calc(100%) !important;
      padding-top: 18px;
    }

  .lv-digital-close {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1000;
    width: 30px;
    height: 30px;
    display: block;
    pointer-events: all
  }

  .lv-chat-box-icon {
    position: absolute;
    top: -30px;
    left: 20px;
    z-index: 1000;
    width: 60px;
    height: 60px;
    display: block;
    pointer-events: all;
    border-radius: 50%;
    box-shadow: 0 0.10667rem 0.16rem 0 rgba(13, 35, 82, 0.5);
  }

}

::v-deep .message-inner {
    padding-bottom: 95px;
}

// ::v-deep .progress-box {
//     width: unset !important
//   }

// .robot-wrap.hasNotice {
//     top: 64px
// }
</style>
