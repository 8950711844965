import { getScenceData, getVoice, getScenicList, getRoutes, getSubScenicGroups, getNearServices, getChildScenicGroups } from '@/api';
import utils from '@/utils/utils';
import wx from 'weixin-js-sdk';
import axios from 'axios';
// 图片
import scenicNoSelect from "../assets/images/scenic/scenic_noselect.png";
import scenicSelect from "../assets/images/scenic/scenic_select.png";
import roadNoSelect from "../assets/images/scenic/road_noselect.png";
import roadSelect from "../assets/images/scenic/road_select.png";

export default {
  data() {
    return {
      scenicId: null,
    };
  },
  watch: {
    // 实时更新语言,typed用于currentPlayInfo播放器信息切换时候用,(typed:1为景区，2为景点，3为子景点组，4为景点组)
    // 另外在currentNearService存在一个type字段，属于区分服务类型的。注意别混淆(type:-1为景点，-2为路线，3为服务点类型)
    selectLanguage(val) {
      console.log("============================",this.currentPlayInfo)
      const typeds = this.currentPlayInfo.typed;
      let endVoice;
      let endId;
      if (typeds === 1) {
        this.mapLanguage.voices.map((item) => {
          if (this.isThisVoice(val, item) !== undefined) {
            endVoice = this.isThisVoice(val, item);
            endId = this.scenicId;
          }
        });
      } else if (typeds === 2) {
        this.mapScenicList.map((item) => {
          if (item.typed === this.currentPlayInfo.typed && item.id === this.currentPlayInfo.playId) {
            item.voices.map((item2) => {
              if (this.isThisVoice(val, item2) !== undefined) {
                endVoice = this.isThisVoice(val, item2);
                endId = item.id;
              }
            });
          }
        });
      } else if (typeds === 3) { // 子景点 
        this.mapScenicList.filter(item => item.isContainChildScenic === 1).map((item2) => {
          item2.subScenics.map((child) => {
            child.childScenics.map((child2) => {
              child2.voices.map((item3) => {
                if (this.isThisVoice(val, item3) !== undefined) {
                  endVoice = this.isThisVoice(val, item3);
                  endId = child2.id;
                }
              });
            });
          });
        });
      } else if (typeds === 4) {
        this.mapScenicGroup.map((item) => {
          // isparentGroup 不是一直为true?
          if (item.isparentGroup) {
            if (item.typed === this.currentPlayInfo.typed && item.id == this.currentPlayInfo.playId) {
              item.voices.map((item2) => {
                if (this.isThisVoice(val, item2) !== undefined) {
                  endVoice = this.isThisVoice(val, item2);
                  endId = item.id;
                }
              });
            }
          } else {
            // ??????
            item.subScenics.map((item2) => {
              item2.subScenics.map((item3) => {
                if (item3.typed === this.currentPlayInfo.typed && item3.id == this.currentPlayInfo.playId) {
                  item3.voices.map((item4) => {
                    if (this.isThisVoice(val, item4) !== undefined) {
                      endVoice = this.isThisVoice(val, item4);
                      endId = item3.id;
                    }
                  });
                }
              });
            });
          }
        });
      }
      if (endVoice !== undefined) {
        this.$store.dispatch('updatePlayInfo', {
          currentVoice: endVoice,
          playId: endId,
          playStatus: 0,
        });
      } else {
        this.$store.dispatch('updatePlayInfo', {
          currentVoice: null,
          playStatus: 0,
        });
      }

      this.$toast({
        message: `当前语音为：${val.name}`,
        position: 'bottom',
      });
    },
  },
  methods: {
    
    isThisVoice(val, item) {
      if (val.parentId == item.languageId && val.id == item.styleId) {
        return item;
      }
    },

    getShareInfo(salesmanId, supplierProdId, token, scenicId) {
      axios({
        method: 'get',
        url: `${window.location.origin}/guide-distribution/api/pt/product/share/info`,
        params: {
          salesmanId,
          supplierProdId
        },
        headers: {
          'h5-token': token
        }
      }).then((res) => {
        if (res.data.code === 200) {
          const linkUrl = `${window.location.origin}/guide/?scenicId=${scenicId}&salesmanId=${salesmanId}&supplierProdId=${supplierProdId}`;
          wx.ready(() => {
            wx.hideMenuItems({
              menuList: ["menuItem:copyUrl"]
            });
            const data = res.data.data;
            wx.updateAppMessageShareData({ // 分享朋友
              title: data.name, // 标题
              desc: linkUrl, // 描述
              link: linkUrl, // 链接
              imgUrl: data.introPicPath, // 分享图标
              success() {
              }
            });
            wx.updateTimelineShareData({ // 分享到朋友圈
              title: data.name, // 标题
              desc: linkUrl, // 描述
              link: linkUrl, // 链接
              imgUrl: data.introPicPath, // 分享图标
              success() {
              }
            });
          });
        }
      });
    }
  },
  mounted() {
    this.nearServiceData = [];
    // 进入页面，获取景区信息
    const obj = utils.getUrlParamToObj(window.location.href);
    this.scenicId = obj.scenicId;
    const { salesmanId, supplierProdId } = obj;
    const newToken = this.$cookies.get('elec-guide-dist-h5-token');
    this.$store.dispatch('setToken', { salesmanId, supplierProdId, token: newToken });
    const params = { supplierProdId };

    // 导览是否收费
    axios.get(`${window.location.origin}/guide-distribution/api/pt/product/costType`, {
      params,
      headers: {
        'h5-token': newToken
      }
    }).then(res => {
      console.log(res, '-----res---');
      localStorage.setItem('isFree', res.data.data);
      this.$store.dispatch('setToken', { salesmanId, supplierProdId, token: newToken, isFree: res.data.data });
    });

    // 是否是vip
    axios.get(`${window.location.origin}/guide-distribution/api/pt/interest/${supplierProdId}`, {
      headers: {
        'h5-token': newToken
      }
    }).then(res => {
      if (res.data.code == 200) {
        this.$store.commit('setVip', res.data.data);
        const showActive = localStorage.getItem('showActive');
        const clickKnow = localStorage.getItem('clickKnow');
        console.log('setVip-----', res.data.data, obj);
        if (obj.toast && res.data.data && (obj.toast !== showActive || (obj.toast == showActive && clickKnow)) && obj.isAiBot !== '1') {
          this.modalAll.paySuccessModal.isShow = true;
          this.modalAll.paySuccessModal.showMask = true;
          localStorage.setItem('showActive', obj.toast);
          localStorage.setItem("clickKnow", '1');
        }
      }
    });
    // ai产品信息
    axios.get(`${window.location.origin}/guide-distribution/api/pt/product/bind/aiBot`, {
      params,
      headers: {
        'h5-token': newToken
      }
    }).then(res => {
      if (res.data.code == 200) {
        // res.data.data.pbOpenid = 'o82GF69uimh3TlAEXUbjiGBp96wo'
        const aiInfo = res.data.data
        this.$store.commit('setAiBotInfo', aiInfo);
        // isAssociationAiBot	是否关联AiBot 0否 1是
        // supplierProdAiBotId	关联的AiBot产品id
        if(aiInfo.isAssociationAiBot === 1) {
          axios.get(`${window.location.origin}/guide-distribution/api/pt/interest/aiBot/${aiInfo.supplierProdAiBotId}`, {
            headers: {
              'h5-token': newToken
            }
          }).then(res1 => {
            if (res1.data.code == 200) {
              // res.data.data.orderNoSys = '7777777777777'
              const vipInfo = res1.data.data
              this.$store.commit('setAiBotVipInfo', vipInfo);
              this.updateAiInfo(aiInfo,vipInfo)
              // 0无权益(支付购买),1拥有有效权益，2权益已退款
              if(vipInfo && vipInfo.interestStatus === 1) {
                this.getAiPaymentInfo()
                // 这里服务端没做区分，每次都调接口
                // this.handleAiPay()
              }
              // if(vipInfo && vipInfo.interestStatus === 2) {
              //   this.handleAiRefund()
              // }

              const aiToastTime = localStorage.getItem('aiToastTime');
              if(vipInfo && vipInfo.interestStatus === 1 && obj.toast && obj.toast !== aiToastTime && obj.isAiBot === '1') {
              //    方案一：  
                  // this.$toast({
                  //   message: 'AI对话解锁成功',
                  //   position: 'bottom',
                  // });
                  // localStorage.setItem('aiToastTime', obj.toast);
              //    方案二：
                  this.modalAll.payAiSuccessModal.isShow = true;
                  this.modalAll.payAiSuccessModal.showMask = true;
                  
              }
            }
          })

          axios.get(`${window.location.origin}/guide-distribution/api/pt/product/purchase/info`, {
            params:{
              salesmanId: salesmanId,	
              supplierProdId: res.data.data.supplierProdAiBotId
            },
            headers: {
              'h5-token': newToken
            }
          }).then(res2 => {
            if (res2.data.code == 200) {
              this.$store.commit('setAiBotVipTypeList', res2.data.data);
            }
          })
        }
      }
    })
    

    if (utils.os.isWechat) {
      axios({
        method: 'post',
        url: `${window.location.origin}/guide-distribution/api/pt/wechat/wxPublicJsSdkConfig`,
        headers: {
          'h5-token': newToken
        },
        data: { url: location.href.split('#')[0] }
      }).then((res) => {
        wx.config({
          debug: false, // 开启调试模式
          ...res.data.data,
          jsApiList: ['getLocation', 'checkJsApi', 'updateAppMessageShareData', 'hideOptionMenu',
            'hideMenuItems', 'onMenuShareTimeline', 'showOptionMenu', 'updateTimelineShareData'],
        });
        this.getShareInfo(salesmanId, supplierProdId, newToken, obj.scenicId);
      });
    }

    getScenceData(obj.scenicId).then((res) => {
      this.mapScenic = res.data;
      const mapScenic = res.data;
      document.title = mapScenic.scenic.name;
      this.$store.dispatch('setMapData', JSON.parse(JSON.stringify(this.mapScenic))); // 防止和vuex冲突
      
      this.mapScenic.map.map((item) => {
        if (item.isTileInH5) {
          
          item.tileUrl = `https://mimi-001.oss-cn-hangzhou.aliyuncs.com/scenicPicture/h5/${this.mapScenic.scenic.id}/{z}/{x}_{y}.png`;
        }
      });

      // 是否自动讲解赋值store
      this.$store.dispatch('updateAutoExplain', this.autoExplain || mapScenic.scenic.isDisplayAutoExplain);
      // 景区数据是否完成
      this.$store.dispatch('updateScenicInfo', true);
      // 播放器信息初始
      this.$store.dispatch('updatePlayInfo', {
        title: mapScenic.scenic.name,
        latLng: { lat: this.mapScenic.scenic.latitude, lng: this.mapScenic.scenic.longitude },
      });
    }, () => {
      // 景区加载失败则清空页面
      this.$store.dispatch('updateScenicInfo', false);
      this.$toast({
        message: '暂无网络或景区地图基础数据有误',
      });
    });
    // 由于语音类型的需要都加载完才能完成对当前语言和语音的匹配，所以要用promise.all。获取语音信息,获取景点列表
    Promise.all(
      [getVoice(obj.scenicId), getScenicList(obj.scenicId), getSubScenicGroups(obj.scenicId), getNearServices(obj.scenicId), getRoutes(obj.scenicId)],
    ).then((res) => {
      this.mapLanguage = res[0].data;
      this.mapLanguage.typed = 1;
      
      this.mapLanguage.languages.map((item, index) => {
        item.styles.map((item2, index2) => {
          item2.parentId = item.id;
          item2.duId = `${item.id}${item2.id}`;
          if (index === 0 && index2 === 0) {
            item2.selected = true;
            this.$store.dispatch('updateLanguage', item2);
          } else {
            item2.selected = false;
          }
        });
      });
      this.$store.dispatch('updatePlayInfo', {
        voices: this.mapLanguage.voices,
        playStatus: 0,
        typed: 1,
      });

      this.mapScenicList = res[1].data;

      const auditionArr = localStorage.getItem(`audition_${obj.scenicId}`);
      // 存在试听景点
      if (auditionArr) {
        this.mapScenicList.forEach(item => {
          if (auditionArr.indexOf(item.id) > -1) {
            item.isAudition = true;
          }
        });
      }

      this.nearServiceData.push(
        {
          select: true,
          id: -1,
          type: -1,
          name: '景点',
          defaultPicPath: scenicNoSelect,
          showPicPath: scenicSelect,
          listPicPath: '',
          infoPicPath: '',
          picPath: '',
        },
      );
      
      this.mapScenicList.map((item) => {
        item.typed = 2
        item.selected = false;
        if (item.isContainChildScenic === 1) {
          // 子景点组
          getChildScenicGroups(item.id).then((res) => {
            item.subScenics = res.data;
            // item.subScenics = [{
            //   childScenics:[{}],
            //   id: '',
            //   name:''
            // }]
            item.subScenics.map((child) => {
              child.typed = 3;
              child.childScenics.map((child2) => {
                child2.typed = 3;
                // 父景点显示子景点的选中状态
                child2.parentId = item.id // 用于后续找到parent有用
              });
            });
          });
        }
      });
      this.currentServiceList = this.mapScenicList; // 初始化进来首先看到的是景点

      this.mapScenicGroup = res[2].data;
      // [{
      //   id: '',
      //   ...
      //   subScenics: [{}]
      // }]
      this.mapScenicGroup.map((item) => {
        item.typed = 4;
        item.isparentGroup = true;
        
        item.subScenics.map((item2) => {
          item2.typed = 4;  
          item2.parentGroupId = item.id
          item2.isparentGroup = false;
        });
      });

      this.mapRoutes = res[4].data;
      
      this.mapRoutes.map((item, index) => {
        if (index === 0) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });
      this.currentRoutes = this.mapRoutes[0];

      if (this.mapRoutes.length > 0) {
        this.nearServiceData.push(
          {
            select: false,
            id: -2,
            type: -2,
            name: '路线',
            defaultPicPath: roadNoSelect,
            showPicPath: roadSelect,
            listPicPath: '',
            infoPicPath: '',
            picPath: '',
          });
      }

      this.mapNearServiceList = res[3].data;
      // [
      //   {
      //     nearServiceType:{},
      //     nearServices:[{}]
      //   }
      // ]
      this.mapNearServiceList.map((item) => {
        const item2 = item.nearServiceType;
        
        item.nearServiceType.type = 3;
        
        item.nearServices.map((child) => {
          child.listPicPath = item.nearServiceType.listPicPath;    
          child.infoPicPath = item.nearServiceType.infoPicPath; 
          child.picPath2 = item.nearServiceType.picPath;
        });
        this.nearServiceData.push({
          select: false,
          id: item2.id,
          type: 3,
          name: item2.name,
          defaultPicPath: item2.defaultPicPath,
          showPicPath: item2.showPicPath,
          listPicPath: item2.listPicPath,
          infoPicPath: item2.infoPicPath,
          picPath: item2.picPath,
        });
      });
    }, () => {
      this.$toast({
        message: '景区数据有误',
        position: 'bottom',
      });
    });
  },
};
