import axios from 'axios';

const state = {
  userToken: '',
  userId: '',
  isVip: false,
  vipItemList: {},
  elecUser: {},
  
  aiBotInfo: {},
  aiBotVipInfo: {},
  aiBotVipItemList: []
};

const actions = {
  setToken({ commit, dispatch }, elecUser) {
    commit('saveElecUser', elecUser);
    console.log(elecUser.isFree, '---------elecUser.isFree----------');
    if (elecUser.isFree == '0') {
      // dispatch('checkVip', elecUser);
      dispatch('getVipItemList', elecUser);
    }
  },
  // 检查是否有权益
  async checkVip({ commit }, info) {
    axios.get(`${window.location.origin}/guide-distribution/api/pt/interest/${info.supplierProdId}`, {
      headers: {
        'h5-token': info.token
      }
    }).then(res => {
      if (res.data.code == 200) {
        console.log('setVip', res.data.data);
        commit('setVip', res.data.data);
      }
    });
  },

  // 获取VIP套餐列表
  getVipItemList({ commit }, info) {
    const params = {
      salesmanId: info.salesmanId,
      supplierProdId: info.supplierProdId
    };
    axios.get(`${window.location.origin}/guide-distribution/api/pt/product/purchase/info`, {
      params,
      headers: {
        'h5-token': info.token
      }
    }).then(res => {
      console.log('获取VIP套餐列表', res);
      if (res.data && res.data.code == 200) {
        commit('setVipTypeList', res.data.data);
      }
    });
  },
};

const mutations = {
  // eslint-disable-next-line no-shadow
  setVip(state, isVip) {
    state.isVip = isVip;
  },

  // eslint-disable-next-line no-shadow
  setVipTypeList(state, info) {
    console.log('setVipTypeList', info);
    state.vipItemList = info;
  },

  // eslint-disable-next-line no-shadow
  saveElecUser(state, { salesmanId, supplierProdId, isFree, token }) {
    state.elecUser = {
      salesmanId,
      supplierProdId,
      isFree,
      token
    };
  },

  setAiBotInfo(state, info) {
    state.aiBotInfo = info
  },

  setAiBotVipTypeList(state, info) {
    state.aiBotVipItemList = info;
  },

  setAiBotVipInfo(state, info) {
    state.aiBotVipInfo = info
  },
};

export default {
  state,
  actions,
  mutations,
};
