
import BScroll from 'better-scroll';

export default{
  data() {
    return {
      scrollBar: null,
    };
  },
  created() {
    // this.$nextTick(() => {
    //   this.scrollBar = window.createScroll(this.$refs.scroll, {
    //     scrollbars: false,
    //   }); /* 添加流动条 */
    // });
  },
  methods: {
    scrolljs(isScrollX, liwidth, margin, lastMargin) {
      // 分别是li的宽度，li的marginRight，还有最后一个li的MarginRight*2
      this.$nextTick(() => {
        if (isScrollX) { // 横向滚动
          if (this.$refs.li && this.$refs.ul) {
            const width = (this.$refs.li.length * (liwidth + margin)) - lastMargin;
            this.$refs.ul.style.width = `${width / 37.5}rem`;
            this.scrollBar = new BScroll(this.$refs.scroll, {
              startX: 0,
              click: true,
              scrollX: true,
              // 忽略竖直方向的滚动
              scrollY: false,
              eventPassthrough: 'vertical',
            });
          }
        } else {
          this.scrollBar = new BScroll(this.$refs.scroll, {
            startX: 0,
            click: true,
            scrollX: false,
            // 忽略竖直方向的滚动
            scrollY: true,
          });
        }
      });
    },
  },
  updated() {
    // eslint-disable-next-line
    this.scrollBar && this.scrollBar.refresh(); /* 数据变成时刷新滚动条 */
    setTimeout(() => {
      // eslint-disable-next-line
      this.scrollBar && this.scrollBar.refresh();
    }, 300);
  },
};

