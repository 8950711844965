<template>
  <transition name="mask-modal">
    <div class="trans-mask-modal"
    :style="styleCustom || {zIndex:iszIndex?'3':'4'}"
    @click.stop.self='hideModal(currentModal)'>
      <slot name="modal"></slot>
    </div>
  </transition>
</template>

<script>
import eventBus from '@/eventBus';

export default {
  components: {},
  props: {
    currentModal: {
      type: String,
      default() {
        return '';
      },
    },
    iszIndex: { // 为了防止景点组它们内部弹出详情的时候会被遮盖住详情弹框，为true即将使用该属性的transition层级降一层，其他保持不变
      type: Boolean,
      default() {
        return false;
      },
    },
    styleCustom: {
      type: String,
      default() {
        return '';
      },
    }
  },
  data() {
    return {
    };
  },
  methods: {
    hideModal(modal) {
      eventBus.$emit('hideModal', modal);
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.mask-modal-enter, .mask-modal-leave-to /* .fade-down-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .mask-modal-enter {
    opacity: 1;
  }
  .trans-mask-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: auto;
    margin-top: 0px;
    top: 0;
    left: 0;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.6);
    z-index: 2;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
  }
</style>
