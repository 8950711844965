<template>
  <div class="gotoContainer" v-if="routeObject&&mapScenic">
    <div class="close_wrap" @click="closeGoto">
      <img class="th_close" src="../assets/f7/close.png">
    </div>
    <div class="gotoWrap">
      <div class="lcbtm">
        <div class="location myLocation"><p>我的位置</p></div>
        <div class="location desLocation">
          <p>{{routeObject.desItem.title||routeObject.desItem.name}}</p>
        </div>
      </div>
      <div class="gotoBtm">
        <div class="time">
          步行{{routeObject.duration|secondMinute}} {{routeObject.distance}}米
        </div>
        <div class="bttm">
          <div class="goto handTo" @click="gotoTextNavigation">手绘图导航</div>
          <div class="goto otherTo" @click="gotoOtherNavigation">其他导航</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import utils from '@/utils/utils';

export default {
  props: {
    mapScenic: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState({
      routeObject: state => state.app.routeObject,
    }),
  },
  methods: {
    gotoTextNavigation() {
      this.$router.push({
        name: 'textNavigation',
        query: {
          scenicId: this.mapScenic.scenic.id,
          lat: this.routeObject.desItem.lat || this.routeObject.desItem.latLng.lat,
          lng: this.routeObject.desItem.lon || this.routeObject.desItem.latLng.lng,
        },
      });
    },
    gotoOtherNavigation() {
      if (utils.os.isMiniWechat) { // 如果是小程序才能进行其他导航
        // const data = {
        //   origin: this.navigation.origin,
        //   destination: this.navigation.destination,
        //   name: this.navigation.target.name,
        // };
        // window.wx.miniProgram.navigateTo({
        //   url: `navigation?data=${encodeURIComponent(JSON.stringify(data))}`,
        // });
      } else {
        this.$toast({
          message: '请在微信小程序中打开',
          position: 'bottom',
        });
      }
    },
    closeGoto() {
      this.$store.dispatch('updateRouteObject', null);
      this.$store.dispatch('updateIsGoto', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.gotoBtm{
  flex:1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .bttm{
    display: flex;
    align-items: center;
  }
  .time{
    font-size: 14px;
  }
  .goto{
    text-align: center;
    width: 80px;
    height: 30px;
    line-height: 30px;
    background: #48CAD7;
    color: #fff;
    font-size: 12px;
    border-radius: 15px;
    margin-left: 10px;
    box-shadow: 0 0 5px #48CAD7;
    &.otherTo{
      background: #F5F6FA;
      color: #48CAD7;
      box-shadow: 0 0 5px #F5F6FA
    }
  }
}
.gotoWrap{
  display: flex;
  flex-direction: column;
  height: 100%;
  .lcbtm{
    border-bottom: 1px solid #F7F7F7;
    position: relative;
    z-index: 2;
    height: 65px;
    &::after{
      height: 50%;
      content: "";
      position: absolute;
      width: 0;
      border: 1px dashed #F1F1F1;
      left: 5px;
      top: 50%;
      transform: translateY(-50%)
    }
  }
  .location{
    font-size: 15px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
    &::after{
      content: "";
      background: #333;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      width: 10px;
      height: 10px;
      transform: translateY(-50%);
    }
    &.desLocation{
      &::after{
        background: #FC9E56;
      }
    }
  }
}

.gotoContainer{
  width:364px;
  height:138px;
  background:rgba(255,255,255,1);
  border-radius:8px;
  margin: 0 auto 5px;
  padding: 16px 21px 5px 10px;
  box-sizing: border-box;
  position: relative;
  .close_wrap{
    position: absolute;
    width:30px;
    height: 30px;
    top: 0;
    right: 0;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    .th_close{
      width: 12px;
      height: 12px;
    }
  }
}
</style>
