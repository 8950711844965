<template>
  <transition name="fade" appear>
    <div class="share-page" id="copyPro">
      <div class="share-txt">
        右上角分享给好友<img src="../../assets/f7/share-ying.png" alt="" class="close-i">
      </div>
      <div class="shareImg">
        <div class="loading" v-if="!posterUrl">
          <img src="../../assets/f7/location-loading.gif" alt=""></div>
        <img v-else :src="posterUrl" class="poster">
      </div>
      <canvas id="canvas" width="654" height="894" class="canvasBox" style="display:none"></canvas>

      <div class="share-box">
        <div class="share-item">
          <img src="../../assets/f7/share_close.png" alt="" class="close-i" @click="hideModal('shareModal')">
        </div>
        <!-- <div class="share-item">
          <span class="copyPro" ref="copy" @click="copyLink()">
            <img src="../../assets/f7/icon_lianjie.png" alt="">
            <p>复制链接</p>
          </span>
        </div> -->
        <!-- <div class="share-item">
          <img src="../../assets/f7/icon_fxtp.png" alt="">
          <p>长按保存图片</p>
        </div> -->
      </div>
    </div>
  </transition>
</template>

<script>
import Clipboard from 'clipboard';
import eventBus from '@/eventBus';
import axios from 'axios';
import scrolls from '@/mixin/scroll';
import { mapState } from 'vuex';
import utils from '@/utils/utils';

export default {
  components: {},
  mixins: [scrolls],
  props: {
    mapLanguage: {
      type: Object,
      default() {
        return {};
      },
    },
    sharePosterUrl: {
      type: String,
      default() {
        return '';
      },
    }
  },
  data() {
    return {
      endLanguage: [],
      isShow: true,
      proPicUrl: '',
      shareLink: '',
      clipboard: '',
      posterUrl: '',
      qrCodePicPath: '',
      introPicPath: '',
      infoData: {},
      isCanClick: false
    };
  },
  watch: {
    isCanClick(val) {
      if (val) {
        setTimeout(() => {
          this.clickCanvas();
        }, 1500);
      }
    }
  },
  methods: {
    hasLoadImg() {
      this.getCanvas(this.infoData);
    },
    getProPicimg() {
      const url = window.location.href;
      const obj = utils.getUrlParamToObj(url);
      const salesmanId = obj.salesmanId || localStorage.getItem('salesmanId');
      const supplierProdId = obj.supplierProdId || localStorage.getItem('supplierProdId');

      const params = {
        salesmanId,
        supplierProdId
      };
      axios({
        method: 'get',
        url: `${window.location.origin}/guide-distribution/api/pt/product/share/info`,
        params,
        headers: {
          'h5-token': this.$store.state.user.elecUser.token || ''
        }
      }).then(res => {
        if (res.data.code === 200) {
          const data = res.data.data;
          if (data) {
            this.introPicPath = data.introPicPath;
            this.qrCodePicPath = data.qrCodePicPath;
            this.getCanvas(data);
          }
        }
      });
    },
    hideModal(modal) {
      eventBus.$emit('hideModal', modal);
      if (this.posterUrl) {
        eventBus.$emit('posterUrlChange', this.posterUrl);
      }
    },
    copyLink() {
      // let copy=this.$refs.copy
      // let clipboard = new Clipboard(copy, { //将'#copy' 改为 copy
      //   text: (trigger)=>{
      //       return this.shareLink;
      //   }
      // });
      this.clipboard.on('success', (e) => {
        this.$toast({ message: '复制成功' });
        this.clipboard.destroy();
      });
      this.clipboard.on('error', (e) => {
        this.$toast({ message: '复制失败' });
        this.clipboard.destroy();
      });
    },

    clickCanvas() {
      this.posterUrl = document.getElementById("canvas").toDataURL();
    },

    getCanvas({ name, salePrice }) {
      const c = document.getElementById("canvas");
      const ctx = c.getContext("2d");
      const self = this;

      ctx.fillStyle = "#ffffff";
      this.roundRect(ctx, 0, 0, 654, 894, 8, "#fff");

      const t = name;
      ctx.font = "bold 32px arial";
      ctx.fillStyle = "#212121";
      ctx.textBaseline = "center";
      // eslint-disable-next-line
      ctx.fillText(t, (327 - ctx.measureText(t).width / 2), 68);

      // eslint-disable-next-line
      var img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = self.introPicPath;
      // eslint-disable-next-line
      img.onload = function () {
        ctx.drawImage(img, 0, 108, 654, 464);
        const img2 = new Image();
        img2.crossOrigin = 'Anonymous';
        img2.src = self.qrCodePicPath;
        // eslint-disable-next-line
        img2.onload = function () {
          ctx.drawImage(img2, 182, 360, 288, 288);
          self.isCanClick = true;
        };
      };

      // const price = '￥' + salePrice;
      // ctx.font = "bold 36px arial";
      // ctx.fillStyle = "#FF7535";
      // ctx.textBaseline = "center";
      // ctx.fillText(price, (327 - ctx.measureText(price).width / 2), 692);


      const t2 = '请使用微信扫码进入';
      ctx.font = "28px arial";
      ctx.fillStyle = "#666666";
      ctx.textBaseline = "center";
      // eslint-disable-next-line
      ctx.fillText(t2, (327 - ctx.measureText(t2).width / 2), 742);

      const t3 = '为避免页面丢失，请将改二维码拍照保存';
      ctx.font = "26px arial";
      ctx.fillStyle = "#FF7535";
      ctx.textBaseline = "center";
      // eslint-disable-next-line
      ctx.fillText(t3, (327 - ctx.measureText(t3).width / 2), 802);

      const t4 = '或在微信中收藏链接';
      ctx.font = "26px arial";
      ctx.fillStyle = "#FF7535";
      ctx.textBaseline = "center";
      // eslint-disable-next-line
      ctx.fillText(t4, (327 - ctx.measureText(t4).width / 2), 846);
    },

    roundRect(ctx, x, y, w, h, r, c = '#fff') {
      // eslint-disable-next-line
      if (w < 2 * r) { r = w / 2; }
      // eslint-disable-next-line
      if (h < 2 * r) { r = h / 2; }

      ctx.beginPath();
      // eslint-disable-next-line
      ctx.fillStyle = c;

      ctx.arc(x + r, y + r, r, Math.PI, Math.PI * 1.5);
      ctx.moveTo(x + r, y);
      // eslint-disable-next-line
      ctx.lineTo(x + w - r, y);
      ctx.lineTo(x + w, y + r);

      // eslint-disable-next-line
      ctx.arc(x + w - r, y + r, r, Math.PI * 1.5, Math.PI * 2);
      // eslint-disable-next-line
      ctx.lineTo(x + w, y + h - r);
      // eslint-disable-next-line
      ctx.lineTo(x + w - r, y + h);

      // eslint-disable-next-line
      ctx.arc(x + w - r, y + h - r, r, 0, Math.PI * 0.5);
      ctx.lineTo(x + r, y + h);
      // eslint-disable-next-line
      ctx.lineTo(x, y + h - r);

      // eslint-disable-next-line
      ctx.arc(x + r, y + h - r, r, Math.PI * 0.5, Math.PI);
      ctx.lineTo(x, y + r);
      ctx.lineTo(x + r, y);
      ctx.fill();
      ctx.closePath();
    }
  },
  mounted() {
    const scenicId = utils.getUrlParamToObj(location.href).scenicId;
    const salesmanId = utils.getUrlParamToObj(location.href).salesmanId;
    const isBuy = utils.getUrlParamToObj(location.href).isBuy;
    const supplierProdId = utils.getUrlParamToObj(location.href).supplierProdId;
    this.shareLink = `${window.location.origin}/guide/?scenicId=${scenicId}&salesmanId=${salesmanId}&supplierProdId=${supplierProdId}&isBuy=${isBuy}`;

    // const copy = this.$refs.copy;
    // this.clipboard = new Clipboard(copy, {
    //   text: (trigger) => this.shareLink
    // });
    if (this.sharePosterUrl) {
      this.posterUrl = this.sharePosterUrl;
    } else {
      this.getProPicimg();
    }
  },
};
</script>
<style lang="scss" scoped>
.share-page{
  height: calc(100% - 40px);
  .shareImg{
    width: 327px;
    height: 447px;
    .poster{
      width: 100%;
      height: 100%;
    }
    .loading {
      text-align: center;
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        margin-top: 46%;
      }
    }
  }
  .share-txt {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
    margin-bottom: 12px;
    img {
      width: 43px;
      height: 52px;
      margin-left: 6px;
    }
  }
}
.share-box{
  display: flex;
  margin-top: 12px;
  .share-item{
    flex: 1;
    color: #fff;
    text-align: center;
  }
  .close-i {
    width: 24px;
    height: 24px;
  }
}
.canvasBox {
  width: 327px;
  height: 447px;
}
</style>
