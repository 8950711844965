<template>
  <transition name="fade" appear>
    <div class="languageDetail tan_wrap">
      <div class="tan_header">
        <p class="th_p">语音选择</p>
        <div class="th_wrap" @click="hideModal('languageDetailModal')">
          <img
            class="th_close"
            src="../../assets/f7/close.png">
        </div>
      </div>
      <div class="tan_main">
        <p class="tm_p">小提示：御姐女神为系统默认语音</p>
        <div class="tm_body" ref="scroll">
          <div class="tm_radio_wrap" v-if="isShow">
            <div class="tm_radio_item"
              v-for="(item,index) in endLanguage"
              :key="index"
              @click="changeLanguage(item)">
              <div class="tm_radio_left">
                <img :src="item.icon" class="tm_radios">
                <span>{{item.name}}</span>
              </div>
              <div class="tm_radio_right">
                <img src="../../assets/f7/radio_non.png" alt="" v-if="!item.selected">
                <img src="../../assets/f7/radio.png" alt="" v-else>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import eventBus from '@/eventBus';
import scrolls from '@/mixin/scroll';
import { mapState } from 'vuex';

export default {
  components: {},
  mixins: [scrolls],
  props: {
    mapLanguage: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      endLanguage: [],
      isShow: true,
    };
  },
  watch: {
  },
  computed: {
    ...mapState({
      selectLanguage: state => state.app.selectLanguage,
    }),
  },
  methods: {
    hideModal(modal) {
      eventBus.$emit('hideModal', modal);
    },
    changeLanguage(lanitems) {
      this.isShow = false;
      // eslint-disable-next-line
      this.endLanguage.map((item,index)=>{
      // eslint-disable-next-line
        item.selected = false;
      });
      // eslint-disable-next-line
      this.endLanguage.map((item) => {
        if (item.duId === lanitems.duId) {
          // eslint-disable-next-line
          item.selected = true;
          this.$store.dispatch('updateLanguage', item);
        }
        this.isShow = true;
      });
    },
    // 格式化语音信息
    formLanguage() {
      const formLan = [];
      // eslint-disable-next-line
      this.mapLanguage.languages.map((item,index) => {
        // eslint-disable-next-line
        item.styles.map((item2, index2) => {
        // eslint-disable-next-line
          item2.parentId = item.id;
          // eslint-disable-next-line
          item2.duId = `${item.id}${item2.id}`;
          formLan.push(item2);
        });
      });
      return formLan;
    },
  },
  mounted() {
    const endLanguage = this.formLanguage();
    this.endLanguage = endLanguage;
    this.scrolljs(false);
  },
};
</script>
<style lang="scss" scoped>
.languageDetail{
  width: 299px;
  height: 320px;
  border-radius:8px;
  background:rgba(255,255,255,0.95);
  box-shadow:0px 10px 20px 0px rgba(0,0,0,0.2),0px 5px 10px 0px rgba(0,0,0,0.2);
  .tm_body{
    height: 200px;
    overflow-y: auto
  }
  .tm_radio_wrap{
    .tm_radio_item{
      display: flex;
      display: -webkit-flex;
      // align-items: center;
      justify-content: space-between;
      height: 50px;
      line-height: 50px;
      .tm_radio_left{
        display: flex;
        display: -webkit-flex;
        align-items: center;
        font-size: 12px;
        .tm_radios{
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
      .tm_radio_right{
        width: 15px;
        height: 15px;

        img{
          width: 100%;
        }
      }
    }
  }
}
</style>
