import L from 'leaflet';

export default {
  data() {
    return {
      userPositionMarker: null,
    };
  },
  mounted() {

  },
  methods: {
    // 定位marker
    locationIcon() {
      let html = '';
      html =
        '<div id="locationMarker" ref="compass" class="location-marker"><div class="location-marker-content"><div class="accuracy-circle"></div></div></div>';
      return L.divIcon({
        className: ' location-pannel ',
        html,
        iconSize: [16, 16],
        iconAnchor: [16 / 2, 16 / 2],
      });
    },
    rotateHandler(e) {
      let angle = 0;
      if (e.webkitCompassHeading) { // ios
        angle = e.webkitCompassHeading;
      } else if (e.alpha) { // 安卓
        // angle = 180 - e.alpha;
        angle = 180 - e.alpha;
      } else {
        // this._errorCompass({ message: 'Orientation angle not found' });
      }

      angle = Math.round(angle);

      // if (angle % 2 === 0) {
      // eslint-disable-next-line
      const userIconEl = this.userPositionMarker._icon.querySelector('#locationMarker');
      this.rotateElement(userIconEl, angle);
      // }
    },
    rotateElement(el, currentAngle) {
      // console.log(el, currentAngle);
      // eslint-disable-next-line
      el.style.webkitTransform = `rotate(${currentAngle}deg)`;
      // eslint-disable-next-line
      el.style.MozTransform = `rotate(${currentAngle}deg)`;
      // eslint-disable-next-line
      el.style.transform = `rotate(${currentAngle}deg)`;
    },
    // 时刻更新游客位置
    updateUserMarker(nv) {
      if (!this.userPositionMarker) { // 当没有创建的时候就创建
        this.userPositionMarker = L.marker(L.latLng(nv.lat, nv.lng), {
          icon: this.locationIcon(),
        }).addTo(this.$refs.map.mapObject);
        this.$nextTick(() => {
          L.DomEvent.on(window, 'deviceorientation', this.rotateHandler, this);
        });
      } else { // 已经创建的就判断图层是否添加到页面，没有则添加，有则更改游客位置
        if (!this.$refs.map.mapObject.hasLayer(this.userPositionMarker)) {
          this.userPositionMarker.addTo(this.$refs.map.mapObject);
        }
        this.userPositionMarker.setLatLng(L.latLng(nv.lat, nv.lng));
      }
    },
  },

};
