<template>
  <transition name="audioList">
    <div class="audioList">
      <div class="input">
        <img class="search" src="../assets/f7/search.png" alt="">
        <input type="search" placeholder="请输入景点" maxlength="20" v-model="searchKey"/>
      </div>
      <div class="adl_wrap">
        <div class="adl_top">
          <ul class="adl_zong">
            <li class="adl_same scenic"><span>景点名</span></li>
            <!-- <li class="adl_same time"><span>时长</span></li> -->
            <!-- <li class="adl_same people"><span>收听人数</span></li> -->
            <li class="adl_same distance"><span>距离</span></li>
            <li class="adl_same operation"><span>操作</span></li>
          </ul>
        </div>
        <div class="adl_list" ref="scroll">
          <mt-loadmore
          :bottom-method="loadBottom"
          :bottom-all-loaded="allLoaded"
          :autoFill="false"
          ref="loadmore">
            <div v-if="keyScenic.length>0">
              <div class="adl_zong list_item" v-for="(item ,index) in addL" :key="index">
                <p class="adl_same scenic">
                  <span class="title">{{item.name|wordFlow(5)}}</span>
                </p>
                <!-- <p class="adl_same time">
                  <span v-if="item.currentVoice">{{item.currentVoice.duration|timeFormat}}</span>
                </p> -->
                <!-- <p class="adl_same people"><span>342</span></p> -->
                <p class="adl_same distance"><span>{{item.distance}}</span></p>
                <div class="adl_same operation">
                  <div class="button"
                  @click="changeStatus(2)"
                  v-if="currentPlayInfo.typed===item.typed&&
                  currentPlayInfo.playId==item.id&&currentPlayInfo.playStatus==1">
                    <img
                    class="img" src="../assets/f7/iconPlaying.gif" alt="">
                  </div>
                  <div class="button"
                  @click="changeStatus(1)"
                  v-else-if="currentPlayInfo.typed===item.typed&&
                  currentPlayInfo.playId==item.id&&currentPlayInfo.playStatus==2">
                    <img
                    class="img" src="../assets/f7/iconPlayPruse.png" alt="">
                  </div>
                  <div class="button" :class="{disabled:cannotPlay && !item.isAudition}" @click="explainThis(item)" v-else>{{cannotPlay && item.isAudition ? '试听': '讲解'}}</div>
                </div>
              </div>
            </div>
            <div v-else>
              <p class="nonhav">未找到符合的景点或目的地</p>
            </div>
          </mt-loadmore>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
// import scrolls from '@/mixin/scroll';
import eventBus from '@/eventBus';
import { mapState } from 'vuex';

export default {
  // mixins: [scrolls],
  components: {},
  props: {
    scenicAndGroup: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      searchKey: '',
      addL: [],
      allLoaded: false,
      loadIndex: 0,
    };
  },
  watch: {
    searchKey() {
      this.addL = [];
      this.allLoaded = false;
      this.loadIndex = 0;
      this.loadBottom();
    },
    scenicAndGroup(newVal, oldVal) {
      // console.log("+++++",newVal,'+++++',oldVal)
      if (oldVal && oldVal.length === 0 && newVal && newVal.length > 0) {
        this.loadBottom(true);
      }
    }

  },
  computed: {
    ...mapState({
      selectLanguage: state => state.app.selectLanguage,
      currentPlayInfo: state => state.app.currentPlayInfo,
    }),
    keyScenic() {
      const keyword = this.searchKey;
      const arr = this.scenicAndGroup.filter(
        item => (RegExp(keyword).test(item.name)),
      );
      return arr;
    },
    cannotPlay() {
      return this.$store.getters.cannotPlay;
    }
  },
  methods: {
    loadBottom(firstLoad) {
      // eslint-disable-next-line
      let size =this.loadIndex+=10;
      size = size > this.keyScenic.length ? this.keyScenic.length : size;
      const dataAppended = this.keyScenic.slice(this.addL.length, size);
      // console.log('loadBottom', this.keyScenic, this.addL.length, size, dataAppended);
      this.addL = this.addL.concat(dataAppended);
      // console.log('loadBottom', this.addL);
      this.allLoaded = dataAppended.length === 0;
      if (!firstLoad) {
        this.$refs.loadmore.onBottomLoaded();
      }
    },
    refreshList() {
      this.$nextTick(() => {
        this.searchKey = '';
        this.addL = [];
        this.allLoaded = false;
        this.loadIndex = 0;
        this.loadBottom();

        this.$refs.scroll.scrollTop = 0;
      });
    },
    changeStatus(status) {
      eventBus.$emit('changePlayInfo', {
        playStatus: status,
      });
    },
    explainThis(item) {
      eventBus.$emit('changeAudioInfo', { item, status: 1 });
      eventBus.$emit('changeSelected', item);
    },
  },
  mounted() {
    // setTimeout(() => {
    //   this.loadBottom();
    // }, 1000);
  },
};
</script>
<style lang="scss" scoped>
.audioList{
  height: 200px;
  width: 364px;
  margin: 0 auto;
  background: #fff;
  border-top-left-radius:8px;
  border-top-right-radius:8px;
  padding: 8px 5px;
  box-sizing: border-box;
  .input{
    position: relative;
    margin-bottom: 5px;
    .search{
      position: absolute;
      left: 19px;
      top: 9px;
      width:15px;
      height:15px;
    }
    input{
      outline: none;
      text-indent: 30px;
      width:335px;
      margin: 0 auto;
      display: block;
      height:32px;
      background:rgba(255,255,255,1);
      border-radius:30px;
      border:1px solid rgba(237,239,242,1);
    }
  }
  .adl_wrap{
    .adl_list{
      height: 115px;
      overflow-y: scroll;
    }
    .adl_top{
      font-size: 12px;
      height: 30px;
      line-height: 30px;
      border-bottom: 1px solid rgb(236, 235, 235);
    }
    .list_item{
      font-size: 10px;
      height: 40px;
      border-bottom: 1px solid rgb(236, 235, 235);
    }
    .adl_zong{
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: space-around;
      .adl_same{
        width: 121px;
        text-align: center;
      }
      .scenic{
        .title{
          display: inline-block;
          vertical-align: middle;
        }
        .img{
           display: inline-block;
          vertical-align: middle;
          width: 10px;
          height: 10px;
        }
      }
      .time{
      }
      .people{
      }
      .distance{
      }
      .operation{
        .button{
          outline: none;
          border: none;
          width: 46%;
          line-height: 0.533333rem;
          margin: 0 auto;
          height: 0.533333rem;
          border-radius: 0.16rem;
          background: #ffd23d;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          .img{
            width: 25%;
            display: block;
          }
        }

        .button.disabled {
          background: #c1c1c1
        }
      }
    }
  }
}
.audioList-enter-active,
.audioList-leave-active{
  transition: all .2s;
}
.audioList-enter{
  transform: scale(2)
}
.audioList-leave-to{
  transform: scale(0);
}
.nonhav{
    text-align: center;
    font-size: 12px;
    color: #999;
    margin: 10px 0;
  }
</style>
