<template>
  <transition name="slideup">
    <div class="feedBackDetail tan_wrap">
      <div class="tan_header">
        <p class="th_p">{{feedTitle}}</p>
        <div class="th_wrap" @click="hideModal('feedBackDetailModal')">
          <img class="th_close"
            src="../../assets/f7/close.png">
        </div>
      </div>
      <div class="tan_main">
        <div class="tm_feed">
          <!-- 问题每一项 -->
          <div class="tm_feed_item">
            <div class="tm_feed_top"><span class="red">当前现状</span></div>
            <div class="tm_feed_select">
              <ul>
                <li @click="liclick($event)">位置不准</li>
                <li @click="liclick($event)">不在景区内</li>
                <li @click="liclick($event)">景点简介有无</li>
                <li @click="liclick($event)">其他问题</li>
              </ul>
            </div>
          </div>
          <div class="tm_feed_item" v-if="arg===1">
            <div class="tm_feed_top"><span>问题景点</span></div>
            <select name="" id="" class="tm_feed_option">
              <option value="">请选择景点(选填)</option>
            </select>
          </div>
          <div class="tm_feed_item" v-if="arg!==4">
            <div class="tm_feed_top"><span>补充描述</span></div>
            <textarea class="tm_textarea" placeholder="以上选项说不清，来这里吐槽..."></textarea>
          </div>
          <div class="tm_feed_item" v-if="arg===4">
            <div class="tm_feed_top"><span>建议或者意见</span></div>
            <textarea class="tm_textarea" placeholder="请描述您的问题或提出宝贵建议，驴小迹一定会认真改进的呢~"></textarea>
          </div>
          <div class="tm_feed_item">
            <div class="tm_feed_top"><span>手机号</span></div>
            <input class="tm_input" type="number" placeholder="请输入您的手机号"/>
          </div>
          <div class="tan_submit active" @click="submit">提交</div>
        </div>
      </div>

    </div>
  </transition>
</template>

<script>
import eventBus from '@/eventBus';

const $ = window.$;
export default {
  components: {},
  props: {
    arg: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
    };
  },
  watch: {},
  computed: {
    feedTitle() {
      switch (this.arg) {
        case 1:
          return '地图问题';
        case 2:
          return '语音问题';
        case 3:
          return '功能问题';
        default:
          return '其他';
      }
    },
  },
  methods: {
    submit() {
      this.$toast({
        message: '提交成功',
        position: 'bottom',
      });
    },
    hideModal(modal) {
      eventBus.$emit('hideModal', modal);
    },
    liclick(e) {
      $(e.target).addClass('active').siblings().removeClass('active');
    },
  },
  created() {},
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.slideup-enter-active,.slideup-leave-active{
  transition: all 0.2s
}
.slideup-enter,.slideup-leave-to{
  transform: translateY(100%);
  opacity: 0;
}
.feedBackDetail{
  height: 100%;
  background: #fff;
  overflow-y: scroll;
  width: 100%;
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: 4;
  .tm_feed{
    padding: 16px 24px;
    .tm_feed_item{
      margin-bottom: 20px;
      .tm_feed_top{
        font-size:16px;
        font-family:PingFangSC-Medium;
        font-weight:500;
        color:rgba(64,64,64,1);
        margin-bottom: 12px;
        span{
          position: relative;
          &.red::after{
            content:"*";
            position: absolute;
            right: -10px;
            top: 0;
            color: red;
          }
        }
      }
      .tm_feed_select{
        ul{
          display: flex;
          display: -webkit-flex;
          align-items: center;
          flex-wrap: wrap;
        }
        li{
          padding: 0 11px;
          line-height: 32px;
          text-align: center;
          margin-bottom: 19px;
          height:32px;
          background:rgba(255,255,255,1);
          border-radius:100px;
          border:1px solid rgba(114,64,11,1);
          margin-right: 20px;
          font-size:14px;
          font-family:PingFangSC-Regular;
          font-weight:400;
          color:rgba(114,64,11,1);
          &.active{
            background:rgba(250,210,83,1);
            border-radius:100px;
            border:1px solid rgba(114,64,11,1);
          }
        }
      }
      .tm_feed_option{
        height:40px;
        background:rgba(250,250,250,1);
        width: 100%;
        outline: none;
        border: none;
        text-indent: 12px;
      }
      .tm_textarea{
        height:66px;
        background:rgba(250,250,250,1);
        width: 100%;
        border: none;
        resize: none;
        padding: 10px;
        box-sizing: border-box;
      }
      .tm_input{
        height:40px;
        width: 100%;
        border: none;
        padding: 10px;
        box-sizing: border-box;
        background:rgba(250,250,250,1);
      }
    }
    .tan_submit{
      width:240px;
      height:40px;
      background:rgba(244,244,244,1);
      line-height: 40px;
      font-size:15px;
      font-family:PingFangSC-Medium;
      text-align: center;
      font-weight:500;
      color:rgba(32,32,32,1);
      border-radius:8px;
      margin: 75px auto 0;
      &.active{
        background:rgba(255,221,2,1);
      }
    }
  }

}
</style>
