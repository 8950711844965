<template>
  <transition name="fade" appear>
    <div class="shopDetail windowClass">
      <div class="shop_title">
        <div class="shop_img">
          <img :src="scenic.picPath" alt="">
        </div>
        <div class="shop_content">
          <div class="title">
            <p class="nei">{{scenic.name}}</p>
            <span class="tag" v-text="scenic.scenicGradeDesc">名胜古迹</span>
            <span class="tag" v-text="scenic.scenicTypeDesc">网红打卡点</span>
          </div>
          <div class="shop_desc">包含{{scenicCount}}个语音讲解点，不包含AI智能对话</div>
        </div>
      </div>
      <div class="shop_detail">
        <div
          class="shop_item"
          :class="selectedIndex===index?'active':''"
          @click="selectVipItem(index)"
          v-for="(vipItem,index) in vipItemList"
          :key="index"
        >
          <div class="item_top">
            <p class="it_left">
              <span>{{vipItem.name}}</span>
              <img class="hot" src="../../assets/f7/hot.png" alt="" v-if="index === 0">
            </p>
            <span class="it_right">￥{{vipItem.salePrice}}</span>
          </div>
          <div class="item_bottom">
            <p class="ib_left">{{'解锁' + scenic.name +'语音讲解，每天仅需'+(vipItem.salePrice/vipItem.availableDays).toFixed(2)+'元'}}</p>
          </div>
          <p class="ib_right">
            有效期{{vipItem.availableDays}}日，虚拟产品不支持退款
          </p>
        </div>
      </div>
      <div class="shop_operation">
        <div class="shop_btn cancel" @click="hideModal('shopDetailModal')">取消</div>
        <div class="shop_btn shopto" @click="justBuy">立即购买</div>
      </div>
    </div>
  </transition>
</template>

<script>
import eventBus from "@/eventBus";
import utils from '@/utils/utils';

export default {
  components: {},
  props: {
    scenic: {
      type: Object,
      default() {
        return {};
      }
    },
    scenicCount: 0
  },
  data() {
    return {
      selectedIndex: 0,
      vipItemList: {}
    };
  },
  watch: {},
  computed: {
    platformIos() {
      return utils.os.isIOS;
    }
  },
  methods: {
    hideModal(modal) {
      eventBus.$emit("hideModal", modal);
    },

    selectVipItem(index) {
      this.selectedIndex = index;
    },

    justBuy() {
      const item = this.vipItemList[this.selectedIndex];
      if (!item) {
        return;
      }
      const that = this;
      const vipId = item.id;
      const title = item.name;
      if (wx) {
        // if (!this.$store.state.user.userId) {
        //   wx.miniProgram.switchTab({
        //     url: "/pages/user/user",
        //   });
        //   return;
        // }
        const obj = utils.getUrlParamToObj(window.location.href);
        const { scenicId, salesmanId, supplierProdId } = obj;
        window.location.replace(`${window.location.origin}/ele-distribution/#/payment?scenicId=${scenicId}&salesmanId=${salesmanId}&supplierProdId=${supplierProdId}&id=${vipId}&isDayCard=0`);
      }
    }
  },
  created() {
    this.vipItemList = this.$store.state.user.vipItemList.productSpecVOS;
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.windowClass {
  overflow-y: scroll;
  position: relative;
}
.shopDetail {
  width: 343px;
  /*height: 516px;*/
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  padding: 33px 12px;
  box-sizing: border-box;
  .shop_title {
    margin-bottom: 12px;
    align-items: center;
    display: flex;
    .shop_img {
      width: 54px;
      height: 54px;
      margin-right: 12px;
      border-radius: 50%;
      img {
        width: 100%;
      }
    }
  }
  .shop_content {
    flex: 1;
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      .nei {
        font-size: 20px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        color: rgba(22, 24, 22, 1);
        margin-right: 4px;
      }
      .tag {
        margin-right: 4px;
        padding: 0 4px;
        font-size: 10px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        color: rgba(128, 128, 128, 1);
        background: rgba(242, 242, 242, 1);
        border-radius: 4px;
        border: 1px solid rgba(232, 232, 232, 1);
        line-height: 17px;
      }
    }
    .shop_desc {
      font-size: 16px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: rgba(128, 128, 128, 1);
    }
  }
  .shop_detail {
    height: 300px;
    overflow-y: scroll;
    .shop_item {
      width: 319px;
      min-height: 88px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.08);
      padding: 12px;
      box-sizing: border-box;
      margin-bottom: 12px;
      &.active {
        background: rgba(255, 191, 108, 0.1);
        border: 1px solid rgba(255, 145, 2, 1);
      }
      .item_top {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        .it_left {
          font-size: 16px;
          display: flex;
          display: -webkit-flex;
          align-items: center;
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          color: rgba(22, 24, 22, 1);
          .hot {
            width: 15px;
            height: 18px;
          }
        }
        .it_right {
          font-size: 18px;
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          color: rgba(255, 145, 2, 1);
        }
      }
      .item_bottom {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        color: rgba(128, 128, 128, 1);
        .ib_left {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .ib_right {
        color: #ff9102;
        margin-top: 2px;
      }
    }
  }
  .shop_operation {
    margin-top: 20px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    /*position: absolute;*/
    bottom: 33px;
    text-align: center;
    width: calc(100% - 12px);
    .shop_btn {
      width: 136px;
      line-height: 49px;
      text-align: center;
      height: 49px;
      border-radius: 8px;
      font-size: 18px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
    }
    .cancel {
      background: rgba(226, 226, 226, 1);
      margin-right: 16px;
    }
    .shopto {
      background: rgba(255, 210, 61, 1);
    }
  }
}
::-webkit-scrollbar {
  display: none;
}
</style>
