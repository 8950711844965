<template>
  <div class="contentWrap">
    <div class="audioContainer">
      <div class="audio_wrap">
        <div class="aw_left">
          <div class="awl_bg"
               v-if="currentPlayInfo.playStatus==0"
               @click="changePlayInfo({playStatus:1})">
            <img src="../assets/f7/boding.png" class="awl_img" alt="">
          </div>
          <div class="awl_bg awl_bg2"
               v-else-if="currentPlayInfo.playStatus==2"
               @click="changePlayInfo({playStatus:1})">
            <img src="../assets/f7/iconPlayPruse.png" class="awl_img" alt="">
          </div>
          <div class="awl_bg awl_bg2" v-else @click="changePlayInfo({playStatus:2})">
            <img src="../assets/f7/iconPlaying.gif" class="awl_img" alt="">
          </div>
        </div>
        <div class="aw_right">
          <div class="awr_title">
            <span class="title">{{currentPlayInfo.title|wordFlow(15)}}</span>
            <span class="distance" v-if="currentPlayInfo.distance">{{currentPlayInfo.distance}}</span>
            <span class="audition" v-if="cannotPlay && currentPlayInfo.currentItem && currentPlayInfo.currentItem.isAudition">免费试听</span>
          </div>
          <div class="awr_pg_wrap" v-if="currentPlayInfo.currentVoice">
            <div class="main-bar">
              <img @click="change15(-1)"
                   class="rotate15 rotate15_1" src="../assets/f7/rotate15.png" alt="">
              <em class="em_1">{{currentPlayInfo.currentTime|timeFormat}}</em>
              <div class="main-progress" @touchstart="touchStart($event)"
                   @touchmove="touchMove($event)">
                <em class="yellow-line" :style="{ width: currentProgress + '%' }"></em>
              </div>
              <em class="em_2">{{currentPlayInfo.currentVoice.duration|timeFormat}}</em>
              <img @click="change15(1)"
                   class="rotate15 rotate15_2" src="../assets/f7/rotate15.png" alt="">
            </div>
          </div>
          <div class="awr_pg_wrap" v-else>
            <div class="main-bar">
              <img class="rotate15 rotate15_1" src="../assets/f7/rotate15.png" alt="">
              <em class="em_1">00:00</em>
              <div class="main-progress">
                <em class="yellow-line" :style="{ width:  '0%' }"></em>
              </div>
              <em class="em_2">00:00</em>
              <img class="rotate15 rotate15_2" src="../assets/f7/rotate15.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="audio_menu">
        <div class="goto" @click="gotoHear">
          <img src="../assets/f7/goto.png" alt="">
          <span>去这里</span>
        </div>
        <div class="menu_btn">
          <div class="toggleWrapper">
            <input
              type="checkbox"
              name="autoPlay1"
              class="mobileToggle"
              id="autoPlay1"
              v-model="autoExplain">
            <label for="autoPlay1"></label>
          </div>
          <div class="pp">自动导览</div>
        </div>
        <div class="menu_btn">
          <div class="toggleWrapper">
            <input
              type="checkbox"
              name="autoPlay2"
              class="mobileToggle"
              id="autoPlay2"
              v-model="autoListLoop">
            <label for="autoPlay2"></label>
          </div>
          <div class="pp">列表播放</div>
        </div>
        <div class="menu_end" @click="audioListOpen">
          <img src="../assets/f7/menu.png" class="menu_fen" alt="">
        </div>
      </div>
    </div>
    <div class="updownArrow" @touchstart="onTouchStart($event)" @touchmove="onTouchMove($event)" @touchend="onTouchEnd($event)">
      <img :src="upDownWardImg">
    </div>
  </div>
</template>

<script>
import upward from "@/assets/upward@3x.png";
import downward from "@/assets/downward@3x.png";

import eventBus from '@/eventBus';
// import utils from '@/utils/utils';
import { mapState } from 'vuex';

export default {
  components: {},
  props: {},
  data() {
    return {
      touch: {
        stratX: 0,
        endX: 0,
        parentWidth: 0,
      },
      // percent: 0, // 调试使用
      autoPlay2: false,
      upDownWardImg: upward,
    };
  },
  computed: {
    ...mapState({
      currentPlayInfo: state => state.app.currentPlayInfo,
      isInScenic: state => state.app.isInScenic,
    }),
    // 是否自动讲解处理
    autoExplain: {
      get() {
        // eslint-disable-next-line
        return this.$store.state.app.autoExplain == 1;
      },
      set(val) {
        // eslint-disable-next-line
        if (val == 1) {
          this.$store.dispatch('updateListLoop', 2);
          this.autoPlay2 = false;
        }
        // eslint-disable-next-line
        this.$store.dispatch('updateAutoExplain', val == 1?1:2);
      },
    },
    autoListLoop: {
      get() {
        // eslint-disable-next-line
        return this.$store.state.app.listLoop == 1;
      },
      set(val) {
        // eslint-disable-next-line
        if (val == 1) {
          this.$store.dispatch('updateAutoExplain', 2);
          this.autoPlay1 = false;
        }
        // eslint-disable-next-line
        this.$store.dispatch('updateListLoop', val == 1?1:2);
      },
    },
    currentProgress() { // 进度条
      const ends = this.currentPlayInfo.currentTime / this.currentPlayInfo.currentVoice.duration;
      let temp = Math.floor((ends) * 10000) / 100;
      if (isNaN(temp)) {
        temp = 0;
      }
      return temp;
    },
    cannotPlay() {
      return this.$store.getters.cannotPlay;
    }
  },
  methods: {
    change15(val) {
      if (val === 1) {
        eventBus.$emit('setCurrentTime', 15, 1);
      } else {
        eventBus.$emit('setCurrentTime', -15, 1);
      }
    },
    gotoHear() {
      // eslint-disable-next-line
      // utils.gaodeRoutePlanning({ lat: 23.164413, lng: 113.404931 }, [23.164109, 113.404709], { ...this.currentPlayInfo }, true).then((res) => {
      //   console.log(res);
      //   this.$store.dispatch('updateIsGoto', true);
      //   this.$store.dispatch('updateRouteObject', res);
      // }).catch(() => {
      //   this.$store.dispatch('updateIsGoto', false);
      //   this.$store.dispatch('updateRouteObject', null);
      // });
      // eventBus.$emit('hideAllModal');
      if (this.currentPlayInfo.distance && this.currentPlayInfo.latLng && this.isInScenic) {
        eventBus.$emit('hideAllModal');
        eventBus.$emit('gotoHere', { ...this.currentPlayInfo });
      } else if (this.currentPlayInfo.distance && this.currentPlayInfo.latLng && !this.isInScenic) {
        this.$toast('您不在景区范围内，无法导航');
      } else {
        this.$toast('位置服务获取失败，无法导航');
      }
    },
    changePlayInfo(info) {
      eventBus.$emit('changePlayInfo', info);
    },
    audioListOpen() {
      eventBus.$emit('audioListOpen');
    },
    // 播放条逻辑
    progress(event) {
      let precent = 0;
      if (event.target.className === 'main-progress') {
        this.touch.stratX = event.touches[0].clientX - event.target.offsetLeft;
        this.touch.parentWidth = event.target.offsetWidth;
      } else if (event.target.className === 'yellow-line') {
        this.touch.stratX = event.touches[0].clientX - event.target.parentNode.offsetLeft;
        this.touch.parentWidth = event.target.parentNode.offsetWidth;
      }
      precent = this.touch.stratX / this.touch.parentWidth;
      return precent;
    },
    touchStart(event) {
      let precent = this.progress(event);
      if (precent > 1) {
        precent = 1;
      } else if (precent <= 0) {
        precent = 0;
      }
      // // 调试用
      // this.percent = precent * 100;
      // 设置播放器的播放时间
      eventBus.$emit('setCurrentTime', 0, precent);
    },
    touchMove(event) {
      let precent = this.progress(event);
      if (precent > 1) {
        precent = 1;
      } else if (precent <= 0) {
        precent = 0;
      }
      // // 调试用
      // this.percent = precent * 100;
      // // 设置播放器的播放时间
      eventBus.$emit('setCurrentTime', 0, precent);
    },
    onTouchStart(event) {
      event.preventDefault();
      this.startValue = event.targetTouches[0].clientY;
      // eventBus.$emit('postTouchStart', this.startValue);
    },
    onTouchMove(event) {
      this.moveValue = event.targetTouches[0].clientY;
      console.log(this.startValue, this.moveValue);
      eventBus.$emit('postTouchMove', -this.moveValue + this.startValue);
    },
    onTouchEnd(event) {
      event.preventDefault();
      eventBus.$emit('postTouchEnd', 0);
    }
  },
  created() {},
  mounted() {
    eventBus.$on("audioShowStatus", status => {
      this.upDownWardImg = status === 3 ? downward : upward;
    });
  },
};
</script>
<style lang="scss" scoped>
.audioContainer{
  width:364px;
  height:138px;
  background:rgba(255,255,255,1);
  border-radius:8px;
  margin: 0 auto 5px;
  // position: fixed;
  // bottom: 2px;
  // left: 50%;
  // z-index: 2;
  // transform: translateX(-50%);
  padding: 16px 21px 16px 10px;
  box-sizing: border-box;
  .audio_wrap{
    // position: relative;
    margin-bottom: 10px;
    display: flex;
    .aw_left{
      .awl_bg{
        width:63px;
        height:63px;
        border-radius: 50%;
        background:rgba(255,213,90,1);
        display: flex;
        display: -webkit-flex;
        align-items: center;
        margin-right: 10px;
        position: relative;
        img{
          width: 22px;
          position: absolute;
          left: 50%;
          transform: translateX(-40%);
          height: 23px;
          top: 50%;
          margin-top: -11px;
        }
      }
      .awl_bg2{
        img{
          transform: translateX(-50%) !important;
        }
      }
    }
    .aw_right{
      flex: 1;
      .awr_title{
        margin-bottom: 5px;
        .title{
          font-size:16px;
          font-family:PingFangSC-Medium;
          font-weight:500;
          color:rgba(51,51,51,1);
          margin-right: 6px;
          display: inline-block;
          vertical-align: middle;
        }
        .distance{
          padding: 0 4px;
          box-sizing: border-box;
          background:rgba(230,226,226,0.78);
          border-radius:6px;
          height:16px;
          line-height: 16px;
          text-align: center;
          display: inline-block;
          vertical-align: middle;
          font-size:10px;
          font-family:PingFangSC-Light;
          font-weight:300;
          color:rgba(32,32,32,1);
        }
        .audition {
          padding: 0 4px;
          box-sizing: border-box;
          background:#fe9642;
          border-radius:8px;
          height:16px;
          line-height: 16px;
          text-align: center;
          display: inline-block;
          vertical-align: middle;
          font-size:10px;
          font-family:PingFangSC-Light;
          font-weight:300;
          color:#ffffff;

        }
      }
      .awr_pg_wrap{
        .rotate15{
          width:16px;
          height:17px;
        }
        .rotate15_2{
          margin-left: 9px;
        }
        .rotate15_1{
          margin-right: 9px;
        }
        .main-bar {
          display: flex;
          display: -webkit-flex;
          justify-content: space-around;
          align-items: center;
          em {
            width:31px;
            font-size:11px;
            font-family:PingFangSC-Regular;
            font-weight:400;
            color:rgba(153,157,163,1);
            display: block;
            text-align: center;
          }
          .em_1{
            margin-right: 6px;
          }
          .em_2{
            margin-left: 6px;
          }
          .main-progress {
            flex: 0.95;
            height:3px;
            background:rgba(240,242,245,1);
            border-radius:2px;
            position: relative;
            .yellow-line {
              width: 100%;
              // margin-top: -0.01rem;
              position: absolute;
              height: 100%;
              background-color: #3f85e7;
              border-radius: 8px;
              transition: none !important;
              &::after {
                content: "";
                position: absolute;
                width:5px;
                height:10px;
                background:rgba(255,203,50,1);
                box-shadow:0px 1px 3px 0px rgba(255,203,50,0.43);
                border-radius:3px;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                z-index: 4;
                transition: none !important;
              }
            }
          }
        }
      }
    }
  }
  .audio_menu{
    display: flex;
    align-items: center;
    .goto{
      width:89px;
      height:26px;
      margin-left: 17px;
      background:rgba(242,244,247,1);
      border-radius:18px;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      font-size:13px;
      font-family:PingFangSC-Medium;
      font-weight:500;
      color:rgba(50,182,195,1);
      margin-right: 13px;
      img{
        width:9px;
        height:11px;
        margin-right: 3px;
      }
    }
    .menu_btn{
      line-height: 1;
      display: flex;
      display: -webkit-flex;
      margin-right: 9px;
      font-size: 12px;
      .toggleWrapper{
        margin-right: 8px;
      }
      .pp{
        white-space: nowrap;
        font-size: 10px;
      }
    }
    .menu_end{
      width: 31px;
      height: 28px;
      margin-left: 5px;
      img{
        width: 100%;
      }
    }
  }
}
.contentWrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.updownArrow {
  text-align: center;
  position: absolute;
  top: -20px;
  left: 10px;
  right: 10px;
  img {
    width: 70px;
    height: 18px;
    margin-top: 20px;
    padding: 5px 20px;
  }
}
.updownArrow1 {
  position: absolute;
  text-align: center;
  margin: 0 auto;
  top: -10px;
  padding: 10px;
  img {
    width: 30px;
    height: 8px;
  }
}
</style>
