import store from '@/store';
import { Toast } from 'mint-ui';
import { toQueryStr, gaodeMapKey, FlatArray } from './index';
import { gaode, minDistance } from './routeInfo';
/* eslint-disable func-names */
/* eslint-disable one-var */
export default {
  // eslint-disable-next-line wrap-iife
  os: (function () {
    // eslint-disable-next-line
    const ua = navigator.userAgent,
      isWindowsPhone = /(?:Windows Phone)/.test(ua),
      isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
      isAndroid = /(?:Android)/.test(ua),
      isFireFox = /(?:Firefox)/.test(ua),
      isChrome = /(?:Chrome|CriOS)/.test(ua),
      isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
      isPhone = /(?:iPhone)/.test(ua) && !isTablet,
      isPc = !isPhone && !isAndroid && !isSymbian,
      isWechat = /(MicroMessenger)/i.test(ua),
      isUC = /(UCBrowser)/i.test(ua),
      isIOS = /(iPhone|iPad|iPod|iOS)/i.test(ua),
      isMobile = !isPc,
      // eslint-disable-next-line
      isMiniWechat = window.__wxjs_environment === 'miniprogram' || /(miniProgram)/i.test(ua);

    return {
      isTablet,
      isPhone,
      isAndroid,
      isPc,
      isMobile,
      isUC,
      isIOS,
      isWechat,
      isChrome,
      isMiniWechat,
    };
  })(),
  /** *********************************** GPS信号处理 转换 ************************************ */
  /*
   * gps转换相关的方法。。 主要对GPS进行纠偏计算,将基于国际标准(WGS-84)的坐标转换成中国的国家标准(GCJ-02)的坐标系的坐标
   * 使用案例: GPS.gcj_encrypt(coords.latitude, coords.lnggitude)   //WGS-84 to GCJ-02
   * @returns {{gcj_encrypt, outOfChina, transformLat, transformlng}}
   * @constructor
   */
  GPS:
  {
    PI: 3.14159265358979324,
    x_pi:
        // eslint-disable-next-line no-mixed-operators
        3.14159265358979324 * 3000.0 / 180.0,
    delta(lat, lng) {
      const a = 6378245.0; //  a: 卫星椭球坐标投影到平面地图坐标系的投影因子。
      const ee = 0.00669342162296594323; //  ee: 椭球的偏心率。
      // eslint-disable-next-line no-var
      var dLat = this.transformLat(lng - 105.0, lat - 35.0);
      // eslint-disable-next-line no-var
      var dlng = this.transformlng(lng - 105.0, lat - 35.0);
      // eslint-disable-next-line
      const radLat = lat / 180.0 * this.PI;
      // eslint-disable-next-line no-var
      var magic = Math.sin(radLat),
        // eslint-disable-next-line
        magic = 1 - ee * magic * magic;
        // eslint-disable-next-line no-var
      var sqrtMagic = Math.sqrt(magic),
        // eslint-disable-next-line
        dLat = (dLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * this.PI),
        // eslint-disable-next-line
        dlng = (dlng * 180.0) / (a / sqrtMagic * Math.cos(radLat) * this.PI);
      const pt = {
        lat: dLat,
        lng: dlng,
      };
      return pt;
    },

    // WGS-84 to GCJ-02
    gcj_encrypt(wgsLat, wgslng) {
      if (this.outOfChina(wgsLat, wgslng)) {
        return {
          lat: wgsLat,
          lng: wgslng,
        };
      }

      const d = this.delta(wgsLat, wgslng);
      const pt = {
        lat: wgsLat + d.lat,
        lng: wgslng + d.lng,
      };

      return pt;
    },

    // GCJ-02 to WGS-84
    gcj_decrypt(gcjLat, gcjlng) {
      if (this.outOfChina(gcjLat, gcjlng)) {
        return { lat: gcjLat, lng: gcjlng };
      }

      const d = this.delta(gcjLat, gcjlng);
      return { lat: gcjLat - d.lat, lng: gcjlng - d.lng };
    },
    outOfChina(lat, lng) {
      if (lng < 72.004 || lng > 137.8347) {
        return true;
      }
      if (lat < 0.8293 || lat > 55.8271) {
        return true;
      }
      return false;
    },

    transformLat(x, y) {
      // eslint-disable-next-line
      let ret = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x));
      // eslint-disable-next-line
      ret += (20.0 * Math.sin(6.0 * x * this.PI) + 20.0 * Math.sin(2.0 * x * this.PI)) * 2.0 / 3.0;
      // eslint-disable-next-line
      ret += (20.0 * Math.sin(y * this.PI) + 40.0 * Math.sin(y / 3.0 * this.PI)) * 2.0 / 3.0;
      // eslint-disable-next-line
      ret += (160.0 * Math.sin(y / 12.0 * this.PI) + 320 * Math.sin(y * this.PI / 30.0)) * 2.0 / 3.0;
      return ret;
    },

    transformlng(x, y) {
      // eslint-disable-next-line
      let ret = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x));
      // eslint-disable-next-line
      ret += (20.0 * Math.sin(6.0 * x * this.PI) + 20.0 * Math.sin(2.0 * x * this.PI)) * 2.0 / 3.0;
      // eslint-disable-next-line
      ret += (20.0 * Math.sin(x * this.PI) + 40.0 * Math.sin(x / 3.0 * this.PI)) * 2.0 / 3.0;
      // eslint-disable-next-line
      ret += (150.0 * Math.sin(x / 12.0 * this.PI) + 300.0 * Math.sin(x / 30.0 * this.PI)) * 2.0 / 3.0;
      return ret;
    },
  },
  getUrlParamToObj(pageUrl) {
    // eslint-disable-next-line
    let argObj = {},
      argStr;
      // eslint-disable-next-line
    argStr = pageUrl.split('?');
    if (!argStr[1]) return {};
    // eslint-disable-next-line
    argStr[1].replace(/([^\/?&=]*)=([^\/?&=]*)/g, (match, $1, $2) => {
      if ($1) {
        argObj[$1] = $2.replace('#', '');
      }
      // eslint-disable-next-line
      if ($1 == 'token') {
        sessionStorage.setItem('token', $2.replace('#', ''));
      }
    });
    return argObj;
  },
  gaodeRoutePlanning(origin, destination, destItem, isPlayIn) {
    store.dispatch('updateLoadingStatus', true);
    return new Promise((resolve, reject) => {
      const params = {
        key: gaodeMapKey,
        origin: `${origin.lng},${origin.lat}`,
        destination: `${destination[1]},${destination[0]}`,
      };
      function toast(config) {
        if (typeof config === 'string') {
          Toast({
            message: config,
            position: 'bottom',
          });
        } else {
          Toast(config);
        }
      }
      fetch(`//restapi.amap.com/v3/direction/walking?${toQueryStr(params)}`)
        .then((res) => {
          const result = {
            desItem: destItem,
            originData: {},
            path: [],
            type: 'gaode',
            isPlayIn,
            distance: 0,
            guideSteps: null,
          };
          res.json().then((ret) => {
            store.dispatch('updateLoadingStatus', false);
            result.originData = ret;
            if (ret.status === '0') {
            // 根据高德地图路线规划接口文档做一些异常处理https:// lbs.amap.com/api/webservice/guide/api/direction#walk
              switch (ret.infocode) {
                case '20803':
                  toast('规划路线失败,当前位置距离目标景点太远');
                  break;
                case '20800':
                  toast('规划点不在中国陆地范围内');
                  break;
                case '20801':
                  toast('划点（起点、终点、途经点）附近搜不到路');
                  break;
                case '20802':
                  toast('由于道路连通关系导致规划失败');
                  break;
                default:
                  toast('服务器错误');
                  break;
              }
              reject(new Error('fail'));
            } else if (ret.status === '1') {
              const tempPath = ret.route.paths[0];
              if (+tempPath.distance > minDistance) {
                const path = FlatArray(tempPath.steps.map(step => step.polyline))
                  .join(';').split(';')
                  .map((item) => {
                    const pt = JSON.parse(`[${item}]`);
                    return [pt[1], pt[0]];
                  });
                result.path = path;
                result.duration = tempPath.duration;
                result.distance = tempPath.distance;
                result.guideSteps = gaode(tempPath);
                resolve(result);
              } else {
                toast('您已经在目的地附近了噢~');
                reject(new Error('fail'));
              }
            }
          });
        });
    });
  },
};
