import { render, staticRenderFns } from "./aiShopDetail.vue?vue&type=template&id=01df8404&scoped=true"
import script from "./aiShopDetail.vue?vue&type=script&lang=js"
export * from "./aiShopDetail.vue?vue&type=script&lang=js"
import style0 from "./aiShopDetail.vue?vue&type=style&index=0&id=01df8404&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01df8404",
  null
  
)

export default component.exports