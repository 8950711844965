<template>
  <transition name="fade">
    <div class="scenicDetail">
      <div class="scenictop">
        <p>{{endTitle}}</p>
        <div class="th_wrap" @click="hideModal('scenicDetailModal')">
          <img
          class="th_close"
          src="../../assets/f7/close.png" />
        </div>
        <div class="th_goto"
         @click="dianGoto"
          v-if="!isIndoor&&scenicDetailOn.lat&&scenicDetailOn.lon">
          <img class="th_go" src="../../assets/f7/iconGoThere.png" alt="">
        </div>
      </div>
      <div class="scenicbtm" ref="scroll">
        <div class="scenicContent" v-html="endHtml">
        </div>
      </div>
    </div>
   </transition>
</template>

<script>
import eventBus from '@/eventBus';
import { getScenicDetail } from '@/api';
import scrolls from '@/mixin/scroll';
import { mapState } from 'vuex';


export default {
  mixins: [scrolls],
  data() {
    return {
      endHtml: '',
      endTitle: '',
    };
  },
  computed: {
    ...mapState({
      isIndoor: state => state.app.isIndoor,
    }),
  },
  props: {
    scenicDetailOn: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    dianGoto() {
      eventBus.$emit('serviceGoto', this.scenicDetailOn);
    },
    hideModal(modal) {
      eventBus.$emit('hideModal', modal);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.scrolljs(false);
      const marker = this.scenicDetailOn;
      if (!this.isIndoor) {
        if (marker.typed === 2) { // 景点，直接读接口
          getScenicDetail(marker.id).then((res) => { // 景点类
            this.endHtml = res.data.detail;
            this.endTitle = res.data.name && res.data.name.length > 15 ? `${res.data.name.slice(0, 15)}...` : res.data.name;
          });
        } else if (marker.typed === 3) {
          this.endHtml = marker.detail;
          this.endTitle = marker.name && marker.name.length > 15 ? `${marker.name.slice(0, 15)}...` : marker.name;
        } else if (marker.typed === 4) {
          this.endHtml = marker.introVoice;
          this.endTitle = marker.name && marker.name.length > 15 ? `${marker.name.slice(0, 15)}...` : marker.name;
        }
      } else {
        this.endHtml = marker.detail;
        this.endTitle = marker.name && marker.name.length > 15 ? `${marker.name.slice(0, 15)}...` : marker.name;
      }
    });
  },
};
</script>
<style lang="scss">
.scenicContent{
  text-indent: 2em;
  font-size: 14px;
  word-wrap: break-word;
  p{
    margin: .3rem auto;
    line-height: 28px;
  }
}
</style>

<style lang="scss" scoped>
.th_wrap{
  width: 40px;
  height: 40px;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  .th_close{
    width:10px;
    height:10px;
  }
}
.th_goto{
  width: 40px;
  height: 40px;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  .th_go{
    width:26px;
    height:26px;
  }
}
.scenicDetail{
  width: 90%;
  border-radius:5px;
  // height: 500px;
  height: 80%;
  background: #fff;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 5px;
  .scenictop{
    position: relative;
    line-height: 40px;
    height:40px;
    border-bottom: solid 1px #e8e8e8;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    color: #5b5b5b;
    background: #f9f9f9;
    margin-bottom: 5px;
    text-align: center;
    p{
      font-size: 15px;
      font-weight: 700;
    }
    .th_close{
      position: absolute;
      width: 12px;
      height: 12px;
      right: 10px;
      top:50%;
      transform: translateY(-50%);
    }
  }
  .scenicbtm{
    flex: 1;
    overflow: hidden;
    padding: 0 10px 0 10px;
    // text-indent: 2em;
    overflow-y: scroll;
  }
}
</style>
