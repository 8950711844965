import eventBus from '@/eventBus';
import utils from "@/utils/utils";
import { uploadListen } from '@/api';

const $ = window.$;

export default {
  mounted() {
    this.$nextTick(() => {
      // const $audio = $('#audios');
      // const audios = $audio.get(0);
      // console.log(audios);
      const $audio = $(this.$refs.audios);
      const audios = this.$refs.audios;
      const url = window.location.href;
      const obj = utils.getUrlParamToObj(url);

      let lastPlayId = -1;
      /** ************************************** 播放器开始 ********************************** */
      $audio.on('play', () => {
        this.$store.dispatch('updatePlayInfo', {
          currentTime: audios.currentTime,
          playStatus: 1,
        });

        if (this.currentPlayInfo.playId != lastPlayId) {
          lastPlayId = this.currentPlayInfo.playId;
          uploadListen({
            supplierProductId: obj.supplierProdId,
            salesmanId: obj.salesmanId,
            point: this.currentPlayInfo.title
          });
        }
      }); // 播放时间变化时更改播放按钮显示状态
      $audio.on('timeupdate', () => {
        this.$store.dispatch('updatePlayInfo', {
          currentTime: audios.currentTime,
        });
      }); // 播放时间变化时更改播放按钮显示状态
      $audio.on('waiting', () => {
        console.log('waiting');
        this.$store.dispatch('updatePlayInfo', {
          currentTime: audios.currentTime,
        });
      }); // 监控缓冲时更改播放按钮显示状态
      $audio.on('pause', () => {
        this.$store.dispatch('updatePlayInfo', {
          currentTime: audios.currentTime,
          playStatus: 2,
        });
      }); // 音频结束时更改播放按钮显示状态
      $audio.on('ended', () => {
        const current = JSON.parse(JSON.stringify(this.currentPlayInfo));
        current.currentTime = 0;
        current.playStatus = 0;
        this.$store.dispatch('updatePlayInfo', current);
      }); // 音频结束时更改播放按钮显示状态
      $audio.on('error', () => {
        console.log('error');
        this.$store.dispatch('updatePlayInfo', {
          currentTime: 0,
          playStatus: 0,
        });
      }); // 音频结束时更改播放按钮显示状态

      // 播放信息更改
      eventBus.$on('changePlayInfo', (info) => {
        console.log(localStorage.getItem('isFree'), '播放：isFree=======', info);
        if (!this.$store.state.user.isVip && localStorage.getItem('isFree') == '0' && !this.currentPlayInfo.currentItem.isAudition) {
          eventBus.$emit('showVipPackageMask', {});
          return;
        }
        // 特么这么判断playStatus = 0永远进不来
        if (info.playStatus && this.currentPlayInfo.currentVoice) {
          if (info.playStatus == 1) { // 播放
            audios.play();
          } else if (info.playStatus == 0) { // 停止
            audios.pause();
          } else if (info.playStatus == 2) { // 暂停
            audios.pause();
          }
          eventBus.$emit('controlResume', {});
          this.$store.dispatch('updatePlayInfo', info);
        } else {
          this.$toast({
            message: '当前语言下没有对应的播放文件哦，请切换重试',
            position: 'bottom',
          });
        }
      });

      eventBus.$on('setCurrentTime', (val,precent) => { // todo
        if (!this.$store.state.user.isVip && localStorage.getItem('isFree') == '0' && !this.currentPlayInfo.currentItem.isAudition) {
          eventBus.$emit('showVipPackageMask', {});
          return;
        }
        const currentP = this.currentPlayInfo;
        // ??? -15不管了？
        if (val) {
          if (audios.currentTime + 15 >= audios.duration) {
            if (this.listLoop === 2) {
              audios.currentTime = 0;
              audios.pause();
              this.$store.dispatch('updatePlayInfo', {
                currentTime: 0,
                playStatus: 2,
              });
            } else {
              this.$store.dispatch('updatePlayInfo', {
                currentTime: audios.duration,
              });
            }
            return;
          }
        }
        
        if (currentP.playStatus === 2) {
          if(precent){
            if (precent && val === 0) {
              audios.currentTime = audios.duration * precent;
            } else {
              audios.currentTime += val;
            }
          }
          audios.play();
        } else if (currentP.playStatus === 0) {
          if(precent){
            if (precent && val === 0) {
              audios.currentTime = audios.duration * precent;
            } else {
              audios.currentTime += val;
            }
          }
          audios.pause();
        } else if (currentP.playStatus === 1) {
          if(precent){
            if (precent && val === 0) {
              audios.currentTime = audios.duration * precent;
            } else {
              audios.currentTime += val;
            }
          }
          audios.play();
        }
      });
    });
  }
};

