/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { Indicator } from 'mint-ui';

const state = {
  mapBaseData: JSON.parse(sessionStorage.getItem('mapBaseData')), // 景区基本信息，用于导航页面使用
  isSuccess: true, // 景区数据是否完成
  isLoading: false, // 首页loading
  hasLocationLoading: false, // 游客是否定位完成
  isInScenic: false, // 游客是否在景区内
  playInit: false, // 播放器信息初始化完成与否
  autoExplain: null, // 是否自动讲解,1为是，2为否
  listLoop: 2, // 是否列表循环播放,1为是，2为否
  loopIndex: -1, // 当前列表循环播放的index
  selectLanguage: {}, // 当前选择的语音类型
  coords: JSON.parse(sessionStorage.getItem('coords')), // 游客当前位置
  havedUseAutoExplain: false, // 是否已经触发了语音自动讲解功能
  serviceShow: false,
  currentPlayInfo: {
    title: '---',
    currentTime: 0,
    distance: null,
    latLng: null, // 导航作用
    playStatus: 0, // 0为未播放，1为播放，2为暂停
    voices: null, // 播放的url，时长等信息
    currentVoice: null, // 当前url
    typed: 1, // 当前播放的类型(typed:1为景区，2为景点，3为子景点组，4为景点组)
    playId: 0, // 当前播放类型的id。就是语音所属的直接景点id
    currentItem: {} // 当前播放数据
  }, // 当前播放器信息
  currentGroupItem: null, // 当前景点组或者子景点组的id
  currentSelectServiceItem: {}, // 当前选择的服务点
  routeObject: JSON.parse(sessionStorage.getItem('routeObject')), // 导航信息返回
  isGoto: false,
  isIndoor: false, // 是否是室内
};
const actions = {
  setMapData({ commit }, obj) {
    commit('setMapData', obj);
  },
  CHANGE_LC_LOADING({ commit }, flag) {
    commit('CHANGE_LC_LOADING', flag);
  },
  updateLoadingStatus({ commit }, flag) {
    commit('updateLoadingStatus', flag);
  },
  updateAutoExplain({ commit }, flag) {
    commit('updateAutoExplain', flag);
  },
  updateScenicInfo({ commit }, flag) {
    commit('updateScenicInfo', flag);
  },
  updateIsInScenic({ commit }, flag) {
    commit('updateIsInScenic', flag);
  },
  updateLanguage({ commit }, obj) {
    commit('updateLanguage', obj);
  },
  updateCoords({ commit }, Llanlon) {
    commit('updateCoords', Llanlon);
  },
  updatePlayInfo({ commit }, info) {
    commit('updatePlayInfo', info);
  },
  updateHavedUseAutoExplain({ commit }, flag) {
    commit('updateHavedUseAutoExplain', flag);
  },
  updatecurrentGroupItem({ commit }, item) {
    commit('updatecurrentGroupItem', item);
  },
  updateServiceShow({ commit }, item) {
    commit('updateServiceShow', item);
  },
  updateSelectService({ commit }, item) {
    commit('updateSelectService', item);
  },
  updateListLoop({ commit }, flag) {
    commit('updateListLoop', flag);
  },
  updateLoopIndex({ commit }, flag) {
    commit('updateLoopIndex', flag);
  },
  updatePlayInit({ commit }, flag) {
    commit('updatePlayInit', flag);
  },
  updateRouteObject({ commit }, obj) {
    commit('updateRouteObject', obj);
  },
  updateIsGoto({ commit }, flag) {
    commit('updateIsGoto', flag);
  },
  updateIsIndoor({ commit }, flag) {
    commit('updateIsIndoor', flag);
  },
};

const mutations = {
  setMapData(state, obj) {
    state.mapBaseData = obj;
    sessionStorage.setItem('mapBaseData', JSON.stringify(obj));
  },
  CHANGE_LC_LOADING(state, flag) {
    state.hasLocationLoading = flag;
  },
  updateLoadingStatus(state, flag) {
    state.isLoading = flag;
    if (flag) {
      Indicator.open({
        text: '加载中...',
        spinnerType: 'fading-circle',
      });
    } else {
      Indicator.close();
    }
  },
  updateAutoExplain(state, flag) {
    state.autoExplain = flag;
  },
  updateScenicInfo(state, flag) {
    state.isSuccess = flag;
  },
  updateIsInScenic(state, flag) {
    state.isInScenic = flag;
  },
  updateLanguage(state, obj) {
    state.selectLanguage = obj;
  },
  updateCoords(state, Llanlon) {
    state.coords = Llanlon;
    sessionStorage.setItem('coords', JSON.stringify(Llanlon));
  },
  updatePlayInfo(state, info) {
    Object.assign(state.currentPlayInfo, info);
  },
  updateHavedUseAutoExplain(state, flag) {
    state.havedUseAutoExplain = flag;
  },
  updatecurrentGroupItem(state, item) {
    state.currentGroupItem = item;
  },
  updateServiceShow(state, item) {
    state.serviceShow = item;
  },
  updateSelectService(state, item) {
    state.currentSelectServiceItem = item;
  },
  updateListLoop(state, flag) {
    state.listLoop = flag;
  },
  updateLoopIndex(state, flag) {
    state.loopIndex = flag;
  },
  updatePlayInit(state, flag) {
    state.playInit = flag;
  },
  updateRouteObject(state, obj) {
    state.routeObject = obj;
    sessionStorage.setItem('routeObject', JSON.stringify(obj));
  },
  updateIsGoto(state, flag) {
    state.isGoto = flag;
  },
  updateIsIndoor(state, flag) {
    state.isIndoor = flag;
  },
};

export default {
  state,
  actions,
  mutations,
};
