<template>
  <l-layerGroup v-if="formatedLatLngs.length > 0" ref="lineGroup">
    <!-- 终点图标 -->
    <l-marker
      v-if="destPoint"
      :lat-lng="destPoint"
      :icon="routeMarkerIcon('end')">
    </l-marker>

    <!-- 起始位置补全 S -->
    <l-polyline :latLngs="startPath" ref='myline'
      :weight="11"
      color="#fff"></l-polyline>
    <l-polyline :latLngs="startPath" ref='myline'
      :weight="9"
      color="#b6bac2"></l-polyline>
    <v-polyline-decorator ref="decoratorRef"
      :paths="[startPath]"
      :patterns="patterns"></v-polyline-decorator>
    <!-- 起始位置补全 E -->

    <!-- 导航返回的具体路线 S -->
    <l-polyline :latLngs="formatedLatLngs" ref='myline'
      :weight="11"
      color="#fff"></l-polyline>
    <l-polyline :latLngs="formatedLatLngs" ref='myline'
      :weight="9"
      color="#37acb9"></l-polyline>
    <v-polyline-decorator ref="decoratorRef"
      :paths="[formatedLatLngs]"
      :patterns="patterns"></v-polyline-decorator>
    <!-- 导航返回的具体路线 E -->

  </l-layerGroup>
</template>

<script>
import L from 'leaflet';
// import 'leaflet-polylinedecorator';
import { LMarker, LPolyline, LLayerGroup } from 'vue2-leaflet';
import Vue2LeafletPolylineDecorator from 'vue2-leaflet-polylinedecorator';
// import generateMarkerIcon from '@/mixin/generateMarkerIcon';
// import { getScenicSpotDetail } from '@/api';
// import EventBus from './bus';

export default {
  name: 'navigationLine',
  props: {
    destPoint: Object,
    latLngs: Array,
    startPoint: Array,
  },
  // mixins: [generateMarkerIcon],
  components: {
    LMarker,
    LPolyline,
    LLayerGroup,
    'v-polyline-decorator': Vue2LeafletPolylineDecorator,
  },
  data() {
    const patterns = [
      {
        repeat: 20,
        symbol: L.Symbol.dash({
          pixelSize: 8,
          pathOptions: { color: '#fff', weight: 3, lineJoin: 'round', lineCap: 'round' },
        }),
      },
    ];
    return {
      patterns,
      way: null,
      antPolyline: null,
    };
  },
  created() {
    // EventBus.$on('updateRoute', this.updateRoute);
    // EventBus.$on('spotDetailHide', this.spotDetailHide);
  },
  methods: {
    // 路线marker
    routeMarkerIcon(type) {
      return L.icon({
        iconUrl: `static/images/route/marker-${type}.png`,
        iconSize: [36, 44],
        iconAnchor: [18, 40],
      });
    },
    routePlanDest() {
      return L.divIcon({
        html:
          `<div class='dest-container'>
          <div class='ring'><div class='ring-inner'></div></div>
          <div class='line'><div class='real-line'></div></div>
        </div>`,
        iconAnchor: [10, 30],
        className: 'route-plan-dest-marker',
      });
    },
  },
  mounted() {
  },
  computed: {
    formatedLatLngs() {
      if (this.latLngs && this.latLngs.length > 0) {
        return this.latLngs;
      }
      return [];
    },
    position() {
      return this.$store.state.app.coords;
    },
    startPath() {
      if (this.formatedLatLngs && this.formatedLatLngs.length > 0 && this.position) {
        return [
          this.startPoint,
          this.formatedLatLngs[0],
        ];
      }
      return [];
    },
  },
  beforeDestroy() {
    // EventBus.$off('updateRoute', this.updateRoute);
    // EventBus.$off('spotDetailHide', this.spotDetailHide);
  },
};
</script>
<style>
.icon-track {
  width: 0.5rem;
  height: 0.5rem;
  display: block;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  background-color: #ff664f;
}
</style>

