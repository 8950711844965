/* eslint-disable */
import EXIF from '@/utils/exif';

function isObj(x) {
  const type = typeof x;
  return x != null && (type === 'object' || type === 'function');
}

function parseUrl(url) {
  const a = document.createElement('a');
  a.href = url;
  return {
    protocol: a.protocol.replace(':', ''),
    host: a.hostname,
    path: a.pathname.replace(/^([^\/])/, '/$1'),
    query: (function () {
      const ret = {};
      const seg = a.search.replace(/^\?/, '').split('&');
      const len = seg.length;
      let i = 0;
      let s = null;
      for (; i < len; i++) {
        if (!seg[i]) { continue; }
        s = seg[i].split('=');
        ret[s[0]] = s[1];
      }
      return ret;
    }()),
    hash: a.hash.replace('#', ''),
  };
}
const LOCAL_STORAGE_ITEM_NAME = 'H5_2B_LOCAL_STORAGE_ITEM_NAME';
function getLocalStorage() {
  let ret = {};
  if (supportsHtml5Storage()) {
    try {
      ret = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEM_NAME)) || {};
    } catch (e) {
      console.log('parse localstorage error', e);
    }
  } else {
    ret = window[LOCAL_STORAGE_ITEM_NAME] || {};
  }
  return ret;
}
function saveLocalStorage(item) {
  if (supportsHtml5Storage()) {
    localStorage.setItem(LOCAL_STORAGE_ITEM_NAME, JSON.stringify(item || {}));
  } else {
    window[LOCAL_STORAGE_ITEM_NAME] = item;
  }
}

function setLocalStorage(key, value) {
  const localData = getLocalStorage();
  localData[key] = value;
  saveLocalStorage(localData);
}

function supportsHtml5Storage() {
  try {
    return 'localStorage' in window && window['localStorage'] !== null;
  } catch (e) {
    return false;
  }
}

// copy from https://www.cnblogs.com/007sx/p/7583202.html
/*
  三个参数
  file：一个是文件(类型是图片格式)，
  w：一个是文件压缩的后宽度，宽度越小，字节越小
  objDiv：一个是容器或者回调函数
  photoCompress()
*/
function photoCompress(file, w, objDiv) {
  var ready = new FileReader();
  /*开始读取指定的Blob对象或File对象中的内容. 当读取操作完成时,readyState属性的值会成为DONE,如果设置了onloadend事件处理程序,则调用之.同时,result属性中将包含一个data: URL格式的字符串以表示所读取文件的内容.*/
  ready.readAsDataURL(file);
  ready.onload = function () {
    var re = this.result;
    canvasDataURL(re, w, objDiv)
  }
}
function canvasDataURL(path, obj, callback) {
  var img = new Image();
  img.src = path;
  img.onload = function () {
    var that = this;
    // 默认按比例压缩
    var w = that.width,
      h = that.height,
      scale = w / h;
    w = obj.width || w;
    h = obj.height || (w / scale);
    let imgW = w;
    let imgH = h;
    var quality = 0.7;  // 默认图片质量为0.7
    let rotateValue = 0;
    // 转一转
    if (obj.Orientation != "" && obj.Orientation != 1) {
      switch (obj.Orientation) {
        case 6://需要顺时针（向左）90度旋转
          [w, h] = [h, w];
          rotateValue = Math.PI / 2;
          break;
        case 8://需要逆时针（向右）90度旋转
          [w, h] = [h, w];
          rotateValue = -Math.PI / 2;
          break;
        case 3://需要180度旋转
          rotateValue = Math.PI;
          break;
      }
    }

    //生成canvas
    var canvas = document.createElement('canvas');
    var anw = document.createAttribute("width");
    var anh = document.createAttribute("height");
    anw.nodeValue = w;
    anh.nodeValue = h;
    canvas.setAttributeNode(anw);
    canvas.setAttributeNode(anh);

    var ctx = canvas.getContext('2d');
    ctx.save();//保存状态
    ctx.translate(w / 2, h / 2);
    ctx.rotate(rotateValue);
    ctx.drawImage(that, -imgW / 2, -imgH / 2, imgW, imgH);
    ctx.restore();//恢复状态

    // 图像质量
    if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
      quality = obj.quality;
    }
    // quality值越小，所绘制出的图像越模糊
    var base64 = canvas.toDataURL('image/jpeg', quality);
    // 回调函数返回base64的值
    callback(base64);
  }
}
/**
 * 将以base64的图片url数据转换为Blob
 * @param urlData
 * 用url方式表示的base64图片数据
 */
function convertBase64UrlToBlob(urlData) {
  var arr = urlData.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

// 把copy来的代码包装一下
/**
 * 
 * @param {*} file 文件blob
 * @param {*} options width, height, quality 非必须, maxSize: k, Orientation: 图片方向
 */
export function compressPhoto(file, options) {
  return new Promise((resolve) => {
    options = options || {};
    if (options.maxSize) {
      options.quality = Math.min(1, (options.maxSize * 1024 / file.size).toFixed(4));
    }
    EXIF.getImageBlobData(file, () => {
      console.log('exif of file', file);
      console.log('compress options', options);
      options.Orientation = file.exifdata.Orientation || 1;
      photoCompress(file, options, (base64) => {
        const compressedFile = convertBase64UrlToBlob(base64);
        compressedFile.name = file.name;
        resolve(compressedFile);
      });
    });
  });
}

// 返回一个对象中的keys的值,其他值不要了
// 用于和form绑定的数据的直接赋值
export const keysFilter = (obj, ...keys) => {
  const ret = {};
  keys.forEach((key) => { ret[key] = obj && obj[key]; });
  return ret;
};

// 返回一个对象中的keys的值,点名的值不要了
// 用于和form绑定的数据的直接赋值
export const keysRejecter = (obj, ...keys) => {
  const ret = {};
  for (const key in obj) {
    if (!keys.includes(key)) {
      ret[key] = obj[key];
    }
  }
  return ret;
};

// 从字符串中删掉html标签
// e.g.  <p>123</p>  -> 123
export const removeHTMLTag = (str) => {
  const ele = document.createElement('div');
  ele.innerHTML = str;
  return ele.textContent;
}

function formatSeconds(value) {
  var secondTime = parseInt(value);// 秒
  var minuteTime = 0;// 分
  var hourTime = 0;// 小时
  if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
    //获取分钟，除以60取整数，得到整数分钟
    minuteTime = parseInt(secondTime / 60);
    //获取秒数，秒数取佘，得到整数秒数
    secondTime = parseInt(secondTime % 60);
    //如果分钟大于60，将分钟转换成小时
    if (minuteTime > 60) {
      //获取小时，获取分钟除以60，得到整数小时
      hourTime = parseInt(minuteTime / 60);
      //获取小时后取佘的分，获取分钟除以60取佘的分
      minuteTime = parseInt(minuteTime % 60);
    }
  }
  var result = "" + parseInt(secondTime) + "秒";

  if (minuteTime > 0) {
    result = "" + parseInt(minuteTime) + "分" + result;
  }
  if (hourTime > 0) {
    result = "" + parseInt(hourTime) + "小时" + result;
  }
  return result;
}

export function toQueryStr(obj) {
  let str = '';
  Object.keys(obj).map((key) => {
    if(Array.isArray(obj[key])){ // 如果是数组,那么&key=val0&key=val1
      const subStr = obj[key].map(val => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&');
      str = `${str}&${subStr}`;
    }else{
      str = `${str}&${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
    }
  });
  return str.slice(1);
}

export const gaodeMapKey="f3288ae97e6adaa02555e5ebf5b32fde"

export const FlatArray = (array) => {
  if('flat' in array){
    return array.flat();
  }
  const ret =[];
  array.forEach(item => {
    if(Array.isArray(item)){
      ret.push(...item)
    }else{
      ret.push(item)
    }
  })
  return ret;
}

export default {
  isObj,
  parseUrl,
  getLocalStorage,
  saveLocalStorage,
  setLocalStorage,
  compressPhoto,
  keysFilter,
  keysRejecter,
  formatSeconds,
};
