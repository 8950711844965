<template>
  <transition name="slide" appear>
    <div class="service_down">
      <div class="input" v-if="currentNearService.type===-1">
        <img class="search" src="../assets/f7/search.png" alt="">
        <input type="search" placeholder="请输入景点" maxlength="20" v-model="searchKey"/>
      </div>
      <div class="slide-list" ref="scroll">
        <template v-if="currentNearService.type==-1">
            <nearTree
             v-if="mapScenicGroup.length>0||currentServiceList.length>0"
             :childGroup="mapScenicGroup"
             :searchKey="searchKey"
             :currentServiceList="currentServiceList"/>
        </template>
        <ul v-else-if="currentNearService.type==-2&&trigger">
          <li class="serve-li"
            v-for="(item,index) in currentServiceList"
            :key="index"
            @click="selectRoutes(item)">
            <div class="serve-left">
              <img class="serve-icon" src="../assets/f7/routes.png" alt="">
              <span>{{item.name}}</span>
            </div>
            <div class="serve-right">
              <img class="serve-icon"
                v-if="item.selected"
                src="../assets/f7/currentSelect.png" alt="">
              <img class="serve-icon" v-else src="../assets/f7/routeNoselect.png" alt="">
            </div>
          </li>
        </ul>
        <ul v-else-if="currentNearService.type==3">
          <li
           class="serve-li"
           v-for="(item,index) in currentServiceList"
           :key="index"
           @click="changeService(item)"
          >
            <div class="serve-left">
              <img
              class="serve-icon" :src="currentNearService.listPicPath" alt="">
              <span>{{item.name}}</span>
              <img
                v-if="item.id==currentSelectServiceItem.id"
                src="../assets/f7/currentSelect.png"
                class="childPlay2">
            </div>
            <div class="serve-right">
              {{item.distance}}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';
import eventBus from '@/eventBus';
import scrollMixin from '@/mixin/scroll';
import nearTree from './nearTree';

export default {
  components: {
    nearTree,
  },
  mixins: [scrollMixin],
  props: {
    currentNearService: {
      type: Object,
      default() {
        return {};
      },
    },
    currentServiceList: {
      type: Array,
      default() {
        return [];
      },
    },
    mapScenicGroup: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      searchKey: '',
      trigger: true,
    };
  },
  computed: {
    ...mapState({
      currentPlayInfo: state => state.app.currentPlayInfo,
      currentSelectServiceItem: state => state.app.currentSelectServiceItem,
    }),
  },
  methods: {
    // 点击服务列表
    changeService(item) {
      this.$store.dispatch('updateSelectService', item);
      eventBus.$emit('changeScenic', item);
    },
    selectRoutes(item) {
      this.trigger = false;
      // eslint-disable-next-line
      this.currentServiceList.map((items) => {
        // eslint-disable-next-line
        items.selected = false;
      });
      // eslint-disable-next-line
      this.currentServiceList.map((items) => {
        if (items.id === item.id) {
          this.trigger = true;
          // eslint-disable-next-line
          items.selected = true;
        }
      });
      eventBus.$emit('changeRoute', item);
    },
  },
  mounted() {
    this.scrolljs(false);
  },
};
</script>
<style lang="scss" scoped>
.service_down{
  height: 344px;
  position: fixed;
  padding: 19px;
  box-sizing: border-box;
  top: 50px;
  width: 100%;
  z-index: 3;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  background:rgba(255,255,255,0.9);
  .input{
    position: relative;
    margin-bottom: 10px;
    height: 31px;
    .search{
      position: absolute;
      left: 10px;
      top: 9px;
      width:15px;
      height:15px;
    }
    input{
      outline: none;
      text-indent: 30px;
      width:335px;
      height:32px;
      background:rgba(255,255,255,1);
      border-radius:30px;
      border:1px solid rgba(237,239,242,1);
    }
  }
  .slide-list{
    flex: 1;
    overflow-y: auto;
    // height: 277px;
  }
  .nonhav{
    text-align: center;
    font-size: 12px;
    color: #000
  }
}
.serve-li{
  height: 40px;
  line-height: 40px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  .serve-left{
    display: flex;
    display: -webkit-flex;
    align-items: center;
    font-size: 13px;
    color: #202020;
    .serve-icon{
      width:18px;
      height:18px;
      margin-right: 15px;
    }
  }
  .serve-right{
    font-size: 12px;
    color: #A5B0B5;
    .serve-icon{
      width:18px;
      height:18px;
      margin-right: 15px;
    }
  }
  .childPlay2{
    width:15px;
    margin-left: 10px;
    height:15px;
  }
}
</style>
