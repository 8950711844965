<template>
  <transition name="slidetop">
    <div class="groupWrap">
      <div class="groupcontent">
        <div class="sg_top">
          <div class="sgt_left"
            v-if="currentGroupItem.voices.length>0">
            <img class="sgt_img" :src="currentGroupItem.introPic" alt="">
            <div class="clip"></div>
            <div class="play"
             v-if="endPlayStatus(currentGroupItem)==0" @click="playAudio(currentGroupItem,1)">
             <img src="../../assets/f7/play.png" alt="">
            </div>
            <div class="play"
             v-else-if="endPlayStatus(currentGroupItem)==1" @click="playAudio(currentGroupItem,2)">
             <img src="../../assets/f7/childPlaying.gif" alt="">
            </div>
            <div class="play"
             v-else-if="endPlayStatus(currentGroupItem)==2" @click="playAudio(currentGroupItem,1)">
             <img src="../../assets/f7/childPause.png" alt="">
            </div>
          </div>
          <div class="sgt_left" v-else>
            <img class="sgt_img" :src="currentGroupItem.introPic" alt="">
            <div class="clip"></div>
          </div>
          <div class="sgt_right" :class="isChildrenGroup?'cg_color':''">
            <p class="title" >{{currentGroupItem.name}}</p>
            <span class="desc">{{currentGroupItem.introVoiceCut||currentGroupItem.introCut}}</span>
            <!-- <span class="look"
             v-if="currentGroupItem.typed===3||currentGroupItem.typed===4||isIndoor"
             @click="showModal('scenicDetailModal',{marker:currentGroupItem,isChild:true})">
             查看详情
             </span> -->
             <span class="look"
             @click="showModal('scenicDetailModal',currentGroupItem)">
             查看详情
             </span>
          </div>
        </div>
      <div class="childrenBg"
      :style="{background:'url('+currentGroupItem.introPic+')'}" v-if="isChildrenGroup"></div>
      </div>
      <div class="sg_main" v-if="!isChildrenGroup" ref="scroll">
        <div class="sg_wrag"
           v-if="currentGroupItem.subScenics.length>0">
          <div class="main_item"
           v-for="(child,index) in currentGroupItem.subScenics"
           :key="index"
           >
            <div class="item_left">
              <div class="item_img">
                <img class="item_imgnei" :src="child.introPic" alt="">
                <div class="clip"></div>
                <div class="play" v-if="endPlayStatus(child)==0" @click="playAudio(child,1)">
                    <img src="../../assets/f7/play.png" alt="">
                  </div>
                  <div class="play"
                   v-else-if="endPlayStatus(child)==1" @click="playAudio(child,2)">
                    <img src="../../assets/f7/childPlaying.gif" alt="">
                  </div>
                  <div class="play"
                   v-else-if="endPlayStatus(child)==2" @click="playAudio(child,1)">
                    <img src="../../assets/f7/childPause.png" alt="">
                  </div>
              </div>
              <div class="item_info">
                <p class="title">{{child.name}}</p>
                <span class="desc">{{child.introVoiceCut}}</span>
              </div>
            </div>
            <div class="item_right"
            @click="showModal('scenicDetailModal',child)">
              <img src="../../assets/f7/info.png" alt="">
              <span>详情</span>
            </div>
          </div>
        </div>
        <div class="sg_wrag" v-else>
          <p>暂无景点组内景点哦·····</p>
        </div>
      </div>
      <div class="childGroup_main" v-if="isChildrenGroup" ref="scroll">
        <div v-for="(item,index) in scenicsGroup.subScenics" :key="index">
          <div class="cg_h1">{{item.name}}</div>
          <div class="child_main">
            <div class="child_main_wrap">
              <div class="childGroup_item"
              v-for="(child,index2) in item.childScenics" :key="index2">
                <div class="cg_top cg_img" v-if="child.voices.length>0">
                  <img class="cg_imgnei" :src="child.introPic" alt="">
                  <div class="clip"></div>
                  <div class="play" v-if="endPlayStatus(child)==0" @click="playAudio(child,1)">
                    <img src="../../assets/f7/play.png" alt="">
                  </div>
                  <div class="play"
                   v-else-if="endPlayStatus(child)==1" @click="playAudio(child,2)">
                    <img src="../../assets/f7/childPlaying.gif" alt="">
                  </div>
                  <div class="play"
                   v-else-if="endPlayStatus(child)==2" @click="playAudio(child,1)">
                    <img src="../../assets/f7/childPause.png" alt="">
                  </div>
                  <div class="detail">{{child.name}}</div>
                </div>
                <div class="cg_top cg_img" v-else>
                  <img class="cg_imgnei" :src="child.introPic" alt="">
                  <div class="clip"></div>
                  <div class="play">
                    <img src="../../assets/f7/play.png" alt="">
                  </div>
                  <div class="detail">{{child.name}}</div>
                </div>
                <div class="cg_btm"
                @click="showModal('scenicDetailModal',child)">
                  <p>查看详情</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sg_btm">
        <span @click="hideModal('scenicGroupModal')">收起</span>
      </div>
    </div>
  </transition>
</template>

<script>
import eventBus from '@/eventBus';
import { mapState } from 'vuex';

export default {
  props: {
    isChildrenGroup: {
      type: Boolean,
      default() {
        return false;
      },
    },
    scenicList: {
      type: Array,
      default() {
        return [];
      },
    },
    currentGroupItem: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      scenicsGroup: [],
      currentChild: null,
    };
  },
  computed: {
    ...mapState({
      selectLanguage: state => state.app.selectLanguage,
      currentPlayInfo: state => state.app.currentPlayInfo,
      indoorCurrentPlayInfo: state => state.indoor.indoorCurrentPlayInfo,
      isIndoor: state => state.app.isIndoor,
    }),
    endPlayStatus() {
      const that = this;
      // eslint-disable-next-line
      return function (item) {
        if (!that.isIndoor) {
          // eslint-disable-next-line
        if (item&&item.typed == that.currentPlayInfo.typed && item.id == that.currentPlayInfo.playId) {
            return that.currentPlayInfo.playStatus;
          }
        } else {
          // eslint-disable-next-line
        if (item&&item.typed == that.indoorCurrentPlayInfo.typed && item.id == that.indoorCurrentPlayInfo.playId) {
            return that.indoorCurrentPlayInfo.playStatus;
          }
        }

        return 0;
      };
    },
  },
  methods: {
    playAudio(item, status) {
      // console.log(item);
      if (item.typed === 2) { // 顶部景点

      } else if (item.typed === 3) { // 子景点
        this.currentChild = item;
      }
      if (this.isIndoor) {
        eventBus.$emit('changeIndoorAudioInfo', { item, status });
        eventBus.$emit('changeIndoorSelected', item);
      } else {
        eventBus.$emit('changeAudioInfo', { item, status });
        eventBus.$emit('changeSelected', item);
      }
    },
    scrolljs() {
      this.$nextTick(() => {
        this.scrollBar = window.createScroll(this.$refs.scroll, {
          scrollbars: false,
        }); /* 添加流动条 */
      });
    },
    showModal(modal, item) {
      eventBus.$emit('showModal', { whichModal: modal, arg: item });
    },
    hideModal(modal) {
      eventBus.$emit('hideModal', modal);
    },
  },
  mounted() {
    if (this.currentGroupItem) {
      // 子景点
      if (this.isChildrenGroup) {
        this.scenicsGroup = this.scenicList.filter(item => item.isContainChildScenic === 1);
        // eslint-disable-next-line
        this.scenicsGroup.map((item) => {
          if (item.id === this.currentGroupItem.id) {
            this.scenicsGroup = item;
          }
        });
      } else { // 景点组
        this.scrolljs(false);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.childrenBg{
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  filter: blur(10px);
}
.groupcontent{
  position: relative;
}
.groupWrap{
  left: 0;
  height: 530px;
  background: #fff;
  width: 100%;
  position: absolute;
  top: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: left;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  .sg_top{
    padding: 10px;
    z-index: 2;
    box-sizing: border-box;
    display: flex;
    display: -webkit-flex;
    align-items: flex-start;
    position: relative;
    .sgt_left{
      width: 90px;
      height: 90px;
      margin-right: 10px;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      .sgt_img{
        width: 100%;
        height: 100%;
      }

    }
    .sgt_right{
      flex: 1;
      .title{
        font: 16px PingFangSC-Medium;
        color:#4a4a4a;
        margin-bottom: 5px;
      }
      .desc{
        color: #999;
        font-size: 12px;
      }
      .look{
        position: absolute;
        bottom: 10px;
        color: #999;
        right: 10px;
        &::after{
          padding-left: .1rem;
          content: ">";
          font-family: serif;
        }
      }
    }

  }
  .sg_main{
    flex: 1;
    overflow: scroll;
    background: #fafafa;
    .main_item{
      display: flex;
      display: -webkit-flex;
      align-items: center;
      padding: 10px;
      justify-content: space-between;
      border-bottom: 1px dashed #e0e0e0;
      .item_left{
        display: flex;
        display: -webkit-flex;
        width: 80%;
        .item_img{
          width: 72px;
          height: 72px;
          margin-right: 10px;
          border-radius: 10px;
          position: relative;
          overflow: hidden;
          .clip{
            border-radius: 10px;
          }
          .item_imgnei{
            width: 100%;
            height: 100%;
          }
        }
        .item_info{
          flex:1;
          .title{
            font-size: 15px;
            color: #4a4a4a;
          }
          .desc{
            font-size: 11px;
            color: #999
          }
        }
      }
      .item_right{
        width: 16%;
        border-left: 1px solid #e6e6e6;
        font-size: 12px;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        color: #abadb3;
        flex-direction: column;
        img{
          width: 20px;
          margin-bottom: 5px;
          height: 20px;
        }
      }
    }
  }
  .childGroup_main{
    flex: 1;
    overflow: scroll;
    background: #fafafa;
    display: flex;
    flex-direction: column;
    padding: 10px;
    .cg_h1{
      font-size: 11px;
      color: #B3B3B3;
      text-align: center;
      margin: 0.2rem auto;
      height: 25px;
      width: 100%;
      line-height: 25px;
      background: #F0F0F0;
    }
    .child_main{
      flex: 1;
      overflow: scroll;
      .child_main_wrap{
        display: flex;
        flex-wrap: wrap;
      }
      .childGroup_item{
        position: relative;
        width: 25%;
        padding: 0 5px;
        box-sizing: border-box;
        margin-bottom: 10px;
        .cg_img{
          width: 100%;
          height: 78px;
          margin-right: 10px;
          border-radius: 10px;
          position: relative;
          overflow: hidden;
          .clip{
            border-radius: 10px;
          }
          .cg_imgnei{
            width: 100%;
            height: 100%;
          }
        }
        .detail{
          font-size: 10px;
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 0 5px;
          width: 100%;
          box-sizing: border-box;
          color: #FFFFFF;
          text-align: center;
          box-sizing: border-box;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          background: rgba(0, 0, 0, 0.5);
        }
        .cg_btm{
          color: #B8B8B8;
          border: 1px solid #E6E6E6;
          border-radius: 10px;
          padding: 2px 5px;
          text-align: center;
          width: 90%;
          font-size: 10px;
          box-sizing: border-box;
          margin: 5px auto 0;
        }
      }
    }
  }
  .sg_btm{
    height: 40px;
    text-align: center;
    line-height: 40px;
    span{
      position: relative;
      font-size: 13px;
      color: #b8b8b8;
      &::after{
        content: "";
        background: url('../../assets/f7/scenicGroupTop.png') no-repeat center top;
        background-size: contain;
        width: 14px;
        height: 14px;
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translateY(-50%)
      }
    }
  }
  .clip{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0.08rem solid #615b5b;
    border-radius: 50%;
    opacity: 0.3;
  }
  .play{
    background: rgba(0,0,0,0.2);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 22;
    img{
      width: 25px;
      height: 31px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
}
.cg_color{
  .title{
    color: #fff !important
  }
  .desc,.look{
    color: #c9c9c9 !important;
  }
}
</style>
