<template>
  <div class="h5_index_wrap">
    <l-map ref="map"
           class="map-content"
           :max-zoom="scenic.zoomMax"
           :zoom="mapZoom.zoom"
           :min-zoom="scenic.zoomMin"
           :crs="options.crs"
           :options="options"
           @click="onClickMap"
           >
      <!-- 室外模式 start -->
      <l-layer-group>
        <l-tile-layer :options="options" :url="gaodeTileUrl" v-if="gaodeTileUrl"></l-tile-layer>
        <!-- 手绘图 -->
        <l-layer-group v-if="handScan">
          <l-layer-group v-for="(item,index) in map" :key="index">
            <!-- 瓦片模式 -->
            <l-tile-layer
            :options="options"
            :url="item.tileUrl+'?'+getImageNameFromUrl(item.picPath)"
            @tileerror="tileerror"
            v-if="item.isTileInH5"></l-tile-layer>
            <!-- 非瓦片模式 -->
            <l-image-overlay
              v-else
              ref="imageOverlay"
              :url="item.picPath"
              :bounds="imageLayerBoundsAll(item)"
            ></l-image-overlay>
          </l-layer-group>
        </l-layer-group>
      </l-layer-group>
      <!-- 室外模式 end -->
      <l-layer-group v-if="!isGoto">
        <!-- 景点 S -->
        <l-layer-group
        v-if="mapScenicList&&currentNearService.type==-1&&isStartShow" :options="{zIndex:400}">
          <v-marker-cluster ref='markerCluster'
            :options="{
                iconCreateFunction: clusterIcon,
                disableClusteringAtZoom:mapZoom.maxZoom-1,
                showCoverageOnHover: false,
                zoomToBoundsOnClick: true,
                spiderfyOnMaxZoom: false,
                animateAddingMarkers: false,
                animate:false,
                maxClusterRadius: 30
              }">
              <l-marker
                :key="index"
                v-for="(item,index) in mapScenicList"
                v-if="item.isContainChildScenic==2&&isStartShow"
                :lat-lng="{lat:item.lat,lng:item.lon}"
                :options="{data:item, title: 'marker1'}"
                :icon="newIcon(item)"
                @popupclose="closePopup(item)"
                @popupopen="openPopup(item, $event)"
              >
                <l-popup
                  :content="scenicPopupContent(item)"
                  :options="popupOptions.scenicPopup"></l-popup>
              </l-marker>
              <!-- 子景点组 S -->
              <l-marker
                :key="index"
                v-for="(item,index) in mapScenicList"
                v-if="item.isContainChildScenic==1&&isStartShow"
                :lat-lng="{lat:item.lat,lng:item.lon}"
                :options="{data:item, title: 'marker2'}"
                :icon="childrenScenicGroupIcon(item)"
                @click="childrenScenicGroupOpen(item)"
              ></l-marker>
              <!-- 子景点组 E -->

            <!-- 景点组 E -->
          </v-marker-cluster>

          <!-- 景点组 S -->
          <l-layer-group v-if="mapScenicGroup.length>0">
              <l-marker
              :key="index"
              v-for="(item,index) in mapScenicGroup"
              :lat-lng="{lat:item.lat,lng:item.lon}"
              :options="{data:item}"
              :icon="scenicGroupIcon(item)"
              @click="scenicGroupOpen(item)"
              ></l-marker>
          </l-layer-group>
        </l-layer-group>
        <!-- 景点 E -->

        <!--推荐路线 S-->
        <l-layer-group
        v-else-if="currentRoutes&&currentNearService.type==-2" :options="{zIndex:800}">
          <l-layer-group :options="{zIndex:10}">
            <l-marker
            v-for="(marker, index) in flagCurrentRoute"
            :key="index"
            v-if="marker.flag==1"
            :lat-lng="[marker.lat,marker.lon]" :icon="markIcon(index+1)"></l-marker>
          </l-layer-group>
          <l-layer-group :options="{zIndex:1}">
            <v-poly :lat-lngs="currentRoutes.route" :weight="8"
                    :color="'#FF6969'"></v-poly>
            <v-poly :lat-lngs="currentRoutes.route" :weight="1"
                    dashArray="8"
                    :color="'#FFFFFF'"></v-poly>
          </l-layer-group>
        </l-layer-group>
        <!--推荐结束 E-->

        <!-- 服务点 S -->
        <l-layer-group
        v-else-if="currentServiceList.length>0&&currentNearService.type==3&&serviceShow"
        :options="{zIndex:800}">
          <l-marker
            :key="index"
            v-for="(item,index) in currentServiceList"
            :lat-lng="{lat:item.lat,lng:item.lon}"
            :options="{data:item,title:'marker3'}"
            :icon="serviceIcon(item)"
            @popupclose="closePopup(item)"
            @popupopen="servicePopup(item, $event)"
            >
            <l-popup
              :content="servicePopupContent(item)"
              :options="popupOptions.servicePopup"></l-popup>
            </l-marker>
        </l-layer-group>
        <!-- 服务点 E -->
      </l-layer-group>

      <!-- 导航路线 S-->
      <navigation-line
        v-if="routeObject&&coords&&isGoto"
        :latLngs="navigationLatLngs"
        :destPoint="{lat:routeObject.desItem.lat||routeObject.desItem.latLng.lat,
        lng:routeObject.desItem.lon||routeObject.desItem.latLng.lng}"
        :startPoint="[coords.lat,coords.lng]"
      />
      <!-- 导航路线 E-->

      <!--游客定位 marker S  -->
      <!-- <l-layer-group :options="{zIndex:9999}">
        <l-marker ref="location" id="location"
                  :lat-lng="circleCenter"
                  v-if="location.isShow"
                  :icon="locationIcon()">
        </l-marker>
      </l-layer-group> -->
      <!--游客定位 marker E  -->
    </l-map>

  </div>
</template>

<script>
import eventBus from '@/eventBus';
import utils from '@/utils/utils';
import icon from '@/mixin/icon';
import userCompass from '@/mixin/userCompass';
import wx from 'weixin-js-sdk';
import L from 'leaflet';
import {
  LMap,
  LTileLayer,
  LLayerGroup,
  LImageOverlay,
  LMarker,
  LIconDefault,
  LPolyline,
  LPolygon,
  LPopup,
} from 'vue2-leaflet';
import navigationLine from '@/components/navigationLine';
import Vue2LeafletMarkercluster from 'vue2-leaflet-markercluster';
import Vue2LeafletPolylineDecorator from 'vue2-leaflet-polylinedecorator';
import { mapState } from 'vuex';

const $ = window.$;

export default {
  mixins: [icon, userCompass],
  components: {
    LMap,
    LTileLayer,
    LLayerGroup,
    LImageOverlay,
    LMarker,
    LIconDefault,
    LPolygon,
    'v-poly': LPolyline,
    LPopup,
    // VLine,
    navigationLine,
    // VPolygon,
    'v-polyline-decorator': Vue2LeafletPolylineDecorator,
    // PMarker,
    'v-marker-cluster': Vue2LeafletMarkercluster,
  },
  props: {
    scenic: {
      type: Object,
      default() {
        return {};
      },
    },
    map: {
      type: Array,
      default() {
        return [];
      },
    },
    handScan: {
      type: Boolean,
      default() {
        return true;
      },
    },
    mapScenicGroup: {
      type: Array,
      default() {
        return [];
      },
    },
    mapScenicList: {
      type: Array,
      default() {
        return [];
      },
    },
    currentRoutes: {
      type: Object,
      default() {
        return {};
      },
    },
    currentNearService: {
      type: Object,
      default() {
        return {};
      },
    },
    // 服务点列表
    currentServiceList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      navWayPattern: [
        { offset: 6,
          repeat: 50,
          symbol: L.Symbol.marker({ rotate: true,
            markerOptions: {
              icon: L.icon({
                iconUrl: 'static/images/route/arrow.png',
                iconSize: [6, 6],
                iconAnchor: [3, -2],
              }),
            } }),
        },
      ],
      gaodeTileUrl:
        '//wprd01.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=1&style=7&ltype=',
      mapZoom: {
        maxZoom: 20,
        minZoom: 0,
        zoom: 10,
      },
      options: {
        zoomOffset: 1,
        tileSize: 128,
        zIndex: 200,
        trackResize: true,
        zoomControl: false,
        attributionControl: false,
        inertia: true,
        zoomSnap: 1,
        crs: L.CRS.EPSG3857,
        dragging: true,
        bounceAtZoomLimits: true,
      },
      polyIndex: 0,
      location: {
        // 定位数据
        coords: {
          // GPS坐标
          lat: null,
          lng: null,
        },
        hasShowUserNotInScenic: false, // 已经提示过游客不在景区内
        hadPanTo: false, // 已经移到游客位置
        hadWarn: false, // 已经提示过错误
        userTrigger: false, // 用户触发
        isShow: false, // 是否显示
      },
      isStartShow: false,
      userPositionMarker: null,
    };
  },
  watch: {
    scenic: {
      handler(nv) {
        console.log('nv', nv);
      },
    },
  },
  computed: {
    ...mapState({
      currentPlayInfo: state => state.app.currentPlayInfo,
      currentGroupItem: state => state.app.currentGroupItem,
      serviceShow: state => state.app.serviceShow,
      routeObject: state => state.app.routeObject,
      coords: state => state.app.coords,
      isGoto: state => state.app.isGoto,

    }),
    navigationLatLngs() {
      if (this.routeObject) {
        return this.routeObject.path;
      }
      return null;
    },
    circleCenter() {
      return L.latLng(this.location.coords.lat, this.location.coords.lng);
    },
    imageLayerBounds2() {
      // 多张手绘图，只拿第一张
      const bounds = [];
      if (this.map) {
        bounds.push([this.map[0].leftBottomLat, this.map[0].leftBottomLon]);
        bounds.push([this.map[0].rightTopLat, this.map[0].rightTopLon]);
      }
      return bounds;
    },
    flagCurrentRoute() {
      return this.currentRoutes.route.filter(item => item.flag === 1);
    },
    isAssociationAiBot() {
      return this.$store.getters.isAssociationAiBot;
    },
  },
  methods: {
    // 瓦片错误处理，换为图片
    tileerror() {
      console.log('tileerror');
      // eslint-disable-next-line
      this.map.map((item) => {
        // eslint-disable-next-line
        item.isTileInH5 = 0;
      });
    },
    // 获取手绘图原始图名称
    getImageNameFromUrl(url) {
      if (url) {
        return url.substring(url.lastIndexOf('/') + 1);
      }
      return '';
    },

    // 子景点组弹窗
    // eslint-disable-next-line
    childrenScenicGroupOpen(item) {
      // const map = this.$refs.map.mapObject;
      // map.panTo([item.lat, item.lon]);
      this.$store.dispatch('updatecurrentGroupItem', item);
      eventBus.$emit('showModal', { whichModal: 'scenicGroupModal', arg: true });
      // eslint-disable-next-line
      if (this.isScenicChildGroupIn(item)) {
        return false;
      }
    },
    // 景点组弹窗
    // eslint-disable-next-line
    scenicGroupOpen(item) {
      // const map = this.$refs.map.mapObject;
      // map.panTo([item.lat, item.lon]);
      this.$store.dispatch('updatecurrentGroupItem', item);
      eventBus.$emit('showModal', { whichModal: 'scenicGroupModal', arg: false });
      // eslint-disable-next-line
      if (this.isScenicGroupIn(item)) {
        return false;
      }
    },
    // 所有手绘图的边界划定
    imageLayerBoundsAll(item) {
      const bounds = [];
      if (this.map) {
        bounds.push([item.leftBottomLat, item.leftBottomLon]);
        bounds.push([item.rightTopLat, item.rightTopLon]);
      }
      return bounds;
    },
    // 设置视图区域
    autoZoomRange(isMaxBounds) {
      // 计算是适合的层级
      let z = this.$refs.map.mapObject.getBoundsZoom(this.imageLayerBounds2, true);
      // 设置最大，最小，以及边界，以及初始展示层级
      z = Math.min(19, z);
      this.$refs.map.mapObject
        .setMinZoom(z)
        .setMaxBounds(isMaxBounds ? this.imageLayerBounds2 : null)
        .panInsideBounds(this.imageLayerBounds2, {
          duration: 0.12,
        })
        .setZoom(z);
    },
    // 打开popup
    // eslint-disable-next-line
    openPopup(marker, popup) {
      // eslint-disable-next-line
      let self = this,
        $currentTarget;
      $(document).off('click', '#speaker');
      $(document).on('click', '#speaker', (e) => {
        $currentTarget = $(e.currentTarget);
        const className = $currentTarget.attr('class');
        // eventBus.$emit('changeSelected', marker);
        // eslint-disable-next-line
        if (marker.typed == this.currentPlayInfo.typed && marker.id == this.currentPlayInfo.playId) {
          if (/playing/.test(className)) {
            eventBus.$emit('changePlayInfo', { playStatus: 2 });
          } else {
            eventBus.$emit('changePlayInfo', { playStatus: 1 });
          }
        } else {
          eventBus.$emit('changeAudioInfo', { item: marker, status: 1 });
        }
        eventBus.$emit('changeSelected', marker);
      });
      // 详情
      $(document).off('click', '#detail');
      $(document).on('click', '#detail', (e) => {
        // $currentTarget = $(e.currentTarget);
        if (this.isAssociationAiBot) {
          eventBus.$emit('showAi', marker);
        } else {
          $currentTarget = $(e.currentTarget);
          eventBus.$emit('showModal', { whichModal: 'scenicDetailModal', arg: marker });
        }
      });
      // 导航
      $(document).off('click', '#goto-icon');
      $(document).on('click', '#goto-icon', (e) => {
        console.log("====点击去这里====", marker);
        eventBus.$emit('serviceGoto', marker);
      });
      // 室内入口
      $(document).off('click', '#speakerIndoor');
      $(document).on('click', '#speakerIndoor', (e) => {
        $currentTarget = $(e.currentTarget);
        this.$router.push({ path: `/indoorHome/?subScenicId=${marker.id}` });
      });
      const map = this.$refs.map.mapObject;
      map.panTo([marker.lat || marker.latitude, marker.lon || marker.longitude]);
    },
    // 关闭popup
    closePopup(marker) {
      this.$store.dispatch('updateSelectService', {});
      console.log(marker);
    },
    // 聚合图标定义
    clusterIcon(marker) {
      // eslint-disable-next-line
      let markername;
      const allMarker = marker.getAllChildMarkers && marker.getAllChildMarkers();
      if (allMarker) {
        // eslint-disable-next-line
        Array.isArray(allMarker) &&
        // eslint-disable-next-line
        allMarker.map((item, index) => {
          if (index === 0) {
            markername = `${item.options.data.name}...等`;
          }
        });
      }
      if (marker.getChildCount && marker.getChildCount() === this.mapScenicList.length) {
        return null;
      }
      const html = `
        <div class="scenics_top"><span>${markername}</span></div>
        <div class="scenics_img scenics_juhe"><span>${(marker.getChildCount ? marker.getChildCount() : '1')}</span></div>
      `;
      return L.divIcon({
        className: ' cluster-marker ',
        html,
      });
    },
    // 服务点popup
    servicePopupContent(marker) {
      let html;
      // eslint-disable-next-line prefer-const
      html = `<div class="popup_container2">
        <div class="service_img"><img src='${marker.picPath === '' ? this.currentNearService.infoPicPath : marker.picPath}'/></div>
        <div class="service_info">
          <div class="title">${marker.name}</div>
          <div class="gohere" id="goto">去这里</div>
        </div>
      </div>`;
      return html;
    },
    // eslint-disable-next-line
    servicePopup(marker, popup) {
      this.$store.dispatch('updateSelectService', marker);
      // eslint-disable-next-line
      let $currentTarget;
      $(document).off('click', '#goto');
      $(document).on('click', '#goto', (e) => {
        $currentTarget = $(e.currentTarget);
        eventBus.$emit('serviceGoto', marker);
      });
    },

    onClickMap() {
      eventBus.$emit('hideAllModal');
      eventBus.$emit('showAi', null);
    },
    // 地图还原原来的状态
    invalidateSize() {
      this.$nextTick(() => {
        this.$refs.map.mapObject.invalidateSize(true);
      });
    },
    // 定位
    getLocation() {
      const self = this;
      if (utils.os.isWechat) {
        wx.getLocation({
          type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
          success(res) {
            const latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
            const longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
            const speed = res.speed; // 速度，以米/每秒计
            const accuracy = res.accuracy; // 位置精度

            const port = {
              latitude: parseFloat(latitude),
              longitude: parseFloat(longitude),
            };
            self.onLocationSuccessHandler(port);
          },
          fail(err) {
            if (!self.location.hadWarn) {
              self.location.hadWarn = true;
              self.$toast({
                message: '获取位置失败，请尝试开启手机定位服务',
                position: 'bottom',
              });
            }
            self.$store.dispatch('CHANGE_LC_LOADING', true);
          }
        });
      } else {
        // eslint-disable-next-line
        AMap.plugin("AMap.Geolocation", () => {
          // eslint-disable-next-line
          const geolocation = new AMap.Geolocation({
            // 是否使用高精度定位，默认：true
            enableHighAccuracy: true,
            // 设置定位超时时间，默认：无穷大
            timeout: 15000,
          });
          geolocation.getCurrentPosition();
          // eslint-disable-next-line
          AMap.event.addListener(geolocation, "complete", onComplete);
          // eslint-disable-next-line
          AMap.event.addListener(geolocation, "error", onError);

          function onComplete(data) {
            // data是具体的定位信息
            console.log(data);
            const port = {
              latitude: parseFloat(data.position.lat),
              longitude: parseFloat(data.position.lng),
            };
            self.onLocationSuccessHandler(port);
          }

          function onError(data) {
            // 定位出错
            if (!self.location.hadWarn) {
              self.location.hadWarn = true;
              self.onLocationError(data);
            }
            self.$store.dispatch('CHANGE_LC_LOADING', true);
          }
        });
      }
    },

    // 定位成功回调
    onLocationSuccessHandler(pot) {
      // 转换成高德坐标
      let point = {
        lat: pot.latitude,
        lng: pot.longitude,
      };
      // if (!utils.os.isIOS) {
      //   point = utils.GPS.gcj_encrypt(pot.latitude, pot.longitude);
      // }
      // 赋值游客坐标
      this.location.coords.lat = point.lat;
      this.location.coords.lng = point.lng;
      this.$store.dispatch('updateCoords', L.latLng(point.lat, point.lng));
      // 判断用户是否在景区内
      if (L.latLngBounds(this.imageLayerBounds2).contains(this.location.coords)) {
        this.location.isShow = true;
        // 在景区内首次进入的时候，移动到游客位置
        if (!this.location.hadPanTo) {
          this.location.hadPanTo = true;
          this.$refs.map.mapObject.panTo(this.location.coords);
        }
        // 用户手动触发定位
        if (this.location.userTrigger) {
          this.location.userTrigger = false;
          this.$store.dispatch('CHANGE_LC_LOADING', true);
          this.$refs.map.mapObject.panTo(this.location.coords);
        }
        this.$store.dispatch('updateIsInScenic', true);
        this.$store.dispatch('CHANGE_LC_LOADING', true);
      } else {
        this.location.isShow = true;
        if (!this.location.hasShowUserNotInScenic) {
          this.location.hasShowUserNotInScenic = true;
          this.location.userTrigger = false;
          this.$toast({
            message: '您不在当前景区范围内',
            position: 'bottom',
          });
        }
        this.$store.dispatch('CHANGE_LC_LOADING', true);
        this.$store.dispatch('updateIsInScenic', false);
        // 假如游客在景区内点击了去这里，但是隔了很久，除了景区之后，检测到不在景区内就需要将去这里窗口关闭
        this.$store.dispatch('updateRouteObject', null);
        this.$store.dispatch('updateIsGoto', false);
      }
    },
    // 失败回调
    onLocationError(ret) {
      // eslint-disable-next-line default-case
      switch (ret.code) {
        case ret.TIMEOUT:
          this.$toast({
            message: '定位失败!',
            position: 'bottom',
          });
          break;
        case ret.POSITION_UNAVAILABLE:
          this.$toast({
            message: '我们无法探测到你的位置,对不起!',
            position: 'bottom',
          });
          break;
        case ret.PERMISSION_DENIED:
          this.$toast({
            message: '请允许使用地理位置访问功能',
            position: 'bottom',
          });
          break;
        case ret.UNKNOWN_ERROR:
          this.$toast({
            message: '发生未知错误!',
            position: 'bottom',
          });
          break;
      }
    },
    getMarkerInCluster(layer, serviceId) {
      const markers = layer.getAllChildMarkers(); // 获取聚合点的所有marker
      // eslint-disable-next-line
      for (let i = 0, len = markers.length; i < len; i++) {
        if (markers[i].options.data.id === serviceId) {
          return markers[i];
        }
      }
      return null;
    },
    // 程序调用弹窗
    popupServicePoint(service) {
      // eslint-disable-next-line
      this.$nextTick(() => {
        const map = this.$refs.map.mapObject;
        map.panTo([service.lat, service.lon], { animate: false });
        map.eachLayer((layer) => {
          layer.closePopup();
          if (layer.getAllChildMarkers) {
            const target = this.getMarkerInCluster(layer, service.id);
            if (target) {
              this.$refs.markerCluster.mapObject.zoomToShowLayer(target, () => {
                target.openPopup();
                eventBus.$emit('hideAllModal');
              });
            }
          }
          // marker1是普通景点，marker2是子景点组，marker3是服务点
          if (layer.options.title === 'marker1' && layer.options.data.id === service.id) {
            layer.openPopup();
            eventBus.$emit('hideAllModal');
          } else if (layer.options.title === 'marker2' && layer.options.data.id === service.id) {
            eventBus.$emit('hideAllModal');
            this.$store.dispatch('updatecurrentGroupItem', service);
            eventBus.$emit('showModal', { whichModal: 'scenicGroupModal', arg: true });
            // eslint-disable-next-line
          } else if (layer.options.title === 'marker3' && layer.options.data.id === service.id) {
            layer.openPopup();
            eventBus.$emit('hideAllModal');
          } else if (service.typed === 4 && !service.isparentGroup) {
            eventBus.$emit('hideAllModal');
            // eslint-disable-next-line
            this.mapScenicGroup.map((items) => {
              if (items.id === service.parentGroupId) {
                // eslint-disable-next-line
                this.$store.dispatch('updatecurrentGroupItem', items);
                eventBus.$emit('showModal', { whichModal: 'scenicGroupModal', arg: false });
                // console.log(service.id, this.currentPlayInfo.playId);
              }
            });
          }
        });
      });
    },
  },
  mounted() {
    const map = this.$refs.map.mapObject;
    this.$nextTick(() => {
      this.invalidateSize();
      // 边界初始化
      map.fitBounds(this.imageLayerBounds2);
      let autoZoom = this.$refs.map.mapObject.getBoundsZoom(this.imageLayerBounds2, true);
      if (autoZoom > this.scenic.zoomImgMax) { // 最大层级设定
        autoZoom = this.scenic.zoomImgMax;
      }
      const latLngBounds = L.latLngBounds(this.imageLayerBounds2);
      const center = latLngBounds.getCenter();
      this.mapZoom.zoom = autoZoom;
      if (this.scenic.isMapScale === 1) { // 是否固定视野
        this.autoZoomRange();
      }
      // 移动到景区中心
      map.setView(center, autoZoom);
      setTimeout(() => {
        this.isStartShow = true;
      }, 200);
    });
    eventBus.$on('userMarker', (nv) => {
      if (nv) {
        this.updateUserMarker(nv);
      }
    });
    // 更改景点
    eventBus.$on('changeScenic', (item) => {
      this.popupServicePoint(item);
    });
    // 触发定位
    // eslint-disable-next-line
    eventBus.$on("location", e => {
      this.location.userTrigger = e.userTrigger;
      this.getLocation();
    });
    // 边界初始化
    eventBus.$on('boundsInit', () => {
      map.fitBounds(this.imageLayerBounds2);
      let autoZoom = this.$refs.map.mapObject.getBoundsZoom(this.imageLayerBounds2, true);
      if (autoZoom > this.scenic.zoomImgMax) {
        autoZoom = this.scenic.zoomImgMax;
      }
      const latLngBounds = L.latLngBounds(this.imageLayerBounds2);
      const center = latLngBounds.getCenter();
      this.mapZoom.zoom = autoZoom;
      map.setView(center, autoZoom);
    });
    // 路线位置边界
    eventBus.$on('routeBounds', () => {
      // this.flagCurrentRoute
      map.flyToBounds(this.currentRoutes.route, {
        duration: 0.2,
        padding: [20, 20],
      });
    });
    if (this.userPositionMarker) {
      L.DomEvent.on(window, 'deviceorientation', this.rotateHandler, this);
    }
  },
  beforeDestroy() {
    L.DomEvent.off(window, 'deviceorientation', this.rotateHandler, this);
  },
  deactivated() {
    L.DomEvent.off(window, 'deviceorientation', this.rotateHandler, this);
  },
};
</script>
<style lang="scss" scoped>
.h5_index_wrap {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
}
</style>
<style lang="scss">
@import "./map.scss";
</style>
